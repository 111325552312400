import React from "react";
import PropTypes from "prop-types";
import { Button as AntButton } from "antd";
import { classesName } from "../../utils/css";
import { ButtonShape, ButtonType, ButtonVariant, ButtonSize } from "./enums";
import BodyMedium from "../../elements/Typography/BodyMedium";
import "./button.scss";
import { TypographyColors } from "../../elements/Typography/enum";
import Icon from "../../elements/Icon";

export default function Button(props) {
  const {
    type,
    variant,
    shape,
    className,
    label,
    icon,
    onClick,
    disabled = false,
    testId,
    error,
    iconName,
    ...restProps
  } = props;

  const buttonTypeClass = getButtonTypeClass(type);
  const variantClass = variant === "icon" ? "button-icon-type" : "button-basic-variant";
  const shapeClass = shape === ButtonShape.ROUND ? "button-round-shape" : "";
  const disabledClass = disabled ? "disabled" : "";
  const labelColor = getButtonLabelColor(error, type, disabled);
  const btnClassName = getFinalClassname(
    buttonTypeClass,
    variantClass,
    shapeClass,
    className,
    disabledClass,
    icon
  );
  const renderIcon = iconName ? <Icon name={iconName} /> : icon;
  return (
    <AntButton
      className={btnClassName}
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      type={type}
      shape={shape === ButtonShape.ROUND ? "circle" : undefined}
      danger={error}
      icon={renderIcon}
      {...restProps}
    >
      {variant !== "icon" && label && (
        <BodyMedium color={labelColor}>{label}</BodyMedium>
      )}
    </AntButton>
  );
}

function getFinalClassname(
  buttonTypeClass,
  variantClass,
  shapeClass,
  className,
  disabledClass,
  icon
) {
  return classesName(
    "energy-button",
    buttonTypeClass,
    variantClass,
    shapeClass,
    className,
    disabledClass,
    icon ? "button-with-icon" : ""
  );
}
function getButtonLabelColor(error, type, disabled) {
  let labelColor;
  if (disabled) {
    return TypographyColors.DISABLED;
  }
  switch (type) {
    case ButtonType.PRIMARY:
      labelColor = error ? TypographyColors.WHITE : TypographyColors.WHITE;
      break;
    case ButtonType.SECONDARY:
      labelColor = error ? TypographyColors.ERROR : TypographyColors.DEFAULT;
      break;
    case ButtonType.LINK:
      labelColor = error ? TypographyColors.ERROR : TypographyColors.LINK;
      break;
    case ButtonType.TEXT:
      labelColor = error ? TypographyColors.ERROR : TypographyColors.DEFAULT;
      break;
    default:
      labelColor = error ? TypographyColors.WHITE : TypographyColors.WHITE;
      break;
  }

  return labelColor;
}

function getButtonTypeClass(type) {
  switch (type) {
    case ButtonType.PRIMARY:
      return "button-primary";
    case ButtonType.SECONDARY:
      return "button-secondary";
    case ButtonType.TEXT:
      return "button-text";
    case ButtonType.LINK:
      return "button-link";
    default:
      return "";
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(ButtonType)),
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
  shape: PropTypes.oneOf(Object.values(ButtonShape)),
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  error: PropTypes.bool,
};

Button.defaultProps = {
  type: ButtonType.PRIMARY,
  variant: ButtonVariant.BASIC,
  shape: ButtonShape.DEFAULT,
  size: ButtonSize.MEDIUM,
  className: "",
  error: false,
};

export { ButtonVariant, ButtonShape, ButtonType, ButtonSize };
