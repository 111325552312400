import React, { useState } from "react";
import { Popover, Button, Input } from "antd";
import { AddOne } from "../../components/icons/menu";
import { getAlertChannelDisplayName } from "./utils";

import { ButtonShape, ButtonSize, ButtonType, ButtonVariant } from "../../atom/Button";
import Icon, { IconName, IconSizes } from "../../elements/Icon";
import "./notification-add-alert-channel-popover.scss";

function NotificationAddAlertChannelPopover(props) {
  const { onAdd, alertChannelList } = props;
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const trimmedSearchValue = searchValue.toLowerCase().trim();
  const filteredAlertChannelList =
    trimmedSearchValue.length > 0
      ? alertChannelList.filter(({ title }) => {
          return title.toLowerCase().indexOf(trimmedSearchValue) !== -1;
        })
      : alertChannelList;

  function onVisibleChange(visible) {
    setVisible(visible);
    if (!visible) {
      setSearchValue("");
    }
  }

  function onAddAlertChannelClicked(currentAlertChannel) {
    onAdd && onAdd(currentAlertChannel);
  }

  const popOverContent = (
    <div className="notification-add-alert-channel-popover-content-container">
      <Input.Search
        className="notification-add-alert-channel-popover-workspace-search-container"
        placeholder={"Search..."}
        onChange={(e) => {
          const currentSearchValue = e.target.value.trim();
          currentSearchValue !== searchValue && setSearchValue(currentSearchValue);
        }}
      />
      <div className="notification-add-alert-channel-popover-alert-channel-list-container">
        {filteredAlertChannelList.map((currentAlertChannel) => {
          return (
            <div
              className="notification-add-alert-channel-popover-alert-channel-list-item-container"
              key={currentAlertChannel.id}
            >
              <span
                className="notification-add-alert-channel-popover-alert-channel-list-item-add-button"
                onClick={onAddAlertChannelClicked.bind(null, currentAlertChannel)}
              >
                <AddOne />
              </span>
              <div className="notification-add-alert-channel-popover-alert-channel-list-item-name-container">
                {getAlertChannelDisplayName(currentAlertChannel)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <Popover
      overlayClassName="notification-add-alert-channel-popover-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomLeft"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      <Button
        type={ButtonType.SECONDARY}
        variant={ButtonVariant.ICON}
        size={ButtonSize.SMALL}
        shape={ButtonShape.ROUND}
        icon={<Icon name={IconName.Plus} size={IconSizes.SMALL} />}
      />
    </Popover>
  );
}

export default NotificationAddAlertChannelPopover;
