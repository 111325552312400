import React, { useState, useMemo } from "react";
import { Popover, Form, Input } from "antd";
import Select from "../../components/select/";
import Button from "../../components/button/ng-button";
import { PlusOutlined } from "@ant-design/icons";

import "./add-user-popover.scss";

function AddUserPopOver(props) {
  const {
    roleOptions,
    initialValues,
    onAddUser,
    userList = [],
    enableAutoComplete = false,
    buttonSize = "middle",
  } = props;

  let trigger = props.trigger;

  const userOptions = useMemo(() => {
    return (userList || []).map((currentUser) => ({
      label: currentUser.email,
      value: currentUser.email,
    }));
  }, [userList]);

  const [visible, setVisible] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [form] = Form.useForm();

  function resetState() {
    form.resetFields();
  }

  function onVisibleChange(visible) {
    setVisible(visible);
    if (!visible) {
      resetState();
    }
  }

  function onFieldsChange() {
    const selectedEmail = form.getFieldValue("email");
    setIsUserSelected(selectedEmail && selectedEmail !== "");
  }

  const onSubmitForm = (values) => {
    let extraUserInfo = {};
    if (enableAutoComplete && userList && userList.length > 0) {
      extraUserInfo = userList.filter(({ email }) => email === values.email)[0];
    }

    // Keep the order here to make sure user selection is in the last.
    onAddUser && onAddUser({ ...extraUserInfo, ...values, email: values.email.trim() });
    resetState();
    setVisible(false);
  };

  const notFoundContent = (
    <div className="add-user-popover-not-found">No user found in the system</div>
  );

  const popOverContent = (
    <div className="add-user-popover-content-container">
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitForm}
        initialValues={initialValues}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input the email!",
            },
          ]}
        >
          {!enableAutoComplete ? (
            <Input autoComplete="off" />
          ) : (
            <Select
              style={{
                width: 250,
              }}
              enableSorting={true}
              placeholder="Search users..."
              options={userOptions}
              showSearch={true}
              notFoundContent={notFoundContent}
            />
          )}
        </Form.Item>
        <Form.Item label="Role" name="role">
          <Select placeholder="Select a role for user" options={roleOptions} />
        </Form.Item>
        <Form.Item>
          <Button
            primary
            htmlType="submit"
            block
            size="large"
            disabled={!isUserSelected}
          >
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  trigger = trigger ?? (
    <Button outline block size={buttonSize} data-testid="admin-add-user-btn">
      Add User
      <PlusOutlined />
    </Button>
  );

  return (
    <Popover
      overlayClassName="add-user-popover-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomLeft"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      {trigger}
    </Popover>
  );
}

export default AddUserPopOver;
