import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { classesName } from "../../utils/css";
import { DialogSize } from "../shared/enums";
import Button, { ButtonType } from "../Button";
import TitleMedium from "../../elements/Typography/TitleMedium";

import "./dialog.scss";
import TextInput from "../TextInput";
import BodyMedium from "../../elements/Typography/BodyMedium";

function Dialog(props) {
  const {
    testId,
    size = DialogSize.SMALL,
    className,
    children,
    title,
    footerProps,
    noFooter = false,
    onOk,
    onCancel,
    okButtonText = "Save",
    cancelButtonText = "Cancel",
    okButtonProps,
    ...otherProps
  } = props;

  return (
    <Modal
      className={classesName("energy-dialog", size, className)}
      data-testid={testId}
      title={<TitleMedium>{title}</TitleMedium>}
      onCancel={onCancel}
      footer={
        <DialogFooter
          visible={!noFooter}
          onOk={onOk}
          onCancel={onCancel}
          okButtonText={okButtonText}
          cancelButtonText={cancelButtonText}
          okButtonProps={okButtonProps}
          {...footerProps}
        />
      }
      {...otherProps}
    >
      {children}
    </Modal>
  );
}

function DialogFooter(props) {
  const {
    left,
    right,
    visible,
    onOk,
    onCancel,
    okButtonText,
    cancelButtonText,
    okButtonProps,
  } = props;
  if (!visible) {
    return null;
  }

  return (
    <div className="energy-dialog-footer">
      <div className="energy-dialog-footer-element">{left}</div>
      <div className="energy-dialog-footer-element">
        {right ? (
          right
        ) : (
          <FooterActions
            onOk={onOk}
            onCancel={onCancel}
            okButtonText={okButtonText}
            cancelButtonText={cancelButtonText}
            okButtonProps={okButtonProps}
          />
        )}
      </div>
    </div>
  );
}

function FooterActions({
  onOk,
  onCancel,
  okButtonText,
  cancelButtonText,
  okButtonProps,
}) {
  return (
    <>
      <Button type={ButtonType.SECONDARY} label={cancelButtonText} onClick={onCancel} />
      <Button label={okButtonText} {...okButtonProps} onClick={onOk} />
    </>
  );
}
Dialog.propTypes = {
  ...Modal.propTypes,
  testId: PropTypes.string,
  footerProps: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node,
  }),
  noFooter: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(DialogSize)),
  className: PropTypes.string,
  children: PropTypes.node,
  cancelButtonText: PropTypes.string,
};

function PromptDialog(props) {
  const { bodyText, confirmText = "ok", okButtonProps, ...restProps } = props;
  const [value, setValue] = useState("");
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const okButtonDisabled = value !== confirmText;
  const updatedOkButtonProps = { ...okButtonProps, disabled: okButtonDisabled };
  return (
    <Dialog {...restProps} okButtonProps={updatedOkButtonProps}>
      <div className="energy-dialog-prompt-body">
        <BodyMedium> {bodyText}</BodyMedium>
        <TextInput
          onChange={onChange}
          placeholder={`Type "${confirmText}" to confirm`}
        />
      </div>
    </Dialog>
  );
}
PromptDialog.propTypes = {
  ...Dialog.propTypes,
  bodyText: PropTypes.string,
  confirmText: PropTypes.string,
};
export default Dialog;
export { PromptDialog };
