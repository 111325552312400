import sqlFormatter from "sql-formatter";
import {
  DOUBLE_PRECISION_MAX,
  DOUBLE_PRECISION_MIN,
  KPI_CATEGORY_KEY_NAME,
  KPI_PERCENTILE_KEY_NAME,
} from "./constants";
import {
  CollectionModeType,
  DataSourceType,
  MetricConfigType,
  PodJobStatus,
  QueryRecordStatus,
  QueryScope,
  TableType,
} from "./enums";
import { getFullTableName } from "./datasource";

export const JSON_VERSION = "0.0.1";

export function updateValueRange(valueRange, currentValue) {
  if (typeof currentValue !== "number") {
    return;
  }

  if (valueRange.min === undefined && valueRange.max === undefined) {
    valueRange.min = currentValue;
    valueRange.max = currentValue;
  } else if (currentValue >= valueRange.max) {
    valueRange.max = currentValue;
  } else if (currentValue <= valueRange.min) {
    valueRange.min = currentValue;
  }
}

export function getRangeFromSubRanges(allSubRanges) {
  let range = { min: undefined, max: undefined };
  Object.values(allSubRanges).forEach(function (subRange) {
    updateValueRange(range, subRange.min);
    updateValueRange(range, subRange.max);
  });

  return range;
}

const resolutionMap = [
  { range: Math.pow(10, 1), resolution: Math.pow(10, 1) },
  { range: Math.pow(10, 0), resolution: Math.pow(10, 0) },
  { range: Math.pow(10, -1), resolution: Math.pow(10, -1) },
  { range: Math.pow(10, -2), resolution: Math.pow(10, -2) },
  { range: Math.pow(10, -3), resolution: Math.pow(10, -3) },
  { range: Math.pow(10, -4), resolution: Math.pow(10, -4) },
  { range: Math.pow(10, -5), resolution: Math.pow(10, -5) },
  { range: Math.pow(10, -6), resolution: Math.pow(10, -6) },
];

export function getYAxisRange(dataMin, dataMax) {
  let currentRange = dataMax - dataMin;
  console.debug(`original ${dataMin} ${dataMax}`);
  if (currentRange < 0) {
    console.log(`Invalid yAxis range with min ${dataMin} and mx ${dataMax}`);
    return { yAxisMin: 0, yAxisMax: 0 };
  }

  let selectedResolution = 1;
  for (let resolutionItem of resolutionMap) {
    selectedResolution = resolutionItem.resolution;
    if (currentRange >= resolutionItem.range) {
      break;
    }
  }

  let yAxisMin = dataMin;
  let yAxisMax = dataMax;
  let resolutionFactor = 0;
  if (currentRange > 0) {
    resolutionFactor = Math.ceil((currentRange * 0.05) / selectedResolution);
  }

  if (yAxisMin % selectedResolution) {
    yAxisMin =
      (Math.floor(yAxisMin / selectedResolution) - resolutionFactor) *
      selectedResolution;
  } else {
    yAxisMin = yAxisMin - resolutionFactor * selectedResolution;
  }

  if (yAxisMax % selectedResolution) {
    yAxisMax =
      (Math.ceil(yAxisMax / selectedResolution) + resolutionFactor) *
      selectedResolution;
  } else {
    yAxisMax = yAxisMax + resolutionFactor * selectedResolution;
  }

  console.debug(`Converted ${yAxisMin} ${yAxisMax}`);
  return { yAxisMin, yAxisMax };
}

export function getUniqueSliceValue(slice, sliceByColumns = []) {
  const currentSliceValue = {};
  sliceByColumns.forEach((sliceKey) => {
    currentSliceValue[sliceKey] = slice[sliceKey];
  });

  return currentSliceValue;
}
export function memoize(fn) {
  const cache = new Map();
  return (...args) => {
    const key = JSON.stringify(args);
    if (cache.has(key)) return cache.get(key);
    const result = fn(...args);
    cache.set(key, result);
    return result;
  };
}
export function getSliceDisplayString(slice, metric = null, skipSystemSlice = false) {
  const sliceDisplayValueArray = [];
  const sortedSliceEntries = Object.entries(slice).sort(([keyA], [keyB]) =>
    keyA.localeCompare(keyB)
  );

  for (let sliceEntry of sortedSliceEntries) {
    let currentSliceEntry = [...sliceEntry];

    if (currentSliceEntry[1] === null) {
      currentSliceEntry[1] = "null";
    }

    let isSystemSlice = false;
    if (currentSliceEntry[0] === KPI_CATEGORY_KEY_NAME) {
      currentSliceEntry[0] = "category";
      isSystemSlice = true;
    } else if (currentSliceEntry[0] === KPI_PERCENTILE_KEY_NAME) {
      currentSliceEntry[0] = "percentile";
      isSystemSlice = true;
    } else if (
      metric?.config?.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG
    ) {
      const currentSliceIndex = currentSliceEntry[0];
      currentSliceEntry = [
        metric.config.sourceTable.sliceByColumns[currentSliceIndex],
        metric.config.targetTable.sliceByColumns[currentSliceIndex],
        currentSliceEntry[1],
      ];
    }

    if (skipSystemSlice && isSystemSlice) {
      continue;
    }

    sliceDisplayValueArray.push(currentSliceEntry.join(":"));
  }

  const sliceDisplayInfo =
    sliceDisplayValueArray.length > 0 ? sliceDisplayValueArray.join(", ") : "";
  return sliceDisplayInfo;
}

export function isSliceEqual(slice1, slice2, sliceByColumns = []) {
  if (sliceByColumns.length === 0) {
    sliceByColumns = Object.keys(slice1);
  }

  for (let sliceKey of sliceByColumns) {
    if (slice1[sliceKey] !== slice2[sliceKey]) {
      return false;
    }
  }

  return true;
}

export function objectIsNotEmpty(testObject) {
  for (let key in testObject) {
    if (typeof testObject[key] !== "number" && !testObject[key]) {
      return false;
    }

    if (typeof testObject[key] === "object" && !objectIsNotEmpty(testObject[key])) {
      return false;
    }
  }

  return true;
}

export function stringFormatter() {
  let str = this;
  for (let k in arguments) {
    str = str.replace("{" + k + "}", arguments[k]);
  }
  return str;
}

export function isJobRunning(jobStatus) {
  return (
    !jobStatus || [PodJobStatus.NOT_AVAILABLE, PodJobStatus.UNKNOWN].includes(jobStatus)
  );
}

export function getAppDomainName() {
  const portInfo = window.location.port ? `:${window.location.port}` : "";
  return `${window.location.protocol}//${window.location.hostname}${portInfo}/`;
}

export function formatCustomSql(customSql, dataSourceType = DataSourceType.POSTGRES) {
  if (!customSql) {
    return "";
  }

  // Todo: upgrade it to 4.0.0 after RC is release for it starts to support postgresql.
  let language = "sql";
  if (dataSourceType === DataSourceType.REDSHIFT) {
    language = "redshift";
  }

  let formattedSql = sqlFormatter.format(customSql, {
    language,
    indent: "\t",
  });

  formattedSql = formattedSql
    .replace(/=\s>/g, "=>")
    .replace(/\{\sstart_ts\s\}/g, "{start_ts}")
    .replace(/\{\send_ts\s\}/g, "{end_ts}")
    .replace(/\{\sstart_date\s\}/g, "{start_date}")
    .replace(/\{\send_date\s\}/g, "{end_date}")
    .replace(/\{\sstart_partition\s\}/g, "{start_partition}")
    .replace(/\{\send_partition\s\}/g, "{end_partition}")
    .replace(/\{\sstart_datetime\s\}/g, "{start_datetime}")
    .replace(/\{\send_datetime\s\}/g, "{end_datetime}");
  return formattedSql;
}

// Todo: deprecated. use getIncidentListForCurrentTimestamp instead.
export function isCurrentTimestampAnIncident(incidentList, currentTimestamp) {
  for (let currentIncident of incidentList) {
    const { startTime, endTime } = currentIncident;
    if (currentTimestamp >= startTime && currentTimestamp <= endTime) {
      return currentIncident;
    }
  }

  return null;
}

export function getIncidentListForCurrentTimestamp(incidentList, currentTimestamp) {
  return incidentList.filter(
    ({ startTime, endTime }) =>
      currentTimestamp >= startTime && currentTimestamp <= endTime
  );
}

export function getKPIProfilerConfigInfo(kpi, dataSource) {
  if (!kpi || !dataSource) {
    console.log(
      `Kpi is${kpi ? " not" : ""} empty. DataSource is${
        dataSource ? " not" : ""
      } empty.`
    );
    return null;
  }

  const dataSourceName = dataSource.metadata.name;
  let fullTableName = "";
  const table = kpi.config.table;
  if (table && table.type === TableType.TABLE) {
    fullTableName = getFullTableName(table);
  }

  const columnName =
    kpi.config.valueColumns && kpi.config.valueColumns.length > 0
      ? kpi.config.valueColumns.map(({ columnName }) => columnName).join(",")
      : "";

  return {
    dataSourceName,
    fullTableName,
    columnName,
  };
}

export function getKPIProfilerDisplayStr(kpi, dataSource) {
  const kpiProfilerConfig = getKPIProfilerConfigInfo(kpi, dataSource);
  if (!kpiProfilerConfig) {
    return "";
  }

  const { dataSourceName, fullTableName, columnName } = kpiProfilerConfig;

  const validValueArray = [];
  dataSourceName && validValueArray.push(dataSourceName);
  fullTableName && validValueArray.push(fullTableName);
  columnName && validValueArray.push(columnName);

  return validValueArray.join(" \u203A ");
}

export function isEnoughPointsForTraining(signalData, targetPointNumbers = 1) {
  if (!signalData || !Array.isArray(signalData)) {
    return false;
  }
  return signalData.length >= targetPointNumbers;
}

export function getRuleRuntimeConfigItemFromObject(
  ruleConfigObject,
  itemAbsolutePath,
  defaultValue,
  isSliceOverride
) {
  if (!itemAbsolutePath || !ruleConfigObject) {
    return defaultValue;
  }

  const pathItems = itemAbsolutePath.split(".");
  let currentValue = isSliceOverride
    ? ruleConfigObject.config
    : ruleConfigObject.config.symptom;
  let i;
  if (currentValue) {
    for (i = 0; i < pathItems.length; i++) {
      if (
        !currentValue.hasOwnProperty(pathItems[i]) ||
        currentValue[pathItems[i]] === null
      ) {
        break;
      }

      currentValue = currentValue[pathItems[i]];
    }

    if (i === pathItems.length) {
      return currentValue;
    }
  }

  if (
    !ruleConfigObject.status ||
    !ruleConfigObject.status.runtimeConfig ||
    !ruleConfigObject.status.runtimeConfig.symptomConfig
  ) {
    return defaultValue;
  }

  currentValue = ruleConfigObject.status.runtimeConfig.symptomConfig;
  for (i = 0; i < pathItems.length; i++) {
    if (!currentValue.hasOwnProperty(pathItems[i])) {
      break;
    }

    currentValue = currentValue[pathItems[i]];
  }

  if (i === pathItems.length) {
    return currentValue;
  }

  return defaultValue;
}

const queryRecordStatusDisplayNameMapper = {
  [QueryRecordStatus.COMPLETED]: "Completed",
  [QueryRecordStatus.FAILED]: "Failed",
  [QueryRecordStatus.CANCELLED]: "Cancelled",
  [QueryRecordStatus.PROCESSING]: "Processing",
};

export function getQueryRecordStatusDisplayName(queryStatus) {
  return queryRecordStatusDisplayNameMapper[queryStatus] || "Unknown";
}

export function isFeatureEnabled(waffle, featureName) {
  return waffle && waffle.switches && waffle.switches[featureName];
}

export function isTimestampTimezoneConfigEnabled(columnType) {
  //this should be in sync with the test in the backend: test_db_column_type_in_ui_list

  if (!columnType) {
    return false;
  }

  return [
    "timestamp without time zone",
    "datetime",
    "timestamp_ntz",
    "datetime2",
    "character varying",
    "integer",
    "timestamp(6)",
    "timestamp(0)",
    "string",
  ].includes(columnType.toLowerCase());
}

export function getSliceValuePayload(objectType, objectData, queryDuration) {
  let payload = {};
  if (objectType === "metric") {
    const kpi = objectData;
    payload = {
      sourceUuid: kpi.config.sources[0],
      table: kpi.config.table,
      timestampColumn: kpi.config.timestampColumn,
      timezone: kpi.config.queryTimezone,
      queryScope:
        kpi.config.configType === MetricConfigType.METRIC_CONFIG
          ? QueryScope.TIME_RANGE
          : QueryScope.FULL_TABLE,
      data_timezone: kpi.config.dataTimezone,
    };

    if (kpi.config.timestampColumnFunctions) {
      payload.timestampColumnFunctions = kpi.config.timestampColumnFunctions;
    }

    if (kpi.config.sliceByColumns && kpi.config.sliceByColumns.length > 0) {
      payload.columns = kpi.config.sliceByColumns;
    }

    if (kpi.config.partitions && kpi.config.partitions.length > 0) {
      payload.partitions = kpi.config.partitions;
      payload.partition_offsets = kpi.config.partitionOffsets;
      payload.partition_timezone = kpi.config.partitionTimezone;
    }
  } else if (objectType === "compareTable") {
    const compareTable = objectData;
    payload = {
      sourceUuid: compareTable.sourceUuid,
      table: {
        type: TableType.TABLE,
        tableName: compareTable.table.tableName,
        schemaName: compareTable.table.schemaName,
      },
      timestampColumn: compareTable.timestampColumn,
      timezone: compareTable.queryTimezone,
      columns: compareTable.sliceByColumns,
      queryScope: compareTable.queryScope,
    };

    if (compareTable.partitions && compareTable.partitions.length > 0) {
      payload.partitions = compareTable.partitions;
      payload.partition_offsets = compareTable.partitionOffsets;
      payload.partition_timezone = compareTable.partitionTimezone;
    }
  }

  if (queryDuration && queryDuration.startTs) {
    payload.startTs = queryDuration.startTs;
  }

  if (queryDuration && queryDuration.endTs) {
    payload.endTs = queryDuration.endTs;
  }

  return payload;
}

export function capitalizeFirstLetter(string) {
  if (!string || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDisplayFloatStr(
  floatNumber,
  isPercentage = false,
  fractionDigits = 2
) {
  if (floatNumber === DOUBLE_PRECISION_MAX) {
    return "Inf";
  }

  if (floatNumber === DOUBLE_PRECISION_MIN) {
    return "-Inf";
  }

  let displayValue = floatNumber;
  if (typeof floatNumber === "number") {
    displayValue = +floatNumber.toFixed(fractionDigits);
  }

  return isPercentage ? `${displayValue}%` : displayValue;
}

export function getRangeDisplayStr(lower, upper) {
  const isLowerANumber = typeof lower === "number";
  const isUpperANumber = typeof upper === "number";
  if (!isLowerANumber && !isUpperANumber) {
    return "-";
  }

  if (!isUpperANumber) {
    return `> ${getDisplayFloatStr(lower, false)}`;
  }

  if (!isLowerANumber) {
    return `< ${getDisplayFloatStr(upper, false)}`;
  }

  return `${getDisplayFloatStr(lower, false)}-${getDisplayFloatStr(upper, false)}`;
}

export function getObjectUuidToInfoMapper(objectList, isName = true) {
  const objectUuidToNameMapper = {};
  objectList.forEach((currentObject) => {
    objectUuidToNameMapper[currentObject.metadata.uuid] = isName
      ? currentObject.metadata.name
      : currentObject;
  });

  return objectUuidToNameMapper;
}

export function isTriggeredCollectionMode(collectionMode) {
  return collectionMode?.type === CollectionModeType.TRIGGERED;
}

export function isCustomScheduledCollectMode(collectionMode) {
  return collectionMode?.type === CollectionModeType.CUSTOM_SCHEDULED;
}

export function isRenderableString(obj) {
  return typeof obj === "string" && obj.trim().length !== 0;
}
