import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router";
import Search from "../search";
import NgDropdownMenu from "../ng-dropdown-menu";
import { PlusOutlined } from "@ant-design/icons";
import { LabeledSelect } from "../labeled-control/labeled-control";
import { DynamicHeightTable } from "../table/ng-table";
import ColumnListControl from "./column-list-control";
import EntityStats from "./entity-stats";
import { indexBy } from "../../utils/iterables";
import {
  decodeEntityListSearchObject,
  encodeEntityListSearchObject,
} from "../../utils/search";
import { useTableCustomSelection } from "../table/use-table-custom-selection";

import Button, { ButtonSize, ButtonType } from "../../atom/Button";
import Icon, { IconName, IconSizes } from "../../elements/Icon";
import { TypographyColors } from "../../elements/Typography/enum";
import BodySmall from "../../elements/Typography/BodySmall";
import "./entity-list.scss";

const defaultPageSize = 50;

function EntityListControls(props) {
  const {
    addText,
    addDropdownMenuItems = null,
    showAdd,
    enableAdd,
    pageSize,
    pageSizeOptions = [10, 20, 50, 100],
    searchOptions,
    searchItem,
    extraSearchControls,
    columnControls,
    onAdd,
    onPageSizeChange,
    onSearchItemChange,
    testId,
    selectedRows,
    onDelete,
    customControls,
  } = props;
  const searchTestId = testId ? `${testId}-search` : "";
  let addEntityElement = null;
  if (showAdd) {
    const testIdProps = testId ? { "data-testid": `${testId}-add-button` } : {};

    if (!addDropdownMenuItems) {
      addEntityElement = (
        <Button
          type={ButtonType.SECONDARY}
          size={ButtonSize.LARGE}
          onClick={onAdd}
          disabled={!enableAdd}
          label={addText}
          {...testIdProps}
        ></Button>
      );
    } else {
      addEntityElement = (
        <NgDropdownMenu
          trigger={
            <Button
              outline
              size={ButtonSize.LARGE}
              type={ButtonType.SECONDARY}
              label={
                <>
                  {addText}
                  <PlusOutlined />
                </>
              }
              disabled={!enableAdd}
              {...testIdProps}
            ></Button>
          }
          menuItems={addDropdownMenuItems}
        />
      );
    }
  }

  const isMultipleSelected = selectedRows && selectedRows.length > 1;

  return (
    <div className="entity-list-controls">
      <div className="entity-list-controls-left">
        <div className="entity-list-controls-search">
          <Search
            label="Search"
            placeholder="Search"
            localFilterSetting={searchItem}
            selectionOptionList={searchOptions}
            onChange={onSearchItemChange}
            testId={searchTestId}
          />
        </div>
        {extraSearchControls}
        {addEntityElement}
        <div className="entity-list-controls-page-size">
          <LabeledSelect
            label=""
            options={pageSizeOptions.map((size) => ({
              label: `Show ${size}`,
              value: size,
            }))}
            value={pageSize}
            onChange={onPageSizeChange}
            testId={`${testId}-pagination-dropdown`}
          />
        </div>
        {columnControls}
        {isMultipleSelected && onDelete && (
          <BodySmall
            color={TypographyColors.ERROR}
            className="entity-list-controls-bulk-button"
            onClick={onDelete}
          >
            <Icon name={IconName.Trash} size={IconSizes.SMALL} />
            Delete ({selectedRows.length})
          </BodySmall>
        )}

        {customControls}
      </div>
    </div>
  );
}

export function getSelectedRows(rows, selectedKeys, getRowKey) {
  if (selectedKeys.length === 0) {
    return [];
  }
  const rowKeySet = new Set(selectedKeys);
  return rows.filter((row) => rowKeySet.has(getRowKey(row)));
}

function EntityList(props) {
  const {
    addText,
    addDropdownMenuItems,
    showAdd,
    enableAdd = true,
    searchOptions,
    columns,
    configurableColumns = [],
    rows = [],
    getRowKey,
    loading,
    tableProps = { pagination: {} },
    filterControls = null, // Appears above the search / pagination controls
    searchControls = null, // Appears to the right of the search input box
    getFilteredRows = (rows, _searchItem) => rows,
    getRowStats = (_rows) => null,
    onAdd,
    onSelectedRowsChange,
    location,
    history,
    testId,
    selectedRows,
    onDelete,
    customControls,
  } = props;

  let {
    searchItem = {},
    onSearchItemChange,
    columnKeyList = [],
    onColumnKeyListChange,
    columnKeyOptions = [],
  } = props;

  const {
    selectedRowKeys,
    clearAll,
    selectAll,
    onSelectionChange,
    onDataSetChange,
    selectionStats,
    onCurrentPageRowsKeysChange,
  } = useTableCustomSelection({
    onSelectedRowsChange,
    getRowKeyFn: getRowKey,
    originalRows: rows,
  });

  const [filteredRows, setFilteredRows] = useState(rows);
  const [rowStats, setRowStats] = useState(null);

  const normalizedColumns = useMemo(() => {
    if (
      !configurableColumns ||
      configurableColumns.length === 0 ||
      !columnKeyList ||
      columnKeyList.length === 0
    ) {
      return columns;
    }

    const columnKeyToConfigMapper = indexBy(
      configurableColumns,
      (column) => column.key
    );
    const enabledColumns = [...columns];
    columnKeyList.forEach((columnKey) => {
      columnKeyToConfigMapper[columnKey] &&
        enabledColumns.push(columnKeyToConfigMapper[columnKey]);
    });

    return enabledColumns;
  }, [columns, configurableColumns, columnKeyList]);

  const { pagination, ...restTableProps } = tableProps;

  const [pageSize, setPageSize] = useState(pagination?.pageSize ?? defaultPageSize);
  const [defaultSearchItem, defaultSetSearchItem] = useState(
    decodeEntityListSearchObject(location.search)
  );

  if (!onSearchItemChange) {
    searchItem = defaultSearchItem;
    onSearchItemChange = (newValue) => {
      history.replace(`${location.pathname}?${encodeEntityListSearchObject(newValue)}`);
      defaultSetSearchItem(newValue);
    };
  }

  useEffect(() => {
    const newFilteredRows = getFilteredRows(rows, searchItem);
    setFilteredRows(newFilteredRows);
    setRowStats(getRowStats(newFilteredRows));
    onDataSetChange(newFilteredRows);
    // Removing rule until we can implement useCallback for getFilteredRows and getRowStats functions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, searchItem, onDataSetChange]);

  const columnControls =
    columnKeyOptions?.length > 0 ? (
      <ColumnListControl
        value={columnKeyList}
        onChange={onColumnKeyListChange}
        options={columnKeyOptions}
      />
    ) : null;

  const topControls = (
    <EntityListControls
      addText={addText}
      showAdd={showAdd}
      enableAdd={enableAdd}
      addDropdownMenuItems={addDropdownMenuItems}
      pageSize={pageSize}
      searchOptions={searchOptions}
      searchItem={searchItem}
      extraSearchControls={searchControls}
      columnControls={columnControls}
      onPageSizeChange={setPageSize}
      onSearchItemChange={onSearchItemChange}
      onAdd={onAdd}
      testId={testId}
      selectedRows={selectedRows}
      onDelete={onDelete}
      customControls={customControls}
    />
  );

  const rowSelection = onSelectedRowsChange
    ? {
        preserveSelectedRowKeys: true,
        selectedRowKeys: [...selectedRowKeys],
        onChange: onSelectionChange,
      }
    : null;

  const summaryChange = (pageData) => {
    // The table summary rendering gives information about the entities on the current page,
    // we use that to calculate the selected element on other pages
    setTimeout(() => {
      // This setTimeout is required to avoid the warning "Cannot update a component while rendering a different"
      onCurrentPageRowsKeysChange(pageData.map((entity) => getRowKey(entity)));
    });
    return null;
  };

  return (
    <div className="entity-list">
      {rowStats && (
        <div className="entity-list-stats-container">
          <EntityStats stats={rowStats} />
        </div>
      )}
      {filterControls && (
        <div className="entity-list-filter-controls">{filterControls}</div>
      )}
      <div className="entity-list-table-container">
        <DynamicHeightTable
          columns={normalizedColumns}
          dataSource={filteredRows}
          loading={loading}
          rowKey={(entity) => getRowKey(entity)}
          tableLayout="fixed"
          sortDirections={["ascend", "descend", "ascend"]}
          topControls={topControls}
          selectionStats={selectionStats}
          isFiltered={filteredRows.length !== rows.length}
          rowSelection={rowSelection}
          onClearAllRows={clearAll}
          onSelectAllRows={selectAll}
          pagination={{
            ...pagination,
            pageSize,
            showSizeChanger: false,
            size: "default",
          }}
          summary={summaryChange}
          testId={testId}
          {...restTableProps}
        />
      </div>
    </div>
  );
}

export default withRouter(EntityList);
