import React from "react";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import Link from "../../components/link";
import { NgTableClickableText } from "../../components/table/ng-table";
import IncidentValidationStatusCell from "./incident-validation-status-cell";
import { getDisplayTimeFromSecond, getStringFromTimeStamp } from "../../utils/time";
import {
  IncidentCreatorType,
  IncidentScore,
  ListPageColumnKey,
} from "../../utils/enums";
import { classesName } from "../../utils/css";
import { getSliceDisplayString } from "../../utils/general";
import {
  isIncidentValidationSupported,
  metricCreationTypeLabel,
} from "../../utils/metric";
import Select from "../../components/select";
import { incidentStatusOptions } from "../../utils/options";
import { AppPermissions } from "../../utils/permissions";
import {
  columnFn,
  displayColumnName,
  displaySchemaName,
  displayTableName,
} from "../../components/entity-list/columns";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import { IncidentDirectionIconComponent } from "../../components/icons/incident-direction-icon";
import queryString from "query-string";
import { getIncidentStatusDisplayStr } from "../../utils/incident";
import { EVENT, trackEvent, getDatasourceDetailProps } from "../../utils/telemetry";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import { fnSorter } from "../../utils/sort";

import "./columns.scss";
import Tag from "../../atom/tag";
import { TagStatus } from "../../atom/tag/enums";

export function getTableRows(groupedIncidentList) {
  const incidentListData = [];
  for (let currentGroupedIncidentList of groupedIncidentList) {
    const { incidents, creatorInfo } = currentGroupedIncidentList;
    let metricName,
      metricType,
      metricCreationType,
      monitorData,
      monitorName,
      monitorType,
      metricDimension,
      dataSourceNames;
    if (creatorInfo.type === IncidentCreatorType.FILTER) {
      metricName = creatorInfo.kpiInfo.metadata.name;
      metricCreationType = metricCreationTypeLabel(creatorInfo.kpiInfo);
      metricType = getMetricTypeFromConfigData(creatorInfo.kpiInfo);
      metricDimension = creatorInfo.kpiInfo.config.dimension;
      monitorData = creatorInfo.filterInfo;
      monitorName = creatorInfo.filterInfo.metadata.name;
      monitorType = creatorInfo.filterInfo.config.symptom.type;
      dataSourceNames = [creatorInfo.dataSourceInfo.metadata.name];
    }

    let schemaName, tableName, columnName;
    if (creatorInfo.kpiInfo) {
      schemaName = displaySchemaName(creatorInfo.kpiInfo);
      tableName = displayTableName(creatorInfo.kpiInfo);
      columnName = displayColumnName(creatorInfo.kpiInfo);
    } else {
      const fullTableName = creatorInfo.tableInfo?.name ?? ".";
      [schemaName, tableName] = fullTableName.split(".");
      columnName = creatorInfo.columnInfo?.name ?? "";
    }

    for (let currentIncident of incidents) {
      incidentListData.push({
        incidentData: currentIncident,
        creatorInfo,
        metricName,
        metricType,
        metricCreationType,
        metricDimension,
        monitorData,
        monitorName,
        monitorType,
        dataSourceNames,
        schemaName,
        tableName,
        columnName,
        sliceName: getSliceDisplayString(currentIncident.slice, creatorInfo.kpiInfo),
      });
    }
  }

  return incidentListData;
}

function SeverityCell(props) {
  const { score } = props;
  const scoreDescription = score === IncidentScore.HIGH ? "High" : "Medium";
  return (
    <Tag status={score === IncidentScore.HIGH ? TagStatus.ERROR : TagStatus.WARNING}>
      {scoreDescription}
    </Tag>
  );
}
function FailingRecordsCell(props) {
  const { failingRecordsCount } = props;
  return (
    <div className={classesName("incident-list-failing-records")}>
      {failingRecordsCount}
    </div>
  );
}
function StatusCell(props) {
  const { status, canEdit, onChange } = props;
  return canEdit ? (
    <div className="incident-list-status-select">
      <Select
        value={status}
        options={incidentStatusOptions}
        style={{ width: "100%" }}
        onChange={onChange}
      />
    </div>
  ) : (
    getIncidentStatusDisplayStr(status)
  );
}

function DirectionCell(props) {
  const { direction } = props;
  const IconComponent = IncidentDirectionIconComponent(direction);
  return IconComponent ? <IconComponent /> : null;
}

function getIncidentOnGoingDisplayStr(ongoing) {
  return ongoing ? "Active" : "Completed";
}

export const incidentIdColumn = columnFn({
  title: "ID",
  key: ListPageColumnKey.INCIDENT_ID,
  defaultSortOrder: "descend",
  renderWithProps: (id, row, renderProps) => {
    const {
      workspaceUuid,
      workspaceUserPermissions,
      filterUuid,
      previewUuid,
      disableIdLink,
    } = renderProps;
    const canViewIncidentDetails = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_VIEW_INCIDENTDETAIL,
    ]);
    if (canViewIncidentDetails && !disableIdLink) {
      const incidentUrlPath = getURIInstance(URIPath.INCIDENT, {
        uuid: row.incidentData.uuid,
        workspaceUuid,
      });
      const incidentUrlQueryParams = { filterUuid, previewUuid };
      const incidentUrl = `${incidentUrlPath}?${queryString.stringify(
        incidentUrlQueryParams
      )}`;
      return (
        <Link to={incidentUrl}>
          <NgTableClickableText
            onClick={() => {
              trackEvent(EVENT.VIEW_INCIDENT, {
                ...getDatasourceDetailProps(row?.creatorInfo?.dataSourceInfo),
                table_id: row?.creatorInfo?.tableInfo?.uuid || "",
                schema_id: row?.creatorInfo?.schemaInfo?.uuid || "",
                column_id: row?.creatorInfo?.columnInfo?.uuid || "",
                monitor_id: row?.creatorInfo?.creatorUuid,
                metric_type: row?.metricType || "",
                incident_id: id,
                monitor_type: row?.monitorType || "",
                incident_uuid: row?.incidentData?.uuid,
              });
            }}
          >
            {id}
          </NgTableClickableText>
        </Link>
      );
    } else {
      return (
        <NgTextTooltip hideTooltipOnClick className="ng-table-text">
          {id}
        </NgTextTooltip>
      );
    }
  },
  width: 60,
  fixed: "left",
});

export const incidentSliceColumn = columnFn({
  title: "Slice",
  key: ListPageColumnKey.SLICE_NAME,
  width: 120,
});

export const incidentStartTimeColumn = columnFn({
  title: "Start time",
  key: ListPageColumnKey.INCIDENT_START_TIME,
  renderWithProps: (startTime) => getStringFromTimeStamp(startTime),
  width: 160,
});

export const incidentSeverityColumn = columnFn({
  title: "Severity",
  key: ListPageColumnKey.INCIDENT_SEVERITY,
  renderWithProps: (score) => <SeverityCell score={score} />,
  width: 100,
});

export const incidentDirectionColumn = columnFn({
  title: "Direction",
  key: ListPageColumnKey.INCIDENT_DIRECTION,
  getCompareVal: (indexedData, _row) => indexedData ?? -1,
  renderWithProps: (direction) => <DirectionCell direction={direction} />,
  width: 120,
});

export const incidentDurationColumn = columnFn({
  title: "Duration",
  key: ListPageColumnKey.INCIDENT_DURATION,
  renderWithProps: (duration) =>
    getDisplayTimeFromSecond(duration, false, [], false, true),
  width: 105,
});

export const incidentProgressColumn = columnFn({
  title: "Progress",
  key: ListPageColumnKey.INCIDENT_PROGRESS,
  getCompareVal: (indexedData, _row) => getIncidentOnGoingDisplayStr(indexedData),
  renderWithProps: (ongoing) => getIncidentOnGoingDisplayStr(ongoing),
  width: 105,
});

export const incidentStatusColumn = columnFn({
  title: "Status",
  key: ListPageColumnKey.INCIDENT_STATUS,
  getCompareVal: (indexedData, _row) => getIncidentStatusDisplayStr(indexedData),
  renderWithProps: (status, row, renderProps) => {
    const { workspaceUserPermissions, onChange } = renderProps;
    const canEdit = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_INCIDENT_VIEWS_EDIT_INCIDENTDETAIL,
    ]);
    return (
      <StatusCell
        status={status}
        canEdit={canEdit}
        onChange={(newStatus) => onChange(row.incidentData, newStatus)}
      />
    );
  },
  width: 130,
});

export const incidentValidationStatusColumn = columnFn({
  title: "Validation",
  key: ListPageColumnKey.INCIDENT_VALIDATION,
  getCompareVal: (indexedData, row) => {
    if (!isIncidentValidationSupported(row.metricType, row.creatorInfo?.kpiInfo)) {
      return "N/A";
    }

    return indexedData;
  },
  renderWithProps: (
    validationStatus,
    row,
    { selectedRows, workspaceUserPermissions, onChange }
  ) => {
    return (
      <IncidentValidationStatusCell
        value={validationStatus}
        record={row}
        selectedRows={selectedRows}
        workspaceUserPermissions={workspaceUserPermissions}
        onChange={onChange}
      />
    );
  },
  width: 120,
});

export const incidentCreationTimeColumn = columnFn({
  title: "Creation time",
  key: ListPageColumnKey.INCIDENT_CREATION_TIME,
  renderWithProps: (creationTime) => getStringFromTimeStamp(creationTime),
  width: 160,
});

export const incidentFailingRecordsCount = columnFn({
  title: "Failing Records Count",
  key: ListPageColumnKey.FAILING_RECORD_COUNT,
  sorter: {
    compare: fnSorter((row) => {
      let failingRecordsCount = row.incidentData?.failingRecordsCount;

      if (failingRecordsCount?.toLowerCase?.() === "n/a") {
        failingRecordsCount = -Infinity;
      } else {
        failingRecordsCount = Number.parseInt(failingRecordsCount);
        if (isNaN(failingRecordsCount)) {
          failingRecordsCount = -Infinity;
        }
      }

      return failingRecordsCount;
    }),
  },
  renderWithProps: (failingRecordsCount) => (
    <FailingRecordsCell failingRecordsCount={failingRecordsCount} />
  ),
  width: 210,
});
