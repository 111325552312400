import React, { useState } from "react";
import Button, { ButtonType } from "../../../../atom/Button";
import Dialog from "../../../../atom/dialog";
import Icon, { IconName, IconSizes } from "../../../../elements/Icon";
import { DialogSize } from "../../../../atom/shared/enums";
import useToggle from "../../../../hooks/useToggle";
import BodyLarge from "../../../../elements/Typography/BodyLarge";
import AlertConfigSection from "./alert-config-section";
import NgToggleCheckbox from "../../../toggle-checkbox/ng-toggle-checkbox";

import { ConnectedSliceValueSelect } from "../../../../views/profiler/custom-metric/slice-value-select";
import BodySmall from "../../../../elements/Typography/BodySmall";
import { FontWeights, TypographyColors } from "../../../../elements/Typography/enum";
import { getSliceDisplayString, memoize } from "../../../../utils/general";
import NgDropdownMenu from "../../../ng-dropdown-menu";

import "./slice-overrides-alert-config.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import NgTooltip from "../../../tooltip/ng-tooltip";
import { isSlicedMetric } from "../../../../utils/metric";

const getChannelAndSchedulName = memoize(
  (config, alertChannelOptions, scheduleOptions) => {
    const firstChannel = config.alertConfig.channels[0];
    const firstSchedule = config.alertConfig.mutingSchedules[0];

    let channelName = firstChannel
      ? alertChannelOptions.find((opt) => opt.value === firstChannel.channelId)
          ?.label || "Unknown"
      : "";
    channelName = channelName.includes(":") ? channelName.split(":")[1] : channelName;
    const scheduleName = firstSchedule
      ? scheduleOptions.find((opt) => opt.value === firstSchedule)?.label || "Unknown"
      : "";

    return { channelName, scheduleName };
  }
);

const getDefaultSliceAlertState = (selectedSlice) => {
  return {
    selectedSlice: selectedSlice,
    channels: [],
    mutingSchedules: [],
    isMuted: false,
  };
};
export default function SliceOverrideAlerts(props) {
  const {
    sliceValueList,
    metric,
    alertChannelOptions,
    scheduleOptions,
    onUpdate,
    sliceAlertConfigs = [],
    onDelete,
  } = props;
  const [visible, toggleVisibility] = useToggle();
  const [editIndex, setEditIndex] = useState(-1);
  const [sliceAlertState, setSliceAlertState] = useState(getDefaultSliceAlertState);
  const onNewAlertClick = () => {
    setEditIndex(-1);

    setSliceAlertState(getDefaultSliceAlertState());
    toggleVisibility();
  };
  const onSave = () => {
    const updatedConfig = {
      metricSlice: sliceAlertState.selectedSlice,
      alertConfig: {
        channels: sliceAlertState.channels.map((channelId) => ({ channelId })),
        mutingSchedules: sliceAlertState.mutingSchedules,
        isMuted: sliceAlertState.isMuted,
      },
    };

    onUpdate(updatedConfig, editIndex);
    setEditIndex(-1);
    toggleVisibility();
  };

  const updateSliceAlertState = (field, value) => {
    setSliceAlertState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const canSave = sliceAlertState.channels.length > 0 && sliceAlertState.selectedSlice;

  const onSliceAlertEdit = (index) => {
    setEditIndex(index);
    const configToEdit = sliceAlertConfigs[index];
    setSliceAlertState({
      selectedSlice: configToEdit.metricSlice,
      channels: configToEdit.alertConfig.channels.map((c) => c.channelId),
      mutingSchedules: configToEdit.alertConfig.mutingSchedules,
      isMuted: configToEdit.alertConfig.isMuted,
    });
    toggleVisibility();
  };
  const onCancel = () => {
    setEditIndex(-1);
    toggleVisibility();
  };

  if (!isSlicedMetric(metric)) {
    return null;
  }

  return (
    <div className="slice-override-alert-container">
      <div className="slice-select-label">
        <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
          Select slice
        </BodySmall>
        <NgTooltip title="Note. Only immediate alerts supported for slices">
          <InfoCircleOutlined />
        </NgTooltip>
      </div>

      <SliceAlertConfigList
        sliceAlertConfigs={sliceAlertConfigs}
        onSliceAlertEdit={onSliceAlertEdit}
        scheduleOptions={scheduleOptions}
        alertChannelOptions={alertChannelOptions}
        onDelete={onDelete}
        metric={metric}
      />
      <Button
        icon={<Icon name={IconName.Plus} size={IconSizes.MEDIUM} />}
        type={ButtonType.LINK}
        label="Slice Alerting"
        onClick={onNewAlertClick}
        className="create-slice-alert"
      />
      <SliceAlertConfigDialog
        visible={visible}
        onCancel={onCancel}
        onOk={onSave}
        sliceValueList={sliceValueList}
        metric={metric}
        alertChannelOptions={alertChannelOptions}
        scheduleOptions={scheduleOptions}
        sliceAlertState={sliceAlertState}
        updateSliceAlertState={updateSliceAlertState}
        canSave={canSave}
      />
    </div>
  );
}

function SliceAlertConfigDialog(props) {
  const {
    visible,
    onCancel,
    onOk,
    metric,
    alertChannelOptions,
    scheduleOptions,
    sliceAlertState,
    updateSliceAlertState,
    canSave = false,
  } = props;
  return (
    <Dialog
      size={DialogSize.SMALL}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      title={<BodyLarge>Slice alerting</BodyLarge>}
      mask
      destroyOnClose
      className="slice-alert-config-dialog"
      okButtonProps={{ disabled: !canSave }}
    >
      <div className="slice-select-label">
        <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
          Select slice
        </BodySmall>
      </div>
      <ConnectedSliceValueSelect
        metric={metric}
        mode="single"
        onChange={(newSelectedSlice) => {
          updateSliceAlertState("selectedSlice", newSelectedSlice);
        }}
        value={sliceAlertState.selectedSlice}
        style={{ width: "100%" }}
      />
      <div className="rule-alert-channel-container">
        <AlertConfigSection
          icon={<AlertChannelIcon />}
          text="Manage alerts - Where do you want to receive notifications about this monitor?"
          value={sliceAlertState.channels}
          placeholder="Select"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
          }
          options={alertChannelOptions}
          onChange={(newChannels) => {
            updateSliceAlertState("channels", newChannels);
          }}
          mode="multiple"
        />
      </div>

      <div className="rule-alert-channel-container">
        <AlertConfigSection
          icon={<ScheduleIcon />}
          text="Notification muting schedule (Optional) - Use schedules to control when notifications should be sent."
          size="large"
          value={sliceAlertState.mutingSchedules}
          placeholder="Select"
          filterOption={true}
          options={scheduleOptions}
          onChange={(newMutingSchedules) => {
            updateSliceAlertState("mutingSchedules", newMutingSchedules);
          }}
          mode="multiple"
        />
      </div>

      <NgToggleCheckbox
        size="mini"
        value={sliceAlertState.isMuted}
        onChange={(newIsMuted) => {
          updateSliceAlertState("isMuted", newIsMuted);
        }}
        label="Mute notifications"
        labelPosition="left"
      />
    </Dialog>
  );
}

function SliceAlertConfigList(props) {
  const {
    sliceAlertConfigs,
    onSliceAlertEdit,
    scheduleOptions,
    alertChannelOptions,
    onDelete,
    metric,
  } = props;
  const renderSliceAlerts = () => {
    if (!Array.isArray(sliceAlertConfigs) || sliceAlertConfigs?.length < 1) {
      return null;
    }
    return sliceAlertConfigs?.map?.((config, index) => {
      const { channelName, scheduleName } = getChannelAndSchedulName(
        config,
        alertChannelOptions,
        scheduleOptions
      );
      const sliceName = getSliceDisplayString(config.metricSlice, metric);

      const menuItems = [
        {
          label: "Edit",
          key: "edit",
          icon: <Icon name={IconName.PencilSimpleLine} size={IconSizes.MEDIUM} />,
          onClick: () => onSliceAlertEdit(index),
        },
        {
          label: "Delete",
          key: "delete",
          icon: <Icon name={IconName.Trash} size={IconSizes.MEDIUM} />,
          danger: true,
          onClick: () => {
            onDelete(index);
          },
        },
      ];
      const getSliceAlertLinkText = () => {
        let finalLinkText = channelName;
        if (scheduleName) {
          finalLinkText += ` | ${scheduleName}`;
        }
        return finalLinkText;
      };
      const content = (
        <div className="slice-alert-config-item">
          <div className="slice-alert-title">
            <BodySmall color={TypographyColors.INFO2}>{sliceName}</BodySmall>
          </div>
          <div className="slice-alert-config">
            <BodySmall color={TypographyColors.LINK}>
              {getSliceAlertLinkText()}
            </BodySmall>
            {config.alertConfig.isMuted && (
              <BodySmall color={TypographyColors.LINK} weight={FontWeights.MEDIUM}>
                {"  (Mute)"}
              </BodySmall>
            )}
          </div>
        </div>
      );

      return (
        <NgDropdownMenu
          key={`${sliceName}-${index}`}
          menuItems={menuItems}
          trigger={content}
        />
      );
    });
  };
  return renderSliceAlerts();
}

export function AlertChannelIcon(props) {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path d="M0 0H68V68H0V0Z" fill="#F7F7FB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 25C30.6863 25 28 27.6863 28 31V40H40V31C40 27.6863 37.3137 25 34 25ZM42 40V31C42 26.5817 38.4183 23 34 23C29.5817 23 26 26.5817 26 31V40H24C23.4477 40 23 40.4477 23 41C23 41.5523 23.4477 42 24 42H30.5354C30.7781 43.6961 32.2368 45 34 45C35.7632 45 37.2219 43.6961 37.4646 42H44C44.5523 42 45 41.5523 45 41C45 40.4477 44.5523 40 44 40H42ZM35.4146 42H32.5854C32.7913 42.5826 33.3469 43 34 43C34.6531 43 35.2087 42.5826 35.4146 42Z"
        fill="#121224"
      />
    </svg>
  );
}

export function ScheduleIcon(props) {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path d="M0 0H68V68H0V0Z" fill="#F7F7FB" />
      <path
        d="M29 32.5C28.4477 32.5 28 32.9477 28 33.5C28 34.0523 28.4477 34.5 29 34.5H34.5C35.0523 34.5 35.5 34.0523 35.5 33.5C35.5 32.9477 35.0523 32.5 34.5 32.5H29Z"
        fill="#121224"
      />
      <path
        d="M28 37.5C28 36.9477 28.4477 36.5 29 36.5H39C39.5523 36.5 40 36.9477 40 37.5C40 38.0523 39.5523 38.5 39 38.5H29C28.4477 38.5 28 38.0523 28 37.5Z"
        fill="#121224"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 24C29.5523 24 30 24.4477 30 25V26H38V25C38 24.4477 38.4477 24 39 24C39.5523 24 40 24.4477 40 25V26H43C44.1046 26 45 26.8954 45 28V41C45 42.1046 44.1046 43 43 43H25C23.8954 43 23 42.1046 23 41V28C23 26.8954 23.8954 26 25 26H28V25C28 24.4477 28.4477 24 29 24ZM38 28V29C38 29.5523 38.4477 30 39 30C39.5523 30 40 29.5523 40 29V28H43V41H25V28H28V29C28 29.5523 28.4477 30 29 30C29.5523 30 30 29.5523 30 29V28H38Z"
        fill="#121224"
      />
    </svg>
  );
}
