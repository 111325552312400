import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "../../components/tooltip/ng-tooltip";
import EntityList from "../../components/entity-list/entity-list";
import ProfilerConfirmationDialog from "../profiler/profiler-confirmation-dialog";
import IntegrationActionsMenu from "./integration-actions-menu";
import { hasPermission } from "../../utils/uri-path";
import { AlertingChannelType, getAlertingConnectionHealth } from "../../utils/alerting";
import { AppPermissions } from "../../utils/permissions";
import { getAddDropdownMenuItems } from "./integration-utils";
import { fnSorter } from "../../utils/sort";
import {
  EmailTypeIcon,
  JiraTypeIcon,
  MSTeamsTypeIcon,
  OpsGenieTypeIcon,
  PagerDutyTypeIcon,
  ServiceNowTypeIcon,
  SlackTypeIcon,
} from "./integration-icon";
import EntityListPage from "../../components/entity-list/entity-list-page";
import {
  ConnectionHealth,
  ListPageColumnKey,
  TakeoverWidth,
  getConnectionHealthDisplayName,
} from "../../utils/enums";
import AddIntegrationDialog from "./add-integration-dialog";
import EditIntegrationDialog from "./edit-integration-dialog";
import { getConnectionHealthIcon } from "../../utils/icon";
import { TextWithIcon } from "../../components/button/ng-button";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";

import BodySmall from "../../elements/Typography/BodySmall";
import PageHeader from "../../components/PageHeader";
import { IconName, IconSizes } from "../../elements/Icon";
import {
  SideBarNavigationAction,
  WorkspaceItemKeyToLabelMapper,
} from "../../components/sidebar/util";
import Button from "../../atom/Button";
import NgDropdownMenu from "../../components/ng-dropdown-menu";

import "./integration-list.scss";
const integrationTooltipStyle = {
  width: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "5px",
  margin: "10px",
};

function getTableRows(integrationList, monitorList) {
  const monitorNamesByIntegrationUuid = monitorList.reduce(
    (monitorNamesByUuid, monitor) => {
      if (
        !monitor.config ||
        !monitor.config.alertConfig ||
        !monitor.config.alertConfig.channels
      ) {
        return monitorNamesByUuid;
      }

      for (let alertingChannel of monitor.config.alertConfig?.channels ?? []) {
        const alertChannelId = alertingChannel["channelId"];
        if (!monitorNamesByUuid.hasOwnProperty(alertChannelId)) {
          monitorNamesByUuid[alertChannelId] = [];
        }

        monitorNamesByUuid[alertChannelId].push({
          name: monitor.metadata.name,
          id: monitor.metadata.uuid,
        });
      }

      return monitorNamesByUuid;
    },
    {}
  );

  return integrationList.data.map((integration) => {
    let detail = "";
    let detailDescription = "";
    const { type } = integration;
    if (
      [
        AlertingChannelType.SLACK,
        AlertingChannelType.MSTEAMS,
        AlertingChannelType.PAGERDUTY,
      ].includes(type)
    ) {
      detail = integration.description || "";
    } else if (type === AlertingChannelType.JIRA) {
      detail = integration.key || "";
    } else if (type === AlertingChannelType.SERVICENOW) {
      detail = integration.host || "";
    } else if (type === AlertingChannelType.EMAIL) {
      detail = integration.emailAddressList?.length || 0;
      if (detail > 0) {
        detailDescription = (
          <div style={integrationTooltipStyle}>
            {integration.emailAddressList.map((email) => (
              <div key={email}>{email}</div>
            ))}
          </div>
        );
      }
    }

    return {
      ...integration,
      detail,
      detailDescription,
      monitorNames: monitorNamesByIntegrationUuid[integration.id] || [],
      connectionHealth: getAlertingConnectionHealth(integration),
    };
  });
}

// Integration types that have a large number of fields, and so will be rendered
// in a panel as opposed to a modal dialog.
const panelIntegrationTypes = [
  AlertingChannelType.JIRA,
  AlertingChannelType.SERVICENOW,
  AlertingChannelType.OPSGENIE,
];

function IntegrationList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    history,
    userInfo,
    workspaceUserPermissions,
    ruleList,
    integrationList,
    getRuleList,
    getIntegrationList,
    addIntegration,
    editIntegration,
    deleteIntegration,
    previewIntegration,
    openWorkspaceTakeover,
    closeTakeover,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.INTEGRATION,
    integrations: integrationList.data,
  });

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [addIntegrationType, setAddIntegrationType] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [contextMenuItem, setContextMenuItem] = useState(null);

  useEffect(() => {
    getRuleList(workspaceUuid);
    getIntegrationList(workspaceUuid);
  }, [workspaceUuid, getRuleList, getIntegrationList]);

  const loading = integrationList.loading;

  const tableRows = useMemo(() => {
    return getTableRows(integrationList, ruleList);
  }, [integrationList, ruleList]);

  const canAddIntegration = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_EDIT_ALERTINGCHANNELLIST,
  ]);

  const canModifyIntegration = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_EDIT_ALERTINGCHANNELDETAIL,
  ]);

  const canPreviewIntegration = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_ALERTING_CHANNEL_VIEWS_EDIT_ALERTINGCHANNELLISTINSPECTION,
  ]);

  function onAddClicked(addIntegrationType) {
    setAddIntegrationType(addIntegrationType);
    if (panelIntegrationTypes.includes(addIntegrationType)) {
      // Panel will be rendered. Ignores the `isAddOpen` flag.
      const outsideClickWrap = {};
      openWorkspaceTakeover(
        <AddIntegrationDialog
          workspaceUuid={workspaceUuid}
          type={addIntegrationType}
          onAdd={onAddIntegration}
          onPreview={onPreviewIntegration}
          setIsOpen={(open) => {
            if (!open) {
              closeTakeover();
            }
          }}
          outsideClickWrap={outsideClickWrap}
        />,
        TakeoverWidth.NORMAL,
        () => outsideClickWrap?.fn()
      );
    } else {
      // Modal dialog will be rendered.
      setIsAddOpen(true);
    }
  }

  function onAddIntegration(data) {
    addIntegration(workspaceUuid, data);
  }

  function onEditIntegration(id, data) {
    editIntegration(workspaceUuid, id, data);
  }

  function onPreviewIntegration(data) {
    return previewIntegration(workspaceUuid, data);
  }

  function onDeleteIntegration(data) {
    deleteIntegration(workspaceUuid, data);
  }

  const integrationActionsSharedMenuProps = {
    workspaceUuid,
    workspaceUserPermissions,
    history: history,
    onDeleteIntegrationClick: (workspaceUuid, integration) => {
      setContextMenuItem(integration);
      setIsDeleteOpen(true);
    },
    onEditIntegrationClick: (workspaceUuid, integration) => {
      if (panelIntegrationTypes.includes(integration.type)) {
        // Panel will render. Ignores isEditOpen flag.
        const outsideClickWrap = {};
        openWorkspaceTakeover(
          <EditIntegrationDialog
            workspaceUuid={workspaceUuid}
            data={integration}
            onEdit={onEditIntegration.bind(null, integration.id)}
            onPreview={onPreviewIntegration}
            modalIsOpen={isEditOpen}
            outsideClickWrap={outsideClickWrap}
            setIsOpen={(open) => {
              if (!open) {
                closeTakeover();
              }
            }}
            enableSave={canModifyIntegration}
            enablePreview={canPreviewIntegration}
          />,
          TakeoverWidth.NORMAL,
          () => outsideClickWrap?.fn()
        );
      } else {
        // Modal dialog will be rendered.
        setContextMenuItem(integration);
        setIsEditOpen(true);
      }
    },
    loading: false,
  };

  const columns = [
    {
      title: "TITLE",
      key: "title",
      dataIndex: "title",
      width: 160,
      sorter: { compare: fnSorter((row) => row.title.toLowerCase()) },
      defaultSortOrder: "ascend",
      render: (title, integrationData) => {
        return <BodySmall>{title}</BodySmall>;
      },
    },
    {
      title: "TYPE",
      key: "type",
      dataIndex: "type",
      width: 70,
      sorter: { compare: fnSorter((row) => row.type) },
      render: (type) => {
        let icon;
        let tooltip;
        if (type === AlertingChannelType.SLACK) {
          icon = <SlackTypeIcon />;
          tooltip = "Slack";
        } else if (type === AlertingChannelType.PAGERDUTY) {
          icon = <PagerDutyTypeIcon />;
          tooltip = "Pagerduty";
        } else if (type === AlertingChannelType.MSTEAMS) {
          icon = <MSTeamsTypeIcon />;
          tooltip = "Microsoft Teams";
        } else if (type === AlertingChannelType.JIRA) {
          icon = <JiraTypeIcon />;
          tooltip = "Jira";
        } else if (type === AlertingChannelType.SERVICENOW) {
          icon = <ServiceNowTypeIcon />;
          tooltip = "Service Now";
        } else if (type === AlertingChannelType.OPSGENIE) {
          icon = <OpsGenieTypeIcon />;
          tooltip = "Opsgenie";
        } else {
          icon = <EmailTypeIcon />;
          tooltip = "Email List";
        }

        return (
          <Tooltip title={tooltip}>
            <span>{icon}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Connection health",
      key: "connectionHealth",
      dataIndex: "connectionHealth",
      sorter: { compare: fnSorter((row) => row.connectionHealth) },
      width: 185,
      render: (connectionHealth, row) => {
        const IconComponent = getConnectionHealthIcon(connectionHealth);
        const connectionHealthDisplayName =
          getConnectionHealthDisplayName(connectionHealth);

        const content = (
          <TextWithIcon icon={<IconComponent />} iconPosition="left" inline={true}>
            {connectionHealthDisplayName}
          </TextWithIcon>
        );

        const runStatusMessage = row.status?.runStatusMessage ?? "";
        if (connectionHealth === ConnectionHealth.SUCCESS || !runStatusMessage) {
          return content;
        }

        const errorContent = (
          <div style={{ ...integrationTooltipStyle, alignItems: "left" }}>
            {runStatusMessage}
          </div>
        );
        return (
          <Tooltip title={errorContent}>
            <span>{content}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "detail",
      key: "detail",
      dataIndex: "detail",
      sorter: { compare: fnSorter((row) => row.detail) },
      render: (detail, { detailDescription }) => {
        if (!detailDescription) {
          return detail;
        }

        return (
          <Tooltip title={detailDescription} overlayStyle={{ maxWidth: "none" }}>
            <span style={{ color: "#4832f3" }}>{detail}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "MONITORS CONFIGURED",
      key: "monitorNames",
      dataIndex: ["monitorNames"],
      sorter: { compare: fnSorter((row) => row.monitorNames.length) },
      render: (monitorNames) => {
        if (monitorNames.length === 0) {
          return 0;
        }

        const toolTipContent = (
          <div style={integrationTooltipStyle}>
            {monitorNames.map(({ name, id }) => (
              <div key={id}>{name}</div>
            ))}
          </div>
        );

        return (
          <Tooltip title={toolTipContent} overlayStyle={{ maxWidth: "none" }}>
            <span style={{ color: "#4832f3" }}>{monitorNames.length}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "",
      key: ListPageColumnKey.ACTIONS,
      width: 50,
      render: (_, integrationData) => (
        <IntegrationActionsMenu
          trigger={null}
          triggerClassName="actions-trigger"
          integrations={[integrationData]}
          {...integrationActionsSharedMenuProps}
        />
      ),
    },
  ];

  return (
    <EntityListPage>
      <PageHeader
        iconSize={IconSizes.XLARGE}
        iconName={IconName.Plugs}
        title={WorkspaceItemKeyToLabelMapper[SideBarNavigationAction.INTEGRATION]}
        rightContent={
          canAddIntegration && (
            <NgDropdownMenu
              trigger={
                <Button
                  disabled={loading}
                  label="Create Integration +"
                  testId="create-integration-button"
                />
              }
              menuItems={getAddDropdownMenuItems(onAddClicked, {
                isEmailEnabled: !userInfo.isLightupLite,
              })}
            />
          )
        }
      />

      <div className="entity-list-table">
        <EntityList
          addText="Create Integration"
          addDropdownMenuItems={getAddDropdownMenuItems(onAddClicked, {
            isEmailEnabled: !userInfo.isLightupLite,
          })}
          searchOptions={searchOptions}
          columns={columns}
          rows={tableRows}
          getRowKey={(integration) => integration.id}
          loading={loading}
          getFilteredRows={filterRows}
          testId="entity-list-integration"
        />
      </div>
      {isEditOpen && contextMenuItem && (
        <EditIntegrationDialog
          workspaceUuid={workspaceUuid}
          data={contextMenuItem}
          onEdit={onEditIntegration.bind(null, contextMenuItem.id)}
          onPreview={onPreviewIntegration}
          modalIsOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          enableSave={canModifyIntegration}
          enablePreview={canPreviewIntegration}
        />
      )}
      {isAddOpen && (
        <AddIntegrationDialog
          workspaceUuid={workspaceUuid}
          type={addIntegrationType}
          onAdd={onAddIntegration}
          onPreview={onPreviewIntegration}
          modalIsOpen={isAddOpen}
          setIsOpen={setIsAddOpen}
        />
      )}
      {isDeleteOpen && contextMenuItem && (
        <ProfilerConfirmationDialog
          modalIsOpen={isDeleteOpen}
          setIsOpen={setIsDeleteOpen}
          okClicked={onDeleteIntegration.bind(null, contextMenuItem)}
          usage={{
            loading: false,
            data: {},
          }}
          title="Delete channel"
          defaultConfirmationMsg={
            <div>
              Are you sure you want to delete this channel "
              <b>{contextMenuItem.title}</b>"?
              <p>
                This channel is currently used by{" "}
                <b>{contextMenuItem.monitorNames.length} monitors</b>.
              </p>
            </div>
          }
        />
      )}
    </EntityListPage>
  );
}

export default IntegrationList;
