import React, { useState, useEffect } from "react";
import AlertConfigSettingView from "./index";
import Dialog from "../../atom/dialog";
import { DialogSize } from "../../atom/shared/enums";
import "./alert-config-setting-dialog.scss";

function AlertConfigSettingDialogBodyComponent(props) {
  const { currentAlertingChannels, alertingChannelList, onChange } = props;

  return (
    <div className="alert-config-setting-dialog-body-component-container">
      <AlertConfigSettingView
        value={currentAlertingChannels}
        onChange={onChange}
        alertingChannelList={alertingChannelList}
        enableSchedule={false}
      />
    </div>
  );
}

function AlertConfigSettingDialog(props) {
  const {
    modalIsOpen,
    setModalIsOpen,
    currentAlertingChannels: defaultCurrentAlertingChannels,
    alertingChannelList,
    okClicked,
    monitor,
  } = props;

  const [currentAlertingChannels, setCurrentAlertingChannels] = useState(
    defaultCurrentAlertingChannels
  );
  useEffect(() => {
    setCurrentAlertingChannels(defaultCurrentAlertingChannels);
  }, [defaultCurrentAlertingChannels]);

  function closeModal() {
    setModalIsOpen(false);
    setCurrentAlertingChannels(defaultCurrentAlertingChannels);
  }

  function onOkClicked() {
    okClicked && okClicked(currentAlertingChannels);
    setModalIsOpen(false);
  }
  const title = monitor?.metadata?.name
    ? monitor?.metadata?.name
    : "Notification Channels";
  return (
    <Dialog
      title={title}
      visible={modalIsOpen}
      mask
      size={DialogSize.SMALL}
      onCancel={closeModal}
      onOk={onOkClicked}
      onClose={closeModal}
      destroyOnClose
      okText="Save"
    >
      <AlertConfigSettingDialogBodyComponent
        alertingChannelList={alertingChannelList}
        currentAlertingChannels={currentAlertingChannels}
        onChange={setCurrentAlertingChannels}
      />
    </Dialog>
  );
}

export default AlertConfigSettingDialog;
