import { Input } from "antd";
import PropTypes from "prop-types";
import Icon, { IconName } from "../../elements/Icon";
import { classesName } from "../../utils/css";
import "./search.scss";
function SearchInput(props) {
  const { className = "", testId, placeholder = "Search", ...restProps } = props;

  return (
    <Input.Search
      allowClear
      prefix={<Icon name={IconName.MagnifyingGlass} />}
      className={classesName("lightup-search-input", className)}
      placeholder={placeholder}
      data-testid={testId}
      {...restProps}
    />
  );
}

SearchInput.propTypes = {
  testId: PropTypes.string,
};
export default SearchInput;
