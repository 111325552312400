import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { getTimePeriodFromString } from "../../utils/time";
import { NgTableClickableText } from "../../components/table/ng-table";
import { fnSorter } from "../../utils/sort";
import EntityList, { getSelectedRows } from "../../components/entity-list/entity-list";
import {
  columnColumn,
  createdByColumn,
  createdOnColumn,
  dataSourceColumn,
  displayColumnName,
  displaySchemaName,
  displayTableName,
  metricCreationTypeColumn,
  metricLinkColumn,
  modifiedAtColumn,
  modifiedByColumn,
  monitorActions,
  monitorTypeColumn,
  schemaColumn,
  tableColumn,
  tagsColumn,
} from "../../components/entity-list/columns";
import { collectStats, countBy, indexBy } from "../../utils/iterables";
import DeleteMonitorConfirmationDialog from "./delete-monitor-confirmation-dialog";
import { ListPageColumnKey, MonitorRunStatus } from "../../utils/enums";
import {
  getURIInstance,
  hasPermission,
  URIPath,
  URIPathPermissions,
} from "../../utils/uri-path";
import MonitorStatusMenu, {
  canPauseMonitor,
  canResumeMonitor,
} from "./monitor-status-menu";
import MonitorStatusBadge, {
  getMonitorBadgeStatus,
  monitorStatusBadgeConfig,
} from "./monitor-status-badge";
import { explorerMetricUrl, isMonitorInVolatileState } from "../profiler/utils";
import AlertConfigSettingDialog from "../../components/alert-config-setting-view/alert-config-setting-dialog";
import { AppPermissions } from "../../utils/permissions";
import { useInterval } from "../../utils/hooks";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import ConfirmationDialog from "../../components/confirmation-dialog/ng-index";
import {
  getDefaultUserSetting,
  getQueryStringFromUserSetting,
} from "../../utils/user-setting";
import fromUnixTime from "date-fns/fromUnixTime";
import Link from "../../components/link";
import { metricCreationTypeLabel, metricDataSourceUuids } from "../../utils/metric";
import { getUpdatedTags } from "../../components/tag-group/ng-tag-group";

import {
  EVENT,
  PAGE,
  trackEvent,
  getMonitorDetailProps,
  getDatasourceDetailProps,
} from "../../utils/telemetry";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";
import AlertStatus from "./AlertStatus";
import "./monitor-list.scss";
import {
  SideBarNavigationAction,
  WorkspaceItemKeyToLabelMapper,
} from "../../components/sidebar/util";
import PageHeader from "../../components/PageHeader";
import { IconName, IconSizes } from "../../elements/Icon";
import Button from "../../atom/Button";

const POLL_MONITOR_INTERVAL_IN_MS = 60 * 1000; // 1 min

function getTableRows(ruleList, kpiList, ruleIncidentList, dataSourceList) {
  const metricsByUuid = indexBy(kpiList.data, (metric) => metric.metadata.uuid);
  const incidentsByMonitorUuid = countBy(
    ruleIncidentList,
    (groupedIncident) => groupedIncident.id,
    (groupedIncident) => groupedIncident.incidents.length
  );
  const dataSourcesByUuid = indexBy(
    dataSourceList.data,
    (dataSource) => dataSource.metadata.uuid
  );

  return ruleList.data.map((monitor) => {
    const monitorMetric = metricsByUuid[monitor.config.metrics[0]];
    const metricDataSourceNames = monitorMetric
      ? metricDataSourceUuids(monitorMetric)
          .map((sourceUuid) => dataSourcesByUuid[sourceUuid]?.metadata.name)
          .sort()
      : [];

    return {
      monitorData: monitor,
      monitorBadgeStatus: getMonitorBadgeStatus(monitor),
      metricData: monitorMetric,
      metricName: monitorMetric?.metadata?.name ?? "",
      metricType: monitorMetric ? getMetricTypeFromConfigData(monitorMetric) : "",
      metricCreationType: monitorMetric ? metricCreationTypeLabel(monitorMetric) : "",
      numIncidents: incidentsByMonitorUuid[monitor.metadata.uuid] ?? 0,
      metricDataSourceNames,
      metricSchemaName: displaySchemaName(monitorMetric),
      metricTableName: displayTableName(monitorMetric),
      metricColumnName: displayColumnName(monitorMetric),
      metricDimension: monitorMetric?.config?.dimension ?? "",
      metricUrl: monitorMetric ? explorerMetricUrl(monitorMetric) : null,
      trainingStatus: monitor.status.training.status,
    };
  });
}

function getRowStats(rows) {
  return collectStats(rows, {
    Total: (_row) => 1,
    Live: (row) =>
      [
        MonitorRunStatus.OK,
        MonitorRunStatus.LIVE,
        MonitorRunStatus.PARTIALLY_LIVE,
        MonitorRunStatus.RESUMING,
        MonitorRunStatus.LOADING,
      ].includes(row.monitorBadgeStatus)
        ? 1
        : 0,
    Paused: (row) =>
      [
        MonitorRunStatus.PAUSED_BY_USER,
        MonitorRunStatus.PAUSED_SOURCE,
        MonitorRunStatus.PAUSED_METRIC_PAUSE,
      ].includes(row.monitorBadgeStatus)
        ? 1
        : 0,
    Training: (row) =>
      row.monitorBadgeStatus === MonitorRunStatus.TRAINING_IN_PROGRESS ? 1 : 0,
    Error: (row) =>
      [
        MonitorRunStatus.EXCEPTION,
        MonitorRunStatus.PAUSED_TRAINING_FAILURE,
        MonitorRunStatus.PAUSED_TRAINING_FAILURE_INSUFFICIENT_DATA,
      ].includes(row.monitorBadgeStatus)
        ? 1
        : 0,
  });
}

function NumIncidentsCell(props) {
  const { numIncidents, queryPeriod, monitor } = props;

  if (numIncidents > 0) {
    const currentUserSetting = getDefaultUserSetting();
    currentUserSetting.time = {
      currentInterval: "1w",
      startTime: fromUnixTime(queryPeriod.startTimestamp),
      endTime: fromUnixTime(queryPeriod.endTimestamp),
      current: fromUnixTime(queryPeriod.endTimestamp),
    };

    currentUserSetting.localFilterSetting = {
      filterName: [monitor.metadata.name],
      severity: [],
      kpiName: [],
      slice: [],
      tagName: [],
      direction: [],
      showMyRule: false,
      showProceededDataOnly: false,
    };

    const queryString = getQueryStringFromUserSetting(currentUserSetting);
    return (
      <Link
        to={`${getURIInstance(URIPath.NG_INCIDENT_LIST, {
          workspaceUuid: monitor.metadata.workspaceId,
        })}${queryString}`}
        className="edit"
        target={"_self"}
      >
        {numIncidents}
      </Link>
    );
  } else {
    return 0;
  }
}

function getRowKey(row) {
  return row.monitorData.metadata.uuid;
}

function MonitorList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    history,
    workspaceUserPermissions,
    ruleList,
    ruleIncidentList,
    kpiList,
    dataSourceList,
    integrationList,
    tagList,
    getDataSourceList,
    getKpiList,
    getRuleList,
    getTagList,
    getIntegrationList,
    getRuleIncidentList,
    deleteRules,
    updateRulesConfig,
    updateRuleTags,
    ruleListPageConfiguration,
    getRuleListPageConfiguration,
    updateRuleListPageConfiguration,
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalTargets, setDeleteModalTargets] = useState([]);
  const [channelsModalOpen, setChannelsModalOpen] = useState(false);
  const [channelModalTarget, setChannelModalTarget] = useState(null);
  const [noDataSourcesModalOpen, setNoDataSourcesModalOpen] = useState(false);
  const [noMetricsModalOpen, setNoMetricsModalOpen] = useState(false);
  const [queryPeriod] = useState(getTimePeriodFromString("1w"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  useEffect(() => {
    getRuleListPageConfiguration(workspaceUuid);
    getDataSourceList(workspaceUuid);
    getKpiList(workspaceUuid);
    getRuleList(workspaceUuid);
    getTagList(workspaceUuid);
    getIntegrationList(workspaceUuid);
    getRuleIncidentList(workspaceUuid, {
      startTime: queryPeriod.startTimestamp,
      endTime: queryPeriod.endTimestamp,
    });
  }, [
    workspaceUuid,
    getDataSourceList,
    getKpiList,
    getRuleList,
    getTagList,
    getIntegrationList,
    getRuleIncidentList,
    getRuleListPageConfiguration,
    queryPeriod,
  ]);

  useInterval(() => {
    if (ruleList.data.some(isMonitorInVolatileState)) {
      getRuleList(workspaceUuid, { quiet: true });
    }
  }, POLL_MONITOR_INTERVAL_IN_MS);

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.MONITOR,
    metrics: kpiList.data,
    monitors: ruleList.data,
    dataSources: dataSourceList.data,
  });

  const loading = ruleList.loading || kpiList.loading || requestInProgress;

  const tableRows = useMemo(() => {
    return getTableRows(ruleList, kpiList, ruleIncidentList, dataSourceList);
  }, [ruleList, kpiList, ruleIncidentList, dataSourceList]);

  const selectedRows = useMemo(() => {
    return getSelectedRows(tableRows, selectedRowKeys, getRowKey);
  }, [tableRows, selectedRowKeys]);

  const canModify = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
  ]);

  const canModifyMonitorColumnList = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_WORKSPACES_VIEWS_EDIT_WORKSPACECONFIGURATIONSPAGEVIEW,
  ]);

  const canAddDataSource = hasPermission(
    workspaceUserPermissions,
    URIPathPermissions[URIPath.ADD_DATA_SOURCE]
  );

  const canAddMetric = hasPermission(
    workspaceUserPermissions,
    URIPathPermissions[URIPath.ADD_METRIC]
  );

  function onEditMonitor(monitor) {
    const nextUrl = getURIInstance(URIPath.EDIT_MONITOR, {
      id: monitor.metadata.uuid,
      workspaceUuid,
    });
    history.push(nextUrl);
  }

  function onCloneMonitor(monitor) {
    const nextUrl = `${getURIInstance(URIPath.ADD_MONITOR, {
      workspaceUuid,
    })}?cloneFrom=${monitor.metadata.uuid}`;
    history.push(nextUrl);
  }

  const onDeleteBulk = () => {
    const monitorTargets =
      selectedRows.length > 0
        ? selectedRows.map((row) => row.monitorData)
        : [selectedRows[0].monitorData];
    onDeleteMonitors(monitorTargets);
  };

  function onDeleteMonitors(monitors) {
    setDeleteModalTargets(monitors);
    setDeleteModalOpen(true);
  }

  function onDeleteConfirmed(monitors) {
    trackEvent(EVENT.DELETE_MONITOR, {
      ...getMonitorDetailProps(monitors[0]),
      page: PAGE.MONITORS,
    });

    setRequestInProgress(true);

    deleteRules(
      workspaceUuid,
      monitors.map((monitor) => monitor.metadata.uuid)
    ).finally(() => setRequestInProgress(false));
    setSelectedRowKeys([]);
    setDeleteModalOpen(false);
  }

  function onPauseResume(monitors, newIsLive) {
    const updatedMonitors = monitors.map((monitor) => ({
      ...monitor,
      config: { ...monitor.config, isLive: newIsLive },
    }));
    updateRulesConfig(workspaceUuid, updatedMonitors);
  }

  function onAlertConfigUpdated(updatedMonitors) {
    updateRulesConfig(workspaceUuid, updatedMonitors);
  }

  function onManageChannels(monitors) {
    setChannelModalTarget(monitors);
    setChannelsModalOpen(true);
  }

  function onChannelsUpdated(newAlertConfig) {
    const updatedMonitors = channelModalTarget.map((modalTarget) => ({
      ...modalTarget,
      config: {
        ...modalTarget.config,
        alertConfig: newAlertConfig,
      },
    }));
    onAlertConfigUpdated(updatedMonitors);
  }

  const channelModalTargetChannels =
    channelModalTarget?.length === 1 ? channelModalTarget[0].config.alertConfig : [];

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: ["monitorData", "metadata", "idSerial"],
      defaultSortOrder: "descend",
      sorter: { compare: fnSorter((row) => row.monitorData.metadata?.idSerial ?? -1) },
      width: 60,
      fixed: "left",
    },
    {
      title: "Monitor Name",
      key: "name",
      dataIndex: ["monitorData", "metadata", "name"],
      sorter: {
        compare: fnSorter((row) => row.monitorData.metadata.name.toLowerCase()),
      },
      render: (name, { metricData, monitorData }) => {
        return (
          <NgTableClickableText
            overflow="break"
            onClick={() => onEditMonitor(monitorData)}
            clickable={canModify}
          >
            {name}
          </NgTableClickableText>
        );
      },
      width: 220,
      fixed: "left",
    },
  ];
  const canViewMetric = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_STREAM_VIEWS_VIEW_STREAMDETAIL,
  ]);
  const configurableColumns = [
    metricLinkColumn({
      dataIndex: ["metricData"],
      renderProps: { workspaceUserPermissions, history, canViewMetric },
    }),
    metricCreationTypeColumn({
      dataIndex: ["metricCreationType"],
      title: "Metric class",
      width: 140,
    }),
    monitorTypeColumn({ dataIndex: ["monitorData", "config", "symptom", "type"] }),
    {
      title: "Status",
      key: ListPageColumnKey.MONITOR_STATUS,
      dataIndex: ["monitorData", "config", "isLive"],
      sorter: {
        compare: fnSorter(({ monitorData }) => {
          const badgeConfig = monitorStatusBadgeConfig(monitorData);
          return `${badgeConfig.title} ${badgeConfig.tooltip}`;
        }),
      },
      render: (isLive, { monitorData }) => {
        const statusChangeAvailable =
          canModify && (canPauseMonitor(monitorData) || canResumeMonitor(monitorData));
        const badge = (
          <MonitorStatusBadge monitor={monitorData} clickable={statusChangeAvailable} />
        );
        if (statusChangeAvailable) {
          const targetMonitors =
            selectedRows.length > 0
              ? selectedRows.map((row) => row.monitorData)
              : [monitorData];
          return (
            <MonitorStatusMenu
              trigger={badge}
              monitors={targetMonitors}
              workspaceUserPermissions={workspaceUserPermissions}
              onPause={onPauseResume}
              onResume={onPauseResume}
            />
          );
        } else {
          return badge;
        }
      },
      width: 200,
    },
    {
      title: "Alerting",
      key: ListPageColumnKey.ALERTING,
      dataIndex: ["monitorData"],
      sorter: {
        compare: fnSorter((row) =>
          row.monitorData.config.alertConfig?.isMuted
            ? -1
            : row.monitorData.config.alertConfig?.channels?.length ?? 0
        ),
      },
      render: (monitorData) => {
        return (
          <AlertStatus
            monitorData={monitorData}
            selectedRows={selectedRows}
            canModify={canModify}
            workspaceUserPermissions={workspaceUserPermissions}
            onAlertConfigUpdated={onAlertConfigUpdated}
            onManageChannels={onManageChannels}
          />
        );
      },
      width: 145,
    },
    createdByColumn({
      dataIndex: ["monitorData", "metadata", "ownedBy", "username"],
    }),
    createdOnColumn({
      dataIndex: ["monitorData", "status", "createdTs"],
    }),
    modifiedByColumn({
      dataIndex: ["monitorData", "metadata", "updatedBy", "username"],
    }),
    modifiedAtColumn({
      dataIndex: ["monitorData", "status", "configUpdatedTs"],
    }),
    {
      title: "Incidents (7D)",
      key: ListPageColumnKey.INCIDENT_COUNT_7D,
      dataIndex: ["numIncidents"],
      sorter: {
        compare: fnSorter((row) => row.numIncidents),
      },
      render: (numIncidents, { monitorData }) => {
        return (
          <NumIncidentsCell
            numIncidents={numIncidents}
            monitor={monitorData}
            queryPeriod={queryPeriod}
          />
        );
      },
      width: 140,
    },
    dataSourceColumn({
      dataIndex: ["metricDataSourceNames"],
    }),
    schemaColumn({ dataIndex: ["metricSchemaName"] }),
    tableColumn({ dataIndex: ["metricTableName"] }),
    columnColumn({ dataIndex: ["metricColumnName"] }),
    tagsColumn({
      dataIndex: ["monitorData", "metadata", "tags"],
      renderProps: {
        editEnabled: canModify,
        tagList,
        onChange: (_newTags, changeObject, row) => {
          const targetRows = selectedRows.length > 0 ? selectedRows : [row];
          targetRows.forEach((selectedRow) => {
            updateRuleTags(
              workspaceUuid,
              selectedRow.monitorData,
              getUpdatedTags(selectedRow.monitorData.metadata.tags, changeObject)
            );
          });
        },
      },
    }),
  ];

  if (canModify) {
    configurableColumns.push(
      monitorActions({
        dataIndex: ["monitorData"],
        renderProps: {
          editEnabled: canModify,
          workspaceUuid,
          onDeleteMonitors,
          onCloneMonitor,
          canModify,
          isMultipleSelected: selectedRowKeys.length > 1,
        },
      })
    );
  }

  function onColumnKeyListChange(columns) {
    updateRuleListPageConfiguration(workspaceUuid, {
      ...ruleListPageConfiguration,
      columns,
    });
  }

  const columnKeyOptions = canModifyMonitorColumnList
    ? [
        { label: "Metric Name", value: ListPageColumnKey.METRIC_NAME },
        { label: "Metric class", value: ListPageColumnKey.METRIC_CREATION_TYPE },
        { label: "Status", value: ListPageColumnKey.MONITOR_STATUS },
        { label: "Alerting", value: ListPageColumnKey.ALERTING },
        { label: "Created by", value: ListPageColumnKey.CREATED_BY },
        { label: "Created on", value: ListPageColumnKey.CREATED_AT },
        { label: "Last modified by", value: ListPageColumnKey.UPDATED_BY },
        { label: "Last modified", value: ListPageColumnKey.UPDATED_AT },
        { label: "Incidents (7D)", value: ListPageColumnKey.INCIDENT_COUNT_7D },
        { label: "Datasource", value: ListPageColumnKey.DATASOURCE },
        { label: "Schema", value: ListPageColumnKey.SCHEMA },
        { label: "Table", value: ListPageColumnKey.TABLE },
        { label: "Column", value: ListPageColumnKey.COLUMN_NAME },
        { label: "Tags", value: ListPageColumnKey.TAGS },
      ]
    : [];
  const visibleColumns = useMemo(() => {
    const columns = ruleListPageConfiguration?.columns || [];
    columns.push(ListPageColumnKey.ACTIONS);
    return columns;
  }, [ruleListPageConfiguration?.columns]);

  const onAdd = () => {
    if (dataSourceList.data.length === 0) {
      setNoDataSourcesModalOpen(true);
    } else if (kpiList.data.length === 0) {
      setNoMetricsModalOpen(true);
    } else {
      trackEvent(EVENT.CRETE_MONITOR, {
        ...getDatasourceDetailProps(dataSourceList.data[0]),
        page: PAGE.MONITORS,
      });
      history.push(getURIInstance(URIPath.ADD_MONITOR, { workspaceUuid }));
    }
  };

  return (
    <div className="monitor-list">
      <PageHeader
        iconSize={IconSizes.XLARGE}
        iconName={IconName.ListNumbers}
        title={WorkspaceItemKeyToLabelMapper[SideBarNavigationAction.MONITORS]}
        rightContent={
          canModify && (
            <Button disabled={loading} label="Create Monitor +" onClick={onAdd} />
          )
        }
      />
      <EntityList
        enableAdd={!loading}
        searchOptions={searchOptions}
        columns={columns}
        configurableColumns={configurableColumns}
        rows={tableRows}
        getRowKey={getRowKey}
        loading={loading}
        getFilteredRows={filterRows}
        getRowStats={getRowStats}
        onSelectedRowsChange={(newSelectedRows) =>
          setSelectedRowKeys(newSelectedRows.map(getRowKey))
        }
        tableProps={{ scroll: { x: 1168 } }}
        columnKeyList={visibleColumns}
        onColumnKeyListChange={onColumnKeyListChange}
        columnKeyOptions={columnKeyOptions}
        selectedRows={selectedRows}
        onDelete={canModify && onDeleteBulk}
      />
      {deleteModalOpen && (
        <DeleteMonitorConfirmationDialog
          isOpen={deleteModalOpen}
          setIsOpen={setDeleteModalOpen}
          monitors={deleteModalTargets}
          onDeleteMonitors={onDeleteConfirmed}
        />
      )}
      {channelsModalOpen && (
        <AlertConfigSettingDialog
          modalIsOpen={channelsModalOpen}
          setModalIsOpen={setChannelsModalOpen}
          currentAlertingChannels={channelModalTargetChannels}
          alertingChannelList={integrationList}
          okClicked={onChannelsUpdated}
          monitor={channelModalTarget?.[0]}
        />
      )}
      {noDataSourcesModalOpen && (
        <ConfirmationDialog
          modalIsOpen={noDataSourcesModalOpen}
          setModalIsOpen={setNoDataSourcesModalOpen}
          title="No datasources"
          confirmationMsg="You do not have any datasources. Create a datasource in order to be able to create a monitor."
          okText={canAddDataSource ? "Add a datasource" : "OK"}
          cancelText="Close"
          showCancelButton={canAddDataSource}
          okClicked={() =>
            canAddDataSource &&
            history.push(getURIInstance(URIPath.ADD_DATA_SOURCE, { workspaceUuid }))
          }
        />
      )}
      {noMetricsModalOpen && (
        <ConfirmationDialog
          modalIsOpen={noMetricsModalOpen}
          setModalIsOpen={setNoMetricsModalOpen}
          title="No metrics"
          confirmationMsg="You have not defined any metrics. Create a metric in order to be able to create a monitor."
          okText={canAddMetric ? "Add a metric" : "OK"}
          cancelText="Close"
          showCancelButton={canAddMetric}
          okClicked={() =>
            canAddMetric &&
            history.push(getURIInstance(URIPath.ADD_METRIC, { workspaceUuid }))
          }
        />
      )}
    </div>
  );
}

export default withRouter(MonitorList);
