import React from "react";
import PropTypes from "prop-types";
import "./rule-basic-config-tab.scss";
import NgMultiSelect from "../../../multi-select/ng-multi-select";

export function RuleBasicConfigInfoHeader({ icon, text }) {
  return (
    <div className="rule-basic-config-info-header">
      {icon}
      {text}
    </div>
  );
}
const AlertConfigSection = ({
  icon,
  text,
  value,
  placeholder,
  options,
  disabled,
  onChange,
  filterOption = true,
}) => {
  return (
    <div className="rule-alert-channel-container">
      <RuleBasicConfigInfoHeader icon={icon} text={text} />
      <NgMultiSelect
        size="large"
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        filterOption={filterOption}
        options={options}
        onChange={onChange}
        mode="multiple"
        maxTagCount="responsive"
      />
    </div>
  );
};

AlertConfigSection.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

AlertConfigSection.defaultProps = {
  placeholder: "Select",
  disabled: false,
  filterOption: true,
};

export default AlertConfigSection;
