import { Tag as AntdTag } from "antd";
import PropTypes from "prop-types";
import BodySmall from "../../elements/Typography/BodySmall";
import { classesName } from "../../utils/css";
import Icon, { IconName } from "../../elements/Icon";
import { TagStatus } from "./enums";
import { TypographyColors } from "../../elements/Typography/enum";
import "./tags.scss";
export default function Tag(props) {
  const { testId = null, children, className, status, ...otherProps } = props;
  const statusClassName = getStatusClassName(status);
  const statusColor = getStatusColor(status);

  return (
    <AntdTag
      data-testid={testId}
      closeIcon={<Icon name={IconName.XCircle} size={16} />}
      className={classesName("energy-tag", statusClassName, className)}
      {...otherProps}
    >
      <BodySmall color={statusColor}>{children}</BodySmall>
    </AntdTag>
  );
}
function getStatusClassName(status) {
  switch (status) {
    case TagStatus.INFO:
      return "tag-info";
    case TagStatus.ERROR:
      return "tag-error";
    case TagStatus.SUCCESS:
      return "tag-success";
    case TagStatus.WARNING:
      return "tag-warning";
    default:
      return "";
  }
}

function getStatusColor(status) {
  switch (status) {
    case TagStatus.INFO:
      return TypographyColors.INFO;
    case TagStatus.ERROR:
      return TypographyColors.ERROR;
    case TagStatus.SUCCESS:
      return TypographyColors.SUCCESS;
    case TagStatus.WARNING:
      return TypographyColors.WARNING;
    default:
      return TypographyColors.DEFAULT;
  }
}
Tag.propTypes = {
  status: PropTypes.oneOf(Object.values(TagStatus)),
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Tag.defaultProps = {
  status: TagStatus.NEUTRAL,
  className: "",
};
