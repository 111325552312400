import React from "react";
import { Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import Popover from "../popover";
import { selectFilterSort } from "../select";

import "./ng-multi-select.scss";

export function MultiSelectTag(props) {
  const { label, onClose, closable } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="ng-multi-select-tag" onMouseDown={onPreventMouseDown}>
      <div className="ng-multi-select-tag-inner">
        {closable && <CloseCircleOutlined onClick={onClose} />}
        <span title={label}>{label}</span>
      </div>
    </div>
  );
}

function NgMultiSelect(props) {
  const { enableSorting, mode = "tags", ...restProps } = props;
  const filterSortProps = enableSorting
    ? {
        filterSort: selectFilterSort,
      }
    : {};

  return (
    <div className="ng-multi-select">
      <Select
        showSearch
        mode={mode}
        allowClear={true}
        tagRender={MultiSelectTag}
        maxTagPlaceholder={(omittedVals) => {
          const numOmitted = omittedVals.length;
          const placeholder = `+ ${numOmitted}`;
          const popoverContent = (
            <div className="ng-multi-select-max-tag-placeholder-popover">
              {omittedVals.map((omittedVal) => {
                return <div key={omittedVal.label}>{omittedVal.label}</div>;
              })}
            </div>
          );
          return (
            <Popover
              content={popoverContent}
              trigger="hover"
              placement="bottomRight"
              overlayClassName="ng-multi-select-max-tag-placeholder-popover"
            >
              {placeholder}
            </Popover>
          );
        }}
        {...restProps}
        {...filterSortProps}
      />
    </div>
  );
}

export default NgMultiSelect;
