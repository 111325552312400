import React from "react";
import PropTypes from "prop-types";
import { Drawer as AntDrawer } from "antd";

import "./drawer.scss";

const ENERGY_DRAWER_WIDTH = 360;

const Drawer = ({
  visible,
  title,
  onClose,
  placement = "right",
  children,
  footer,
  testId,
  closable = true,
}) => {
  return (
    <AntDrawer
      className="energy-drawer"
      title={title}
      placement={placement}
      closable={closable}
      onClose={onClose}
      visible={visible}
      width={ENERGY_DRAWER_WIDTH}
      data-testid={testId}
      footer={footer}
    >
      {children}
    </AntDrawer>
  );
};

Drawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  testId: PropTypes.string,
  closable: PropTypes.bool,
};

export default Drawer;
