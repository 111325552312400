import React from "react";
import ToggleCheckbox from "../../components/toggle-checkbox/ng-toggle-checkbox";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../components/modal-dialog/modal-field-group";

import "./integration-toggle-group-item.scss";

function IntegrationToggleGroupItem(props) {
  const { value, onChange, label, infoPanel, isReversed = false, testId } = props;

  return (
    <ModalFieldGroup>
      <ModalFieldRows>
        <div className="integration-toggle-group-container">
          <ToggleCheckbox
            value={isReversed ? !value : value}
            onChange={() => {
              onChange(!value);
            }}
            label={label}
            labelPosition={"left"}
            testId={testId}
          />
          {Boolean(infoPanel) && (
            <div className="integration-toggle-info-panel">{infoPanel}</div>
          )}
        </div>
      </ModalFieldRows>
    </ModalFieldGroup>
  );
}

export default IntegrationToggleGroupItem;
