import React from "react";
import { Redirect, Route, useParams, withRouter } from "react-router";
import PrivateRoute from "../../components/private-route/private-route";
import WorkspaceHeader from "../../components/workspace-header/workspace-header";
import DataSourceList from "../datasources/";
import ScheduleList from "../schedules/";
import IntegrationList from "../integrations";
import QueryGovernance from "../settings/query-governance";
import Dashboard from "../dashboard";
import Downloads from "../downloads";
import NotificationList from "../notifications/";
import Notification from "../notification/";
import Profiler from "../profiler";
import MetricsList from "../metrics";
import MonitorList from "../monitors";
import IncidentView from "../incident";
import IncidentList from "../incidents";
import DraftList from "../drafts";
import {
  getURIInstance,
  URIPath,
  URIPathPermissions,
  wsUuidParamName,
} from "../../utils/uri-path";
import DismissableExpirationBanner from "../../components/trial/expiration-banner";
import { SupportedFeature } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/general";
import { isTrialUser } from "../../utils/trial";

import "./workspace.scss";
import { SideBarNavigationAction } from "../../components/sidebar/util";

function WorkspacePanel(props) {
  const params = useParams();
  return (
    <div className="workspace-panel-container">
      {isFeatureEnabled(props.userInfo.waffle, SupportedFeature.ENABLE_TRIAL_MODE) &&
        isTrialUser(props.userInfo) && (
          <DismissableExpirationBanner
            expirationTimestamp={props.userInfo.expirationTimestamp}
          />
        )}
      <div className="workspace-content-panel-container">
        <div className="workspace-content-panel-inner-container">
          <WorkspaceHeader
            hiddenRoutes={[
              SideBarNavigationAction.METRICS,
              SideBarNavigationAction.MONITORS,
              SideBarNavigationAction.DATA_SOURCE,
              SideBarNavigationAction.INTEGRATION,
            ]}
          />
          <div className="workspace-content-panel-inner-content-container">
            <PrivateRoute
              path={URIPath.INCIDENT}
              component={IncidentView}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.INCIDENT]}
            />
            <Route exact path={URIPath.SETTINGS}>
              <Redirect
                to={getURIInstance(URIPath.DATA_SOURCE_LIST, {
                  [wsUuidParamName]: params.workspaceUuid,
                })}
              />
            </Route>
            <PrivateRoute
              path={URIPath.NG_METRIC_LIST}
              component={MetricsList}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.NG_METRIC_LIST]}
            />
            <PrivateRoute
              path={URIPath.NG_DRAFT_LIST}
              component={DraftList}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.NG_DRAFT_LIST]}
            />
            <PrivateRoute
              path={URIPath.METRIC_LIST}
              component={() => (
                <Redirect
                  to={getURIInstance(URIPath.NG_METRIC_LIST, {
                    workspaceUuid: params.workspaceUuid,
                  })}
                />
              )}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.METRIC_LIST]}
            />
            <PrivateRoute
              path={URIPath.INTEGRATION_LIST}
              component={IntegrationList}
              requiredWorkspacePermissions={
                URIPathPermissions[URIPath.INTEGRATION_LIST]
              }
            />
            <PrivateRoute
              path={URIPath.SCHEDULE_LIST}
              component={ScheduleList}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.SCHEDULE_LIST]}
            />
            <PrivateRoute
              path={URIPath.QUERY_GOVERNANCE}
              component={QueryGovernance}
              requiredWorkspacePermissions={
                URIPathPermissions[URIPath.QUERY_GOVERNANCE]
              }
            />
            <PrivateRoute
              exact
              path={URIPath.DATA_SOURCE_LIST}
              component={DataSourceList}
              requiredWorkspacePermissions={
                URIPathPermissions[URIPath.DATA_SOURCE_LIST]
              }
            />
            <PrivateRoute
              exact
              path={URIPath.ALERT_LIST}
              component={NotificationList}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.ALERT_LIST]}
            />
            <PrivateRoute
              path={URIPath.ALERT_ITEM}
              component={Notification}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.ALERT_ITEM]}
            />
            <PrivateRoute
              exact
              path={URIPath.DASHBOARD}
              component={Dashboard}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.DASHBOARD]}
            />
            <PrivateRoute
              exact
              path={URIPath.INCIDENT_LIST}
              component={() => (
                <Redirect
                  to={getURIInstance(URIPath.NG_INCIDENT_LIST, {
                    workspaceUuid: params.workspaceUuid,
                  })}
                />
              )}
            />
            <PrivateRoute
              exact
              path={URIPath.NG_INCIDENT_LIST}
              component={IncidentList}
            />
            <PrivateRoute
              path={URIPath.MONITOR_LIST}
              component={() => (
                <Redirect
                  to={getURIInstance(URIPath.NG_MONITOR_LIST, {
                    workspaceUuid: params.workspaceUuid,
                  })}
                />
              )}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.MONITOR_LIST]}
            />
            <PrivateRoute
              path={URIPath.NG_MONITOR_LIST}
              component={MonitorList}
              requiredWorkspacePermissions={URIPathPermissions[URIPath.NG_MONITOR_LIST]}
            />
            <PrivateRoute exact path={URIPath.EXPLORER} component={Profiler} />
            <PrivateRoute path={URIPath.DOWNLOADS} component={Downloads} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(WorkspacePanel);
