import {
  AggregationWindowType,
  AutoMetricsType,
  MetricCategory,
  MetricConfigType,
  MetricStatus,
  MetricType,
  QueryScope,
  TableType,
} from "./enums";
import {
  getMetricQueryScopeFromMetricData,
  getMetricTypeFromConfigData,
} from "../components/metric/utils";
import { KPI_CATEGORY_KEY_NAME, KPI_PERCENTILE_KEY_NAME } from "./constants";
import { countBy } from "./iterables";
import {
  isTriggeredCollectionMode,
  isCustomScheduledCollectMode,
  getKPIProfilerConfigInfo,
} from "./general";
import {
  DAY_IN_SECONDS,
  HOUR_IN_SECONDS,
  LEAP_YEAR_IN_SECONDS,
  WEEK_IN_SECONDS,
} from "./time";
import { getOptionByValue, queryScopeOptions } from "./options";

export function metricDataSourceUuids(metric) {
  const dataSourceUuids = new Set();
  dataSourceUuids.add(metric.config.sources[0]);
  if (metric.config.sourceTable) {
    dataSourceUuids.add(metric.config.sourceTable.sourceUuid);
  }
  if (metric.config.targetTable) {
    dataSourceUuids.add(metric.config.targetTable.sourceUuid);
  }
  return [...dataSourceUuids];
}

export function getSliceByColumns(metric, options = { checkRowByRow: false }) {
  let sliceByColumns = metric?.config?.sliceByColumns || [];
  const { checkRowByRow = false } = options;
  if (sliceByColumns.length === 0 && checkRowByRow) {
    sliceByColumns = metric?.config?.targetTable?.sliceByColumns || [];
  }

  return sliceByColumns.filter(
    (currentSliceColumn) =>
      ![KPI_CATEGORY_KEY_NAME, KPI_PERCENTILE_KEY_NAME].includes(currentSliceColumn)
  );
}

export function isSlicedMetric(metric) {
  const sliceByColumns = getSliceByColumns(metric);
  if (sliceByColumns.length > 0) {
    return true;
  }

  return metric?.config?.sourceTable?.sliceByColumns?.length > 0;
}

export function getMetricColumnInfo(metric) {
  let columnName = "";
  let columnUuid = null;
  if (metric?.config?.table?.type === TableType.CUSTOM_SQL) {
    columnName = metric.config.table.columnName;
    columnUuid = metric.config.table.columnUuid;
  } else if (metric?.config?.valueColumns?.length === 1) {
    columnName = metric.config.valueColumns[0].columnName;
    columnUuid = metric.config.valueColumns[0].columnUuid;
  }
  return { columnName, columnUuid };
}

export function isAutoCreationTypeMetric(metric) {
  return metric?.metadata?.creationType === MetricType.AUTO;
}

export function isAutoMetric(metric) {
  return (
    isAutoCreationTypeMetric(metric) &&
    [
      AutoMetricsType.DATA_DELAY,
      AutoMetricsType.VOLUME,
      AutoMetricsType.MISSING_VALUE,
      AutoMetricsType.NUMERICAL_DISTRIBUTION,
      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
    ].includes(metric?.config?.aggregation?.type)
  );
}

export const tableAutoMetricTypes = new Set([
  MetricCategory.DATA_DELAY,
  MetricCategory.DATA_VOLUME,
]);

export const metadataMetricTypes = new Set([
  MetricCategory.BYTE_COUNT,
  MetricCategory.ROW_COUNT,
  MetricCategory.UPDATE_DELAY,
]);

export function isMetadataMetric(metric) {
  return metric && metadataMetricTypes.has(getMetricTypeFromConfigData(metric));
}
export function isIncrementalMetric(metric) {
  return metric?.config?.configType === MetricConfigType.METRIC_CONFIG;
}

export const activityMetricTypes = new Set([
  MetricCategory.TABLE_ACTIVITY,
  MetricCategory.COLUMN_ACTIVITY,
  MetricCategory.CATEGORY_ACTIVITY,
]);

export function isActivityMetric(metric) {
  return metric && activityMetricTypes.has(getMetricTypeFromConfigData(metric));
}

export const tableOrColumnActivityMetricTypes = new Set([
  MetricCategory.TABLE_ACTIVITY,
  MetricCategory.COLUMN_ACTIVITY,
]);

export function isTableOrColumnActivityMetric(metric) {
  return (
    metric && tableOrColumnActivityMetricTypes.has(getMetricTypeFromConfigData(metric))
  );
}

export function getMetricQueryScopeLabel(metric) {
  let queryScope;

  if (isMetadataMetric(metric) || isTableOrColumnActivityMetric(metric)) {
    queryScope = "Metadata";
  } else {
    queryScope =
      getOptionByValue(queryScopeOptions, getMetricQueryScopeFromMetricData(metric))
        ?.label ?? "";
  }

  return queryScope;
}

export function getMetricStatus(metric) {
  if (!metric) {
    return null;
  } else if (!metric?.config.isLive) {
    // The system may be paused by the user AND paused by the system, but we prioritize showing the
    // paused by user status.
    return MetricStatus.PAUSED_BY_USER;
  } else {
    return metric?.status?.runStatus;
  }
}

const metricStatusToSearchOption = {
  [MetricStatus.EXCEPTION]: "Live",
  [MetricStatus.OK]: "Live",
  [MetricStatus.TIMEOUT]: "Live",
  [MetricStatus.PAUSED_EXCEPTION]: "Error",
  [MetricStatus.PAUSED_BY_USER]: "Paused by user",
  [MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES]: "Paused by excess unique slices",
  [MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES]: "Paused by excess categories",
  [MetricStatus.PAUSED_SOURCE]: "Paused by source or schedule",
};

const metricStatusPausedOptions = [
  MetricStatus.PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED,
  MetricStatus.PAUSED_TIMEOUTS,
  MetricStatus.PAUSED_BY_USER,
  MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES,
  MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES,
  MetricStatus.PAUSED_SOURCE,
];

export function getMetricStatusSearchOptions(metric) {
  const options = [];
  const metricStatus = getMetricStatus(metric);
  const searchOption = metricStatusToSearchOption[metricStatus];
  if (searchOption) {
    options.push(searchOption);
  }
  if (metricStatusPausedOptions.includes(metricStatus)) {
    options.push("Paused");
  }
  return options;
}

export function getQueryScopeLabel(metric) {
  let queryScope;
  if (isMetadataMetric(metric) || isTableOrColumnActivityMetric(metric)) {
    queryScope = "Metadata";
  } else {
    queryScope =
      getOptionByValue(queryScopeOptions, getMetricQueryScopeFromMetricData(metric))
        ?.label ?? "";
  }
  return queryScope;
}

// There is a parallel backend implementation of this logic - see `is_stale`
// on the Metric model. The two implementations need to remain in sync.
export function isMetricStale(metric) {
  if (!metric?.status) {
    return false;
  }
  if (!metric.status?.desiredRunConfigVersion) {
    return false;
  }
  if (!metric.status?.actualRunConfigVersion) {
    return true;
  }
  return metric.status.actualRunConfigVersion < metric.status.desiredRunConfigVersion;
}

export function isMetricPausedBySystem(metric) {
  return [
    MetricStatus.PAUSED_TIMEOUTS,
    MetricStatus.PAUSED_EXCEPTION,
    MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES,
    MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES,
    MetricStatus.PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED,
  ].includes(getMetricStatus(metric));
}

export function isMetricPaused(metric) {
  return [
    MetricStatus.PAUSED_BY_USER,
    MetricStatus.PAUSED_EXCEPTION,
    MetricStatus.PAUSED_EXCESS_UNIQUE_CATEGORIES,
    MetricStatus.PAUSED_EXCESS_UNIQUE_SLICES,
    MetricStatus.PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED,
    MetricStatus.PAUSED_SOURCE,
    MetricStatus.PAUSED_TIMEOUTS,
  ].includes(getMetricStatus(metric));
}

export function isMetricResumable(metric) {
  if (isActivityMetric(metric)) {
    return false;
  }

  return !metric?.config.isLive || isMetricPausedBySystem(metric);
}

export function isMetricPausable(metric) {
  if (isActivityMetric(metric)) {
    return false;
  }

  return !isMetricResumable(metric);
}

export function isFullTableMetric(metric) {
  return metric?.config?.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG;
}

// Need to be in sync with https://github.com/lightup-data/lumen/blob/master/lux/lux/v1_models/models/metric.py#L2012
export function metricCanBeBackfilled(metric) {
  const metricType = getMetricTypeFromConfigData(metric);
  if (isFullTableMetric(metric) || metricType === MetricCategory.FULL_COMPARE) {
    return false;
  }
  return (
    metricType === MetricCategory.AGGREGATION_COMPARE ||
    metricType !== MetricCategory.DATA_DELAY
  );
}

export function isMetricBackfilling(metric) {
  if (!metric || !metric.status || !metricCanBeBackfilled(metric)) {
    return false;
  }
  const metricStatus = getMetricStatus(metric);
  return (
    metricStatus === MetricStatus.OK &&
    (isMetricStale(metric) || !metric.status?.lastScannedTs)
  );
}

export function isTriggeredMetric(metric) {
  return isTriggeredCollectionMode(metric?.config?.collectionMode);
}

export function isMetricWaitingForFirstTrigger(metric) {
  return isTriggeredMetric(metric) && !metric?.status?.triggered?.triggerRunStatus;
}

export function isMetricWaitingForFirstCollection(metric) {
  return (
    metric?.config?.isLive &&
    !isMetricPausedBySystem(metric) &&
    !isMetricBackfilling(metric) &&
    !isMetricWaitingForFirstTrigger(metric) &&
    (!Boolean(metric?.status?.lastScannedTs) || isMetricStale(metric))
  );
}

export function getMetricConfigTypeFromTableQueryScope(queryScope) {
  return queryScope === QueryScope.TIME_RANGE
    ? MetricConfigType.METRIC_CONFIG
    : MetricConfigType.FULL_TABLE_METRIC_CONFIG;
}

export function monitorCountByMetricUuid(monitors) {
  const monitorMetrics = function* () {
    for (let monitor of monitors) {
      for (let monitorMetric of monitor.config.metrics) {
        yield monitorMetric;
      }
    }
  };

  return countBy(
    monitorMetrics(),
    (metricUuid) => metricUuid,
    (_) => 1
  );
}

// Need to be in sync with https://github.com/lightup-data/lumen/blob/aed9b6e4635d84823f2933f0fb931de88129e5c4/lux/lux/v1_models/models/metric.py#L2037
export function isIncidentValidationSupported(metricType, metricInfo = null) {
  if (
    [
      AutoMetricsType.DATA_DELAY,
      AutoMetricsType.AGGREGATION_COMPARE,
      AutoMetricsType.FULL_COMPARE,
      AutoMetricsType.TABLE_ACTIVITY,
      AutoMetricsType.COLUMN_ACTIVITY,
      AutoMetricsType.CATEGORY_ACTIVITY,
    ].includes(metricType)
  ) {
    return false;
  }

  if (metricInfo?.config?.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG) {
    return false;
  }

  return true;
}

export function getAutoMetricTypeFromKPI(kpi) {
  if (kpi?.config?.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG) {
    return AutoMetricsType.FULL_COMPARE;
  }

  if (kpi?.config?.configType === MetricConfigType.AGGREGATION_COMPARE_METRIC_CONFIG) {
    return AutoMetricsType.AGGREGATION_COMPARE;
  }

  // Aggregation based metric, for any system known aggregation type, return its original value.
  if (
    [
      AutoMetricsType.DATA_DELAY,
      AutoMetricsType.VOLUME,
      AutoMetricsType.MISSING_VALUE,
      AutoMetricsType.CONFORMITY_COUNT,
      AutoMetricsType.NUMERICAL_DISTRIBUTION,
      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
      AutoMetricsType.BYTE_COUNT,
      AutoMetricsType.ROW_COUNT,
      AutoMetricsType.UPDATE_DELAY,
      AutoMetricsType.TABLE_ACTIVITY,
      AutoMetricsType.COLUMN_ACTIVITY,
      AutoMetricsType.CATEGORY_ACTIVITY,
    ].includes(kpi?.config?.aggregation?.type)
  ) {
    return kpi.config.aggregation.type;
  }

  // For Custom SQL or aggregation like count, use general type instead.
  return AutoMetricsType.NONE;
}

export function getKPIDisplayName(kpi, dataSource) {
  if (!kpi) {
    console.log("Kpi is expected.");
    return "";
  }

  const {
    metadata: { creationType, name },
    config: { aggregation },
  } = kpi;
  if (
    creationType === MetricType.CUSTOM &&
    (!aggregation || aggregation.type !== AutoMetricsType.CONFORMITY_COUNT)
  ) {
    return name;
  }

  if (!dataSource) {
    console.log("Datasource is expected.");
    return name;
  }

  const { dataSourceName, fullTableName, columnName } = getKPIProfilerConfigInfo(
    kpi,
    dataSource
  );

  const validValueArray = [name];
  dataSourceName && validValueArray.push(dataSourceName);
  fullTableName && validValueArray.push(fullTableName);
  columnName && validValueArray.push(columnName);

  return validValueArray.join("_");
}

export function isTrendMonitorDisabled(metric) {
  if (
    [AutoMetricsType.DATA_DELAY, AutoMetricsType.UPDATE_DELAY].includes(
      getAutoMetricTypeFromKPI(metric)
    )
  ) {
    return true;
  }

  if (
    metric.config.configType === MetricConfigType.FULL_TABLE_METRIC_CONFIG &&
    (isTriggeredCollectionMode(metric.config.collectionMode) ||
      isCustomScheduledCollectMode(metric.config.collectionMode))
  ) {
    return true;
  }

  if (
    metric.config.configType === MetricConfigType.FULL_COMPARE_METRIC_CONFIG &&
    metric.config.queryScope === QueryScope.FULL_TABLE &&
    isTriggeredCollectionMode(metric.config.collectionMode)
  ) {
    return true;
  }

  return false;
}

export function getMetricAggregationDisplayName(metricAggregationInterval) {
  if (metricAggregationInterval === AggregationWindowType.HOUR) {
    return "Hourly";
  }

  if (metricAggregationInterval === AggregationWindowType.DAY) {
    return "Daily";
  }

  if (metricAggregationInterval === AggregationWindowType.WEEK) {
    return "Weekly";
  }

  return "";
}

// Must be kept in sync with:
// https://github.com/lightup-data/lumen/commit/2e008d654d92b265b81ec5c889465ab6313711c2#diff-0d3455881d4c30c48a3921ad0c7258ea84d1e0e7b7a47857c1a97ac38dcf0264R43
const backfillDurationDefaults = {
  [AggregationWindowType.SECOND]: 2 * HOUR_IN_SECONDS,
  [AggregationWindowType.MINUTE]: WEEK_IN_SECONDS,
  [AggregationWindowType.FIVE_MIN]: WEEK_IN_SECONDS,
  [AggregationWindowType.TEN_MIN]: WEEK_IN_SECONDS,
  [AggregationWindowType.FIFTEEN_MIN]: WEEK_IN_SECONDS,
  [AggregationWindowType.HOUR]: 90 * DAY_IN_SECONDS,
  [AggregationWindowType.DAY]: 90 * DAY_IN_SECONDS,
  [AggregationWindowType.WEEK]: 13 * WEEK_IN_SECONDS,
  [AggregationWindowType.MONTH]: 13 * 30 * DAY_IN_SECONDS,
  [AggregationWindowType.QUARTER]: 15 * 30 * DAY_IN_SECONDS,
  [AggregationWindowType.YEAR]: 2 * LEAP_YEAR_IN_SECONDS,
};

export function getDefaultBackfillDuration(aggregationWindow, dataSource) {
  if (!aggregationWindow || !dataSource) {
    return 0;
  }

  if (!aggregationWindow || !backfillDurationDefaults[aggregationWindow]) {
    console.log(`Unknown aggregation window ${aggregationWindow}`);
    return 90 * DAY_IN_SECONDS;
  }

  if (
    typeof dataSource?.config?.governance?.maxBackfillDurationInSeconds !== "number" ||
    dataSource?.config?.governance?.maxBackfillDurationInSeconds === 0
  ) {
    return backfillDurationDefaults[aggregationWindow];
  }

  return Math.min(
    backfillDurationDefaults[aggregationWindow],
    dataSource.config.governance.maxBackfillDurationInSeconds
  );
}

export function metricTypeSupportsQueryHistory(metricType) {
  return ![
    MetricCategory.AGGREGATION_COMPARE,
    MetricCategory.TABLE_ACTIVITY,
    MetricCategory.COLUMN_ACTIVITY,
  ].includes(metricType);
}

export function metricCreationTypeLabel(metric) {
  if (isMetadataMetric(metric)) {
    return "Metadata";
  } else if (isAutoMetric(metric)) {
    return "Auto";
  } else {
    return "Metric";
  }
}
