import React, { useEffect, useState } from "react";
import EntityList from "../../components/entity-list/entity-list";
import { hasPermission } from "../../utils/uri-path";
import ScheduleActionsMenu from "./schedule-actions-menu";
import AddScheduleDialog from "./add-schedule-dialog";
import EditScheduleDialog from "./edit-schedule-dialog";
import ProfilerConfirmationDialog from "../profiler/profiler-confirmation-dialog";
import { AppPermissions } from "../../utils/permissions";
import { ListPageColumnKey, ScheduleType } from "../../utils/enums";
import { fnSorter } from "../../utils/sort";
import EntityListPage from "../../components/entity-list/entity-list-page";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";
import BodySmall from "../../elements/Typography/BodySmall";
import Icon, { IconName } from "../../elements/Icon";
import "./schedule-list.scss";
const typeToIconInfoMapper = {
  [ScheduleType.RECURRING]: {
    icon: <Icon name={IconName.Repeat} />,
    tooltipInfo: "Recurring Schedule",
  },
  [ScheduleType.STATIC]: {
    icon: <Icon name={IconName.RepeatOnce} />,
    tooltipInfo: "Custom Schedule",
  },
};

function ScheduleList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    workspaceUserPermissions,
    scheduleList,
    getScheduleList,
    addSchedule,
    editSchedule,
    deleteSchedule,
    history,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.SCHEDULE,
    schedules: scheduleList.data,
  });

  useEffect(() => {
    getScheduleList(workspaceUuid);
  }, [workspaceUuid, getScheduleList]);

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [contextMenuItem, setContextMenuItem] = useState(null);
  function onAddNewSchedule(newSchedule) {
    addSchedule(workspaceUuid, newSchedule);
  }

  function onEditSchedule(id, currentSchedule) {
    editSchedule(workspaceUuid, id, currentSchedule);
  }

  function onDeleteSchedule(currentSchedule) {
    deleteSchedule(workspaceUuid, currentSchedule);
  }

  const loading = scheduleList.loading;
  const tableRows = scheduleList.data;

  const canAddSchedule = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SCHEDULE_VIEWS_EDIT_SCHEDULELIST,
  ]);

  const canModifySchedule = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SCHEDULE_VIEWS_EDIT_SCHEDULEDETAIL,
  ]);

  const scheduleActionsSharedMenuProps = {
    workspaceUuid,
    workspaceUserPermissions,
    history: history,
    onDeleteScheduleClick: (workspaceUuid, schedule) => {
      setContextMenuItem(schedule);
      setIsDeleteOpen(true);
    },
    onEditScheduleClick: (workspaceUuid, schedule) => {
      setContextMenuItem(schedule);
      setIsEditOpen(true);
    },
    loading: false,
  };

  const columns = [
    {
      title: "TYPE",
      key: "scheduleType",
      dataIndex: "scheduleType",
      sorter: { compare: fnSorter((row) => row.scheduleType) },
      width: 170,
      render: function (scheduleType) {
        const iconInfo = typeToIconInfoMapper[scheduleType];
        if (!iconInfo) {
          return null;
        }

        const { icon: IconComponent, tooltipInfo } = iconInfo;
        return (
          <div className="schedule-type">
            {IconComponent}
            {tooltipInfo}
          </div>
        );
      },
    },
    {
      title: "TITLE",
      key: "title",
      dataIndex: "title",
      sorter: { compare: fnSorter((row) => row.title.toLowerCase()) },
      width: 200,
      render: (title, scheduleData) => {
        return <BodySmall>{title}</BodySmall>;
      },
    },
    {
      title: "DESCRIPTION",
      key: "description",
      dataIndex: "description",
      sorter: { compare: fnSorter((row) => row.description) },
    },
    {
      title: "",
      key: ListPageColumnKey.ACTIONS,
      width: 50,
      render: (_, scheduleData) => (
        <ScheduleActionsMenu
          trigger={null}
          triggerClassName="actions-trigger"
          schedules={[scheduleData]}
          {...scheduleActionsSharedMenuProps}
        />
      ),
    },
  ];

  return (
    <EntityListPage>
      <EntityList
        addText="Create schedule"
        showAdd={canAddSchedule}
        searchOptions={searchOptions}
        columns={columns}
        rows={tableRows}
        getRowKey={(schedule) => schedule.id}
        loading={loading}
        getFilteredRows={filterRows}
        onAdd={() => setIsAddOpen(true)}
        testId="entity-list-schedule"
      />
      {isAddOpen && (
        <AddScheduleDialog
          onAdd={onAddNewSchedule}
          modalIsOpen={isAddOpen}
          setIsOpen={setIsAddOpen}
        />
      )}
      {isEditOpen && contextMenuItem && (
        <EditScheduleDialog
          data={contextMenuItem}
          enableSave={canModifySchedule}
          onEdit={onEditSchedule.bind(null, contextMenuItem.id)}
          modalIsOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        />
      )}
      {isDeleteOpen && contextMenuItem && (
        <ProfilerConfirmationDialog
          modalIsOpen={isDeleteOpen}
          setIsOpen={setIsDeleteOpen}
          okClicked={onDeleteSchedule.bind(null, contextMenuItem)}
          usage={{
            loading: false,
            data: {},
          }}
          title="Delete schedule"
          defaultConfirmationMsg={`You are about to delete ${contextMenuItem.title}. This is not reversible.`}
        />
      )}
    </EntityListPage>
  );
}

export default ScheduleList;
