import React, { Component } from "react";
import GroupPanelItem from "../../../group-panel-item/group-panel-item";
import LabelItem from "../../../label-item/label-item";
import { TriggerButton } from "../../../button/ng-button";
import NgToggleCheckbox from "../../../toggle-checkbox/ng-toggle-checkbox";
import StepContainer from "../../../step/step";
import TimeOption from "../../../time-option/time-option";
import BoundSettingView from "../../../bound-setting-view/";
import { LabeledSelect } from "../../../labeled-control/labeled-control";
import RuleTrainingAdvancedSettingDialog from "./rule-training-advanced-setting-dialog";
import RuleTrainingDetectionDialog from "./rule-training-detection-setting-dialog";
import RuleTrainingSliceInfoSummaryDialog from "./rule-training-slice-info-summary-dialog";
import RuleTrainingPeriodDialog from "./rule-training-period-dialog";
import RuleConfigDetectionSettingDisplayView from "./rule-config-detection-setting-display-view";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getRuleRuntimeConfigItemFromObject,
  getSliceDisplayString,
  isSliceEqual,
} from "../../../../utils/general";
import {
  DAY_IN_SECONDS,
  getDisplayTimeFromSecond,
  getSecondFromDisplayTime,
  getStringFromTimeStamp,
  MINUTE_IN_SECONDS,
  WEEK_IN_SECONDS,
} from "../../../../utils/time";
import {
  AutoMetricsType,
  FeatureTypeConst,
  SliceTrainingStatus,
  SymptomTypeConst,
  featureTypeDisplayNameMap,
  getTypesAndFeaturesBySymptomType,
} from "../../../../utils/enums";
import {
  getDefaultTitle,
  getDefaultTrainingPeriod,
  getKPIGranularity,
} from "../../../../utils/defaults";
import {
  getAutoMetricTypeFromKPI,
  isTrendMonitorDisabled,
  isActivityMetric as getIsActivityMetric,
} from "../../../../utils/metric";
import { isChangeFeatureType, isTrainingRequired } from "../../../../utils/monitor";
import { NextGenPalette } from "../../../../utils/color";
import NgDropdownMenu from "../../../ng-dropdown-menu";
import { enabledThresholdFeatureOptions } from "../../../auto-rule-creation-dialog/utils";
import {
  getNewSymptomType,
  getSelectedMetric,
  sanitizeSliceOverrideSymptomConfig,
} from "../../utils";

import "./rule-train-config-tab.scss";
import SliceThresholdConfig from "./rule-train-slice-threshold-config";

const windowSizeSettingOptions = [
  { value: "s", label: "second" },
  { value: "m", label: "minute" },
  { value: "h", label: "hour" },
  { value: "d", label: "day" },
  { value: "w", label: "week" },
  { value: "mo", label: "month" },
  { value: "y", label: "year" },
];

function RuleTrainingSliceInfoSummaryDialogTriggerComponent({ onClick }) {
  return (
    <div className="rule-training-slice-info-summary-dialog-trigger-component-container">
      <TriggerButton onClick={onClick}>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M4 6C4 4.89543 4.89543 4 6 4H26C27.1046 4 28 4.89543 28 6V26C28 27.1046 27.1046 28 26 28H6C4.89543 28 4 27.1046 4 26V6Z"
            fill="white"
          />
          <path
            d="M17.1598 25.0543C16.9829 25.1565 16.7801 25.233 16.5643 25.2845L16.7984 25.6112C16.1995 25.7543 15.5221 25.7543 14.9232 25.6112L15.1573 25.2845C14.9415 25.233 14.7387 25.1565 14.5618 25.0543L13.6957 24.5543L13.2627 24.8043L11.5307 23.8043L11.9637 23.5543L10.2316 22.5543L9.79863 22.8043L8.06658 21.8043L8.49959 21.5543L7.63357 21.0543C7.45663 20.9522 7.32413 20.8351 7.23483 20.7105L6.66898 20.8457C6.42116 20.4998 6.42116 20.1088 6.66898 19.763L7.23483 19.8982C7.32413 19.7735 7.45663 19.6565 7.63357 19.5543L8.41023 19.1059L7.97722 18.8559L9.53055 17.9591L9.96356 18.2091L11.5169 17.3123L11.0839 17.0623L12.6372 16.1655L13.0702 16.4155L13.8469 15.9671C13.9407 15.9129 14.0435 15.8653 14.1532 15.8246L13.8223 15.5271C14.1181 15.4174 14.4515 15.3458 14.7996 15.3171L14.8862 15.6671C15.0152 15.6564 15.1469 15.6536 15.2793 15.6592L15.6648 15.6754L15.7092 15.3228L16.4802 15.3552L16.4358 15.7078L16.8213 15.724C16.9309 15.7286 17.0388 15.7388 17.1439 15.7544L17.2959 15.4119C17.5785 15.4537 17.846 15.5243 18.0856 15.6204L17.7361 15.9108C17.8253 15.9465 17.9093 15.987 17.987 16.0319L18.7496 16.4722L19.1826 16.2222L20.7079 17.1028L20.2749 17.3528L21.8001 18.2334L22.2332 17.9834L23.7584 18.864L23.3254 19.114L24.088 19.5543C24.265 19.6565 24.3975 19.7735 24.4868 19.8982L25.0526 19.763C25.3005 20.1088 25.3005 20.4998 25.0526 20.8457L24.4868 20.7105C24.3975 20.8351 24.265 20.9522 24.0881 21.0543L23.222 21.5543L23.655 21.8043L21.923 22.8043L21.49 22.5543L19.7579 23.5543L20.1909 23.8043L18.4589 24.8043L18.0259 24.5543L17.1598 25.0543Z"
            fill="white"
            stroke={NextGenPalette.systemBlack}
            strokeDasharray="2 2"
          />
          <path
            d="M17.1598 21.4022C16.9829 21.5043 16.7801 21.5808 16.5643 21.6324L16.7984 21.9591C16.1995 22.1021 15.5221 22.1021 14.9232 21.9591L15.1573 21.6324C14.9415 21.5808 14.7387 21.5043 14.5618 21.4022L13.6957 20.9022L13.2627 21.1522L11.5307 20.1522L11.9637 19.9022L10.2316 18.9022L9.79863 19.1522L8.06658 18.1522L8.49959 17.9022L7.63357 17.4022C7.45663 17.3 7.32413 17.1829 7.23483 17.0583L6.66898 17.1935C6.42116 16.8477 6.42116 16.4566 6.66898 16.1108L7.23483 16.246C7.32413 16.1214 7.45663 16.0043 7.63357 15.9022L8.41023 15.4538L7.97722 15.2038L9.53055 14.3069L9.96356 14.5569L11.5169 13.6601L11.0839 13.4101L12.6372 12.5133L13.0702 12.7633L13.8469 12.3149C13.9407 12.2607 14.0435 12.2131 14.1532 12.1724L13.8223 11.8749C14.1181 11.7653 14.4515 11.6936 14.7996 11.6649L14.8862 12.0149C15.0152 12.0043 15.1469 12.0015 15.2793 12.007L15.6648 12.0232L15.7092 11.6706L16.4802 11.703L16.4358 12.0556L16.8213 12.0718C16.9309 12.0764 17.0388 12.0867 17.1439 12.1022L17.2959 11.7597C17.5785 11.8015 17.846 11.8722 18.0856 11.9683L17.7361 12.2586C17.8253 12.2944 17.9093 12.3348 17.987 12.3797L18.7496 12.82L19.1826 12.57L20.7079 13.4506L20.2749 13.7006L21.8001 14.5812L22.2332 14.3312L23.7584 15.2119L23.3254 15.4619L24.088 15.9022C24.265 16.0043 24.3975 16.1214 24.4868 16.246L25.0526 16.1108C25.3005 16.4566 25.3005 16.8477 25.0526 17.1935L24.4868 17.0583C24.3975 17.1829 24.265 17.3 24.0881 17.4022L23.222 17.9022L23.655 18.1522L21.923 19.1522L21.49 18.9022L19.7579 19.9022L20.1909 20.1522L18.4589 21.1522L18.0259 20.9022L17.1598 21.4022Z"
            fill="white"
            stroke={NextGenPalette.systemBlack}
            strokeDasharray="2 2"
          />
          <path
            d="M24.088 12.75L17.1598 16.75C16.4424 17.1642 15.2792 17.1642 14.5617 16.75L7.63354 12.75C6.9161 12.3358 6.9161 11.6642 7.63354 11.25L14.3413 7.37729C14.4891 7.29193 14.6611 7.22176 14.8499 7.16982L14.993 7.13044C15.5258 6.98384 16.1481 6.99395 16.6658 7.15762L17.1735 7.31812C17.3265 7.3665 17.4667 7.42721 17.5901 7.49842L24.088 11.25C24.8055 11.6642 24.8055 12.3358 24.088 12.75Z"
            fill="#868786"
            stroke={NextGenPalette.systemBlack}
          />
        </svg>
      </TriggerButton>
    </div>
  );
}

class RuleTrainConfigTab extends Component {
  constructor(props) {
    super(props);

    this.onBasicConfigChange = this.onBasicConfigChange.bind(this);

    this.onTrainingTimeSettingApply = this.onTrainingTimeSettingApply.bind(this);
    this.onTrainingPeriodContextMenuClick =
      this.onTrainingPeriodContextMenuClick.bind(this);
    this.onAdvancedSettingApply = this.onAdvancedSettingApply.bind(this);
    this.onDetectionSettingApply = this.onDetectionSettingApply.bind(this);
    this.getMonitorName = this.getMonitorName.bind(this);

    this.state = {
      isTrainingPeriodDialogOpen: false,
      isRuleTrainingSliceInfoSummaryDialogOpen: false,
      currentEditTrainingPeriod: {
        startTimestamp: 0,
        endTimestamp: 0,
      },
      currentEditTrainingPeriodIndex: -1,
      currentEditSliceTrainingPeriodIndex: -1,
    };
  }

  onBasicConfigChange(basicConfigItems) {
    const {
      defaultData: { basicConfig },
    } = this.props;

    const newBasicConfig = {
      ...basicConfig,
      ...basicConfigItems,
    };

    this.props.onConfigChange({
      basicConfig: newBasicConfig,
    });
  }

  onAdvancedSettingApply(newSymptom) {
    this.props.defaultData.config.symptom = newSymptom;
    this.props.onConfigChange(this.props.defaultData);
  }

  getAdvancedSettingComponent(lastSampleSize) {
    let {
      defaultData: {
        config: { symptom },
        status,
      },
    } = this.props;

    if (symptom === SymptomTypeConst.MANUAL_THRESHOLD) {
      return null;
    }

    const runTimeSymptom =
      status && status.runtimeConfig && status.runtimeConfig.symptomConfig
        ? status.runtimeConfig.symptomConfig
        : null;

    return (
      <RuleTrainingAdvancedSettingDialog
        symptom={symptom}
        runTimeSymptom={runTimeSymptom}
        onApply={this.onAdvancedSettingApply}
        lastSampleSize={lastSampleSize}
      />
    );
  }

  getSliceTrainingPeriodsFromOverride(overrides, slice) {
    let currentSliceOverride = overrides.find((override) =>
      isSliceEqual(override.metricSlice, slice)
    );
    if (!currentSliceOverride) {
      const sliceOverrideObject = {
        metricSlice: slice,
        symptomConfig: {
          ...this.props.defaultData.config.symptom,
          trainingPeriods: [],
        },
      };
      overrides.push(sliceOverrideObject);
      currentSliceOverride = sliceOverrideObject;
    }

    if (!currentSliceOverride.symptomConfig.trainingPeriods)
      currentSliceOverride.symptomConfig.trainingPeriods = [];
    return currentSliceOverride.symptomConfig.trainingPeriods;
  }

  /**
   * Applies the new training period settings, handling all edge cases.
   * TODO: Fix all the mutation and side effects.
   *
   * @param {Object} params - Contains the new training period.
   * @param {Object} params.trainingPeriod - The new training period to apply.
   */
  onTrainingTimeSettingApply({ trainingPeriod: newTrainingPeriod }) {
    const { slice: newSlice, startTs, endTs } = newTrainingPeriod;
    const {
      currentEditTrainingPeriodIndex,
      currentEditSliceTrainingPeriodIndex,
      currentEditTrainingPeriod,
    } = this.state;

    let { trainingPeriods, sliceOverrides } = this.getTrainingPeriodListData();
    const isSliceSpecific = !!newSlice;

    const trainingPeriodObject = { startTs, endTs };
    const isEditing = currentEditSliceTrainingPeriodIndex !== -1;

    if (isSliceSpecific) {
      const oldSlice = currentEditTrainingPeriod?.slice;

      // Determine if the slice has changed during editing
      const hasSliceChanged =
        isEditing && oldSlice && !isSliceEqual(oldSlice, newSlice);

      if (hasSliceChanged) {
        // Remove the training period from the old slice override
        const oldSliceTrainingPeriods = this.getSliceTrainingPeriodsFromOverride(
          sliceOverrides,
          oldSlice
        );

        oldSliceTrainingPeriods.splice(currentEditSliceTrainingPeriodIndex, 1); // Mutation occurs here

        // Add the training period to the new slice override
        const newSliceTrainingPeriods = this.getSliceTrainingPeriodsFromOverride(
          sliceOverrides,
          newSlice
        );

        newSliceTrainingPeriods.push(trainingPeriodObject); // Mutation occurs here
      } else {
        // Adding a new training period or editing without changing the slice
        const sliceTrainingPeriods = this.getSliceTrainingPeriodsFromOverride(
          sliceOverrides,
          newSlice
        );

        if (currentEditSliceTrainingPeriodIndex === -1) {
          // Adding a new training period
          sliceTrainingPeriods.push(trainingPeriodObject); // Mutation occurs here
        } else {
          // Editing an existing training period without changing the slice
          sliceTrainingPeriods[currentEditSliceTrainingPeriodIndex] =
            trainingPeriodObject; // Mutation occurs here
        }
      }

      const cleanedUpSliceOverrides = sanitizeSliceOverrideSymptomConfig(
        sliceOverrides,
        currentEditTrainingPeriod.slice,
        "trainingPeriods"
      );

      // Update the sliceSymptomConfigs with the mutated sliceOverrides
      this.props.defaultData.config.sliceSymptomConfigs = cleanedUpSliceOverrides.length
        ? cleanedUpSliceOverrides
        : null; // TODO: mutating props need to fix later
    } else {
      // Handle global training periods
      if (currentEditTrainingPeriodIndex === -1) {
        // Adding a new global training period
        trainingPeriods.push(newTrainingPeriod); // Mutation occurs here
      } else {
        // Editing an existing global training period
        trainingPeriods[currentEditTrainingPeriodIndex] = newTrainingPeriod; // Mutation occurs here
      }

      this.props.defaultData.config.symptom.trainingPeriods = trainingPeriods;
    }

    this.props.onConfigChange(this.props.defaultData);
  }

  onTrainingPeriodContextMenuClick(e, { action, data }) {
    const { currentLearningPeriod, index } = data;

    if (action === "Edit") {
      const { isTrainingPeriodDialogOpen } = this.state;
      const updatedState = {
        isTrainingPeriodDialogOpen: !isTrainingPeriodDialogOpen,
        currentEditTrainingPeriod: currentLearningPeriod,
        currentEditTrainingPeriodIndex: index,
      };
      updatedState[
        currentLearningPeriod.slice
          ? "currentEditSliceTrainingPeriodIndex"
          : "currentEditTrainingPeriodIndex"
      ] = index;
      this.setState(updatedState);
    } else if (action === "Delete") {
      console.log(`Delete row ${index} from learning period`);
      const { trainingPeriods, sliceOverrides } = this.getTrainingPeriodListData();
      if (currentLearningPeriod.slice) {
        // deleting a slice override
        const sliceTrainingPeriods = this.getSliceTrainingPeriodsFromOverride(
          sliceOverrides, // TODO: fix later: This function call will mutate sliceOverride
          currentLearningPeriod.slice
        );
        sliceTrainingPeriods.splice(index, 1);

        // Remove override object if training periods array is empty
        const cleanedUpSliceOverrides = sanitizeSliceOverrideSymptomConfig(
          sliceOverrides,
          currentLearningPeriod.slice,
          "trainingPeriods"
        );
        this.props.defaultData.config.sliceSymptomConfigs =
          cleanedUpSliceOverrides.length ? cleanedUpSliceOverrides : null; // TODO: bad approach, need to fix later
      } else {
        const newPeriods = [...trainingPeriods];
        newPeriods.splice(index, 1);
        this.props.defaultData.config.symptom.trainingPeriods = newPeriods;
      }
      this.props.onConfigChange(this.props.defaultData);
    }
  }

  getTrainingPeriodListData() {
    const trainingPeriods = getRuleRuntimeConfigItemFromObject(
      this.props.defaultData,
      "trainingPeriods",
      []
    );
    const sliceOverrides = getRuleRuntimeConfigItemFromObject(
      this.props.defaultData,
      "sliceSymptomConfigs",
      [],
      true // setting isSliceOverride as true to fetch info from symptomConfig instead of symptom
    );

    return { trainingPeriods, sliceOverrides };
  }

  getTrainingPeriodList(learningPeriodArray) {
    if (learningPeriodArray === undefined) {
      return "Automatic";
    }

    const { isTraining } = this.props;

    return (
      <div className="rule-training-period-list-container">
        {learningPeriodArray.map((learningPeriod, index) => {
          const { startTs, endTs } = learningPeriod;
          const displayStartTime = getStringFromTimeStamp(startTs);
          const displayEndTime = getStringFromTimeStamp(endTs);
          const periodStr = `${displayStartTime} to ${displayEndTime}`;

          const menuItems = [
            {
              label: "Edit",
              key: "edit",
              icon: <EditOutlined />,
              onClick: () =>
                this.onTrainingPeriodContextMenuClick(null, {
                  action: "Edit",
                  data: { currentLearningPeriod: learningPeriod, index },
                }),
            },
            {
              label: "Delete",
              key: "delete",
              icon: <DeleteOutlined />,
              danger: true,
              onClick: () =>
                this.onTrainingPeriodContextMenuClick(null, {
                  action: "Delete",
                  data: { currentLearningPeriod: learningPeriod, index },
                }),
            },
          ];

          const periodKey = `${startTs}-${endTs}`;
          return isTraining ? (
            <div key={periodKey}>{periodStr}</div>
          ) : (
            <NgDropdownMenu
              key={periodKey}
              menuItems={menuItems}
              trigger={
                <div className="rule-training-period-list-item">{periodStr}</div>
              }
            />
          );
        })}
      </div>
    );
  }

  getTrainingSliceSummary() {
    const {
      isEdit,
      defaultData: { status },
      currentRuleStatus,
    } = this.props;
    if (!isEdit || !status || !status.training || !status.training.isSlicing) {
      return null;
    }

    let isNewSliceDiscovered = false;
    for (let currentRuleStatusItem of currentRuleStatus) {
      if (currentRuleStatusItem.trainingStatus === SliceTrainingStatus.UNTRAINED) {
        isNewSliceDiscovered = true;
        break;
      }
    }

    if (!isNewSliceDiscovered) {
      return null;
    }

    return (
      <div className="rule-training-config-slice-summary-info-panel-container lightup-horizon-flex-container">
        <div className="rule-training-config-slice-summary-info-panel-info-icon">
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="https://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 0.53125C4.68359 0.53125 0.78125 4.46875 0.78125 9.25C0.78125 14.0664 4.68359 17.9688 9.5 17.9688C14.2812 17.9688 18.2188 14.0664 18.2188 9.25C18.2188 4.46875 14.2812 0.53125 9.5 0.53125ZM9.5 4.39844C10.3086 4.39844 10.9766 5.06641 10.9766 5.875C10.9766 6.71875 10.3086 7.35156 9.5 7.35156C8.65625 7.35156 8.02344 6.71875 8.02344 5.875C8.02344 5.06641 8.65625 4.39844 9.5 4.39844ZM11.4688 13.3281C11.4688 13.5742 11.2578 13.75 11.0469 13.75H7.95312C7.70703 13.75 7.53125 13.5742 7.53125 13.3281V12.4844C7.53125 12.2734 7.70703 12.0625 7.95312 12.0625H8.375V9.8125H7.95312C7.70703 9.8125 7.53125 9.63672 7.53125 9.39062V8.54688C7.53125 8.33594 7.70703 8.125 7.95312 8.125H10.2031C10.4141 8.125 10.625 8.33594 10.625 8.54688V12.0625H11.0469C11.2578 12.0625 11.4688 12.2734 11.4688 12.4844V13.3281Z"
              fill="#4832F3"
            />
          </svg>
        </div>
        <div
          className="rule-training-config-slice-summary-info-panel-info-msg"
          onClick={() => {
            this.setState({ isRuleTrainingSliceInfoSummaryDialogOpen: true });
          }}
        >
          Untrained slices in the inventory . Review training periods to cover all
          observed slices during live detection.
        </div>
      </div>
    );
  }

  getTrainingComponent() {
    const {
      defaultData: {
        config: { metrics },
      },
      isEdit,
      kpiList,
      isTraining,
    } = this.props;

    const {
      isTrainingPeriodDialogOpen,
      isRuleTrainingSliceInfoSummaryDialogOpen,
      currentEditTrainingPeriod,
      currentEditTrainingPeriodIndex,
    } = this.state;
    let kpiInfo = kpiList.filter((kpi) => kpi.metadata.uuid === metrics[0]);
    kpiInfo = kpiInfo.length > 0 ? kpiInfo[0] : null;
    const defaultTrainingPeriod = getDefaultTrainingPeriod(kpiInfo);
    const lastSampleSize = getKPIGranularity(kpiInfo);

    const { trainingPeriods, sliceOverrides } = this.getTrainingPeriodListData();
    const globalTrainingPeriodsList = this.getTrainingPeriodList(trainingPeriods);
    const globalTrainingPeriodLabel =
      trainingPeriods.length === 0 ? "" : `Training Period (${trainingPeriods.length})`;
    const dialogValue = { trainingPeriod: currentEditTrainingPeriod };

    // Generate slice specific training period override list
    let overrideList = sliceOverrides.map((override) => {
      const sliceName = getSliceDisplayString(override.metricSlice, kpiInfo);
      const tpList = (override.symptomConfig.trainingPeriods || []).map((tp) => ({
        ...tp,
        slice: override.metricSlice,
      }));
      const sliceTrainingPeriodLabel =
        tpList.length === 0 ? "" : `${sliceName} (${tpList.length})`;
      const sliceTrainingPeriodsList = this.getTrainingPeriodList(tpList);

      return sliceTrainingPeriodsList ? (
        <LabelItem label={sliceTrainingPeriodLabel}>
          {sliceTrainingPeriodsList}
        </LabelItem>
      ) : null;
    });

    return (
      <>
        {isEdit && this.getTrainingSliceSummary()}
        <GroupPanelItem chunkSize={1}>
          <LabelItem label={globalTrainingPeriodLabel}>
            {globalTrainingPeriodsList}
          </LabelItem>
          {overrideList.filter((item) => item !== null)}
        </GroupPanelItem>
        {isEdit && this.props.currentRuleStatus.length > 0 && (
          <RuleTrainingSliceInfoSummaryDialogTriggerComponent
            onClick={() => {
              this.setState({ isRuleTrainingSliceInfoSummaryDialogOpen: true });
            }}
          />
        )}
        <div className="rule-training-period-dialog-trigger-component-container">
          <TriggerButton
            disabled={isTraining}
            onClick={() => {
              this.setState({
                isTrainingPeriodDialogOpen: !isTrainingPeriodDialogOpen,
                currentEditTrainingPeriodIndex: -1,
                currentEditTrainingPeriod: defaultTrainingPeriod,
              });
            }}
          >
            <PlusOutlined />
          </TriggerButton>
        </div>
        {isTrainingPeriodDialogOpen && (
          <RuleTrainingPeriodDialog
            modalIsOpen={isTrainingPeriodDialogOpen}
            setIsOpen={(isTrainingPeriodDialogOpen) =>
              this.setState({ isTrainingPeriodDialogOpen })
            }
            value={dialogValue}
            isAdd={currentEditTrainingPeriodIndex === -1}
            onApply={this.onTrainingTimeSettingApply.bind(this)}
            isSliceSelectionDisabled={!trainingPeriods.length}
            metric={kpiInfo}
          />
        )}
        {isRuleTrainingSliceInfoSummaryDialogOpen && (
          <RuleTrainingSliceInfoSummaryDialog
            sliceInfoSummary={this.props.currentRuleStatus}
            modalIsOpen={isRuleTrainingSliceInfoSummaryDialogOpen}
            setIsOpen={(isRuleTrainingSliceInfoSummaryDialogOpen) =>
              this.setState({ isRuleTrainingSliceInfoSummaryDialogOpen })
            }
            triggerComponent={null}
            metric={kpiInfo}
          />
        )}
        {this.getAdvancedSettingComponent(lastSampleSize)}
      </>
    );
  }

  onDetectionSettingApply(detectionSettingValues) {
    const { recoveryDuration, driftDuration, aggressiveness, direction } =
      detectionSettingValues;

    const { defaultData } = this.props;

    if (typeof driftDuration === "number") {
      defaultData.config.driftDuration = driftDuration;
    }

    if (typeof recoveryDuration === "number") {
      defaultData.config.recoveryDuration = recoveryDuration;
    }

    if (direction) {
      defaultData.config.symptom.direction = direction;
    }

    if (aggressiveness) {
      defaultData.config.symptom.aggressiveness = aggressiveness;
    }
    this.props.onConfigChange(defaultData);
  }

  getDetectionSettingsComponent() {
    const {
      defaultData: {
        config: {
          metrics,
          driftDuration,
          recoveryDuration,
          symptom: { type, aggressiveness, direction },
        },
      },
      kpiList,
    } = this.props;

    const kpiInfo = kpiList.find((kpi) => kpi.metadata.uuid === metrics[0]);
    const isActivityMetric = getIsActivityMetric(kpiInfo);

    return (
      <>
        <RuleConfigDetectionSettingDisplayView
          symptomType={type}
          enableTooltip={true}
          driftDuration={driftDuration}
          recoveryDuration={recoveryDuration}
          aggressiveness={getRuleRuntimeConfigItemFromObject(
            this.props.defaultData,
            "aggressiveness",
            undefined
          )}
          direction={getRuleRuntimeConfigItemFromObject(
            this.props.defaultData,
            "direction",
            undefined
          )}
        />
        {!isActivityMetric && (
          <RuleTrainingDetectionDialog
            value={{
              driftDuration,
              recoveryDuration,
              aggressiveness,
              direction,
            }}
            symptomType={type}
            detectionAggressiveOptions={[]}
            onApply={this.onDetectionSettingApply}
          />
        )}
      </>
    );
  }

  getMonitorName(featureType) {
    const { defaultData, kpiList } = this.props;

    const {
      config: {
        metrics = [],
        symptom: { type },
      },
    } = defaultData;

    const currentMetric = metrics.length > 0 ? metrics[0] : "";

    const metricInfo = getSelectedMetric(kpiList, currentMetric);
    const autoMetricType = getAutoMetricTypeFromKPI(metricInfo);
    const disableTrendMonitor = isTrendMonitorDisabled(metricInfo);
    const symptomType = getNewSymptomType(type, autoMetricType, disableTrendMonitor);
    const selectedDefaultTitle = getDefaultTitle(
      symptomType,
      metricInfo.metadata.name,
      defaultData.config?.symptom?.featureConfig?.type
    );

    if (defaultData.metadata.name !== selectedDefaultTitle) {
      // The monitor name was customized by the user, so we keep it
      return defaultData.metadata.name;
    }

    return getDefaultTitle(symptomType, metricInfo.metadata.name, featureType);
  }

  getOutOfBoundsComponent() {
    const { defaultData, kpiList, errors } = this.props;

    const {
      config: {
        metrics = [],
        symptom: {
          type,
          bound,
          featureConfig: { type: featureConfigType, windowSize },
        },
        sliceSymptomConfigs,
      },
    } = defaultData;

    const kpiInfo = kpiList.find((kpi) => kpi.metadata.uuid === metrics[0]);
    const metricType = getAutoMetricTypeFromKPI(kpiInfo);
    const isDataDelayMetric = [
      AutoMetricsType.DATA_DELAY,
      AutoMetricsType.UPDATE_DELAY,
    ].includes(metricType);
    const isActivityMetric = getIsActivityMetric(kpiInfo);
    const sampleSize = getKPIGranularity(kpiInfo);

    const disableTrendMonitor = isTrendMonitorDisabled(kpiInfo);
    let thresholdPeriodOptions = [];
    if (isChangeFeatureType(featureConfigType)) {
      thresholdPeriodOptions = [
        { value: DAY_IN_SECONDS, label: "Day over day" },
        { value: WEEK_IN_SECONDS, label: "Week over week" },
      ];

      if (sampleSize !== DAY_IN_SECONDS && sampleSize !== WEEK_IN_SECONDS) {
        thresholdPeriodOptions.push({
          value: sampleSize,
          label: "Sample over sample",
        });
      }
    }

    const isDataDelayValueThreshold =
      isDataDelayMetric && featureConfigType === FeatureTypeConst.VALUE;

    let suffix = "";
    if (featureConfigType === FeatureTypeConst.PERCENTAGE_CHANGE) {
      suffix = "%";
    } else if (isDataDelayMetric) {
      suffix = "minutes";
    }

    const availableFeatureTypeOptions = getTypesAndFeaturesBySymptomType(
      type
    ).features.map((featureType) => ({
      label: featureTypeDisplayNameMap[featureType],
      value: featureType,
    }));

    return (
      <GroupPanelItem chunkSize={1}>
        <LabeledSelect
          disabled={isActivityMetric}
          label="Type"
          options={enabledThresholdFeatureOptions(
            availableFeatureTypeOptions,
            disableTrendMonitor
          )}
          value={featureConfigType}
          onChange={(newFeatureType) => {
            if (newFeatureType !== featureConfigType) {
              defaultData.metadata.name = this.getMonitorName(newFeatureType);
              defaultData.config.symptom.featureConfig.type = newFeatureType;
              this.props.onConfigChange(defaultData);
            }
          }}
        />
        {isChangeFeatureType(featureConfigType) && (
          <LabeledSelect
            label="Period"
            disabled={isActivityMetric}
            options={thresholdPeriodOptions}
            value={windowSize}
            onChange={(newWindowSize) => {
              if (newWindowSize !== windowSize) {
                defaultData.config.symptom.featureConfig.windowSize = newWindowSize;
                this.props.onConfigChange(defaultData);
              }
            }}
          />
        )}
        <BoundSettingView
          disabled={isActivityMetric}
          errors={errors}
          value={bound}
          onChange={(newBound) => {
            defaultData.config.symptom.bound = newBound;
            this.props.onConfigChange(defaultData);
          }}
          suffix={suffix}
          scale={isDataDelayValueThreshold ? MINUTE_IN_SECONDS : 1}
        />
        <SliceThresholdConfig
          disabled={!bound}
          errors={errors}
          sliceSymptomConfigs={sliceSymptomConfigs}
          onChange={(newSliceSymptomConfigs) => {
            const sliceOverrideConfigWithSymptomInfo = newSliceSymptomConfigs.map(
              (sliceOverrideConfig) => {
                const { bound, trainingPeriods, ...restProperties } =
                  defaultData.config.symptom;

                return {
                  metricSlice: sliceOverrideConfig.metricSlice,
                  symptomConfig: {
                    ...restProperties,
                    ...sliceOverrideConfig.symptomConfig,
                  },
                };
              }
            );
            /**
             * https://lightup.atlassian.net/browse/FT-3071
             * TODO: Fix this prop mutation sh*t. The commented code(proper way of doing this) below doesn't work, because
             * the existing code mutates state all over the place to a point that only mutation of state works. This can
             * only be cleaned up in a proper refactoring, which is out of scope of this PR.
             */
            defaultData.config.sliceSymptomConfigs =
              sliceOverrideConfigWithSymptomInfo.length
                ? sliceOverrideConfigWithSymptomInfo
                : null;

            this.props.onConfigChange(defaultData);
          }}
          suffix={suffix}
          scale={isDataDelayValueThreshold ? MINUTE_IN_SECONDS : 1}
          isSliceSelectionDisabled={false}
          metric={kpiInfo}
        />
      </GroupPanelItem>
    );
  }

  getChangeInTrendComponent() {
    const {
      config: {
        symptom: {
          featureConfig: { windowSize },
        },
      },
    } = this.props.defaultData;

    return (
      <GroupPanelItem chunkSize={1}>
        <TimeOption
          value={
            windowSize === undefined
              ? undefined
              : getDisplayTimeFromSecond(windowSize, true, [
                  "s",
                  "m",
                  "h",
                  "d",
                  "w",
                  "mo",
                  "y",
                ])
          }
          onChange={(windowSizeStr) => {
            const newWindowSize = getSecondFromDisplayTime(windowSizeStr);
            if (windowSize !== newWindowSize) {
              const defaultData = this.props.defaultData;
              defaultData.config.symptom.featureConfig.windowSize = newWindowSize;
              this.props.onConfigChange(defaultData);
            }
          }}
          options={windowSizeSettingOptions}
        />
      </GroupPanelItem>
    );
  }

  getIncidentLearningSettingsComponent() {
    const {
      config: {
        symptom: { autoUpdateModel = false },
      },
    } = this.props.defaultData;

    return (
      <GroupPanelItem chunkSize={1}>
        <NgToggleCheckbox
          value={autoUpdateModel}
          onChange={() => {
            const defaultData = this.props.defaultData;
            defaultData.config.symptom.autoUpdateModel = !autoUpdateModel;
            this.props.onConfigChange(defaultData);
          }}
          className="auto-update-model-checkbox-container"
        />
      </GroupPanelItem>
    );
  }

  render() {
    const {
      config: {
        symptom: { type },
      },
    } = this.props.defaultData;
    const steps = [];

    if (type === SymptomTypeConst.MANUAL_THRESHOLD) {
      steps.push({
        title: "Threshold",
        component: this.getOutOfBoundsComponent(),
        className: "kpi-wizard-train-setting-threshold-step",
        skipIndex: true,
        description: "Set the upper and lower threshold bounds",
      });
    } else if (type === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
      steps.push({
        title: "Smoothing",
        component: this.getChangeInTrendComponent(),
        className: "kpi-wizard-train-setting-change-in-trend-step",
        skipIndex: true,
      });
    } else {
      steps.push({
        title: "Training",
        component: this.getTrainingComponent(type),
        className: "kpi-wizard-train-setting-training-period-step",
        skipIndex: true,
        description:
          "Set the training period and review training results before previewing the monitor on past data",
      });
    }

    steps.push({
      title: "Detection settings",
      component: this.getDetectionSettingsComponent(),
      className: "kpi-wizard-detection-setting-step",
      description:
        "Customize the monitor detection settings. Click edit to change and then click Save and train to apply the changes. New settings will apply only after the monitor has been trained.",
      skipIndex: true,
    });

    if (isTrainingRequired(type)) {
      steps.push({
        title: "Incident learning",
        component: this.getIncidentLearningSettingsComponent(),
        className: "kpi-wizard-incident-learning-setting-step",
        description:
          "Accept and reject incidents on the live monitors and use the incident feedback to autoupdate the monitor.",
        skipIndex: true,
      });
    }

    return (
      <div className="rule-train-config-tab">
        <StepContainer steps={steps} className="rule-train-tab-step-container" />
      </div>
    );
  }
}

export default RuleTrainConfigTab;
