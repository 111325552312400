import React, { Component } from "react";
import { Select } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NgMultiSelect from "../../../components/multi-select/ng-multi-select";
import { isSliceEqual, getSliceDisplayString } from "../../../utils/general";
import { getProfilerCurrentMetricSliceValue } from "../../../actions/profiler/profiler-action";

export default class SliceValueSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      searchValue: "",
    };

    this.previousLoc = -1;
    this.isRender = true;
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.fetchSliceValues();
  }

  fetchSliceValues() {
    const { getSelectedMetricSliceValues, metric, currentMetricSliceValues, match } =
      this.props;

    if (!getSelectedMetricSliceValues) return null;

    const workspaceUuid = match?.params?.workspaceUuid;
    const isMetricChanged =
      metric?.metadata?.uuid !== currentMetricSliceValues.metric?.metadata?.uuid;
    const isSliceListEmpty = !currentMetricSliceValues?.data?.length;
    const shouldFetchSliceValues =
      !currentMetricSliceValues.loading && (isMetricChanged || isSliceListEmpty);

    if (shouldFetchSliceValues) {
      getSelectedMetricSliceValues(workspaceUuid, metric, {});
    }
  }

  handleScroll(event) {
    const currentScrollTop = event.target.scrollTop;
    const currentScrollHeight = event.target.scrollHeight;
    const currentClientHeight = event.target.clientHeight;
    console.log(event.target.scrollY);

    const isDown = currentScrollTop > this.previousLoc;
    this.previousLoc = currentScrollTop;
    console.log(`Direction ${isDown ? "Down" : "UP"}`);
    // options has the same length of sliceValueList
    const { currentMetricSliceValues } = this.props;
    const { offset } = this.state;
    console.log(`Current offset is ${offset}`);

    if (isDown) {
      if (
        currentScrollTop + currentClientHeight + 50 > currentScrollHeight &&
        offset + 50 < currentMetricSliceValues.data.length
      ) {
        console.log("Fetching new data");
        this.isRender = true;
        event.stopPropagation();
        const newOffset = Math.min(
          currentMetricSliceValues.data.length - 50,
          offset + 50
        );
        console.log(`new offset: ${newOffset}`);
        this.setState({ offset: newOffset });
        return false;
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.currentMetricSliceValues.loading !==
      this.props.currentMetricSliceValues.loading
    ) {
      return true;
    }

    if (nextProps.value !== this.props.value) {
      return true;
    }

    if (nextState.offset !== this.state.offset) {
      return true;
    }

    if (nextState.searchValue !== this.state.searchValue) {
      return true;
    }

    return false;
  }

  render() {
    const { offset, searchValue } = this.state;
    const {
      value,
      onChange,
      style = { width: "400px" },
      placeholder = "Please select",
      metric,
      mode = "multiple",
      disabled,
      currentMetricSliceValues,
    } = this.props;

    const sliceValueList = currentMetricSliceValues.data;

    const options = sliceValueList.map((slice, index) => {
      return {
        label: getSliceDisplayString(slice, metric),
        value: index,
      };
    });

    const currentFilterOptions = searchValue.trim()
      ? options.filter((currentOption) =>
          `${currentOption.label}`
            .toLowerCase()
            .includes(searchValue.trim().toLowerCase())
        )
      : options;

    const currentDisplayedOptions = currentFilterOptions.slice(0, offset + 100);
    const normalizedValue = Array.isArray(value)
      ? value.map((sliceValue) =>
          sliceValueList.findIndex((slice) => isSliceEqual(slice, sliceValue))
        )
      : value && sliceValueList.findIndex((slice) => isSliceEqual(slice, value));

    const { loading } = currentMetricSliceValues;

    return (
      <NgMultiSelect
        onPopupScroll={(e) => this.handleScroll(e)}
        style={style}
        placeholder={loading ? "Loading slices..." : placeholder}
        searchValue={searchValue}
        onSearch={(newSearchValue) => this.setState({ searchValue: newSearchValue })}
        value={loading ? undefined : normalizedValue}
        loading={loading}
        mode={mode}
        transitionName={""} // Disable animation
        onChange={(selection) => {
          const normalizedSelectSliceValue = Array.isArray(selection)
            ? selection.map((labelValue) => sliceValueList[labelValue])
            : sliceValueList[selection];
          this.setState({
            searchValue:
              mode === "single"
                ? getSliceDisplayString(normalizedSelectSliceValue, metric)
                : "",
          });
          onChange && onChange(normalizedSelectSliceValue);
        }}
        filterOption={false}
        disabled={disabled}
      >
        {currentDisplayedOptions.map((currentOption, i) => {
          return (
            <Select.Option key={i} value={currentOption.value}>
              {currentOption.label}
            </Select.Option>
          );
        })}
      </NgMultiSelect>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMetricSliceValues: state.profilerReducer.profilerCurrentMetricSliceValues,
});

const mapDispatchToProps = (dispatch) => ({
  getSelectedMetricSliceValues: (workspaceUuid, metric, queryOptions) =>
    dispatch(getProfilerCurrentMetricSliceValue(workspaceUuid, metric, queryOptions)),
});

export const ConnectedSliceValueSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SliceValueSelect));
