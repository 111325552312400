import { ReactComponent as ListChecks } from "./svgs/ListChecks.svg";
import { ReactComponent as Swap } from "./svgs/Swap.svg";
import { ReactComponent as GridFour } from "./svgs/GridFour.svg";
import { ReactComponent as Article } from "./svgs/Article.svg";
import { ReactComponent as FileMagnifyingGlass } from "./svgs/FileMagnifyingGlass.svg";
import { ReactComponent as ArrowClick } from "./svgs/ArrowClick.svg";
import { ReactComponent as CaretDown } from "./svgs/CaretDown.svg";
import { ReactComponent as MagnifyingGlass } from "./svgs/MagnifyingGlass.svg";
import { ReactComponent as X } from "./svgs/X.svg";
import { ReactComponent as DotsThreeVertical } from "./svgs/DotsThreeVertical.svg";
import { ReactComponent as PencilSimpleLine } from "./svgs/PencilSimpleLine.svg";
import { ReactComponent as Copy } from "./svgs/Copy.svg";
import { ReactComponent as Trash } from "./svgs/Trash.svg";
import { ReactComponent as Funnel } from "./svgs/Funnel.svg";
import { ReactComponent as Plus } from "./svgs/Plus.svg";
import { ReactComponent as XCircle } from "./svgs/XCircle.svg";
import { ReactComponent as ChartLineUp } from "./svgs/ChartLineUp.svg";
import { ReactComponent as ListNumbers } from "./svgs/ListNumbers.svg";
import { ReactComponent as UploadSimple } from "./svgs/UploadSimple.svg";
import { ReactComponent as Person } from "./svgs/Person.svg";
import { ReactComponent as CheckCircle } from "./svgs/CheckCircle.svg";
import { ReactComponent as Database } from "./svgs/Database.svg";
import { ReactComponent as Repeat } from "./svgs/Repeat.svg";
import { ReactComponent as RepeatOnce } from "./svgs/RepeatOnce.svg";
import { ReactComponent as Plugs } from "./svgs/Plugs.svg";

const IconMap = {
  ListChecks,
  Swap,
  Article,
  GridFour,
  FileMagnifyingGlass,
  ArrowClick,
  CaretDown,
  MagnifyingGlass,
  X,
  DotsThreeVertical,
  PencilSimpleLine,
  Copy,
  Trash,
  Funnel,
  Plus,
  XCircle,
  ChartLineUp,
  ListNumbers,
  UploadSimple,
  Person,
  CheckCircle,
  Database,
  Repeat,
  RepeatOnce,
  Plugs,
};
export default IconMap;
