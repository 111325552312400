import React from "react";
import { Checkbox as AntCheckbox } from "antd";

import { classesName } from "../../utils/css";
import BodyMedium from "../../elements/Typography/BodyMedium";
import { TypographyColors } from "../../elements/Typography/enum";

import "./checkbox.scss";

export default function Checkbox(props) {
  const { className = "", testId, children, disabled, ...restProps } = props;

  return (
    <AntCheckbox
      data-testid={testId}
      className={classesName("lightup-checkbox", className)}
      disabled={disabled}
      {...restProps}
    >
      <BodyMedium
        color={disabled ? TypographyColors.DISABLED : TypographyColors.DEFAULT}
      >
        {children}
      </BodyMedium>
    </AntCheckbox>
  );
}

export function CheckboxGroup(props) {
  const { className = "", testId, ...restProps } = props;
  return (
    <AntCheckbox.Group
      data-testid={testId}
      className={classesName("lightup-checkbox", className)}
      {...restProps}
    />
  );
}
