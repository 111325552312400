import React from "react";
import PropTypes from "prop-types";

import { FontWeights, TypographyTypes, TypographyColors } from "./enum";
import { classesName } from "../../utils/css";

import "./Typography.scss";

export const getTypographyClassnames = (size, weight, color, className) => {
  const baseClass = "energy-typography";
  const colorClass = `${baseClass}--color-${color}`;
  const typographyVariantClass = `${baseClass}__${size}`;
  const weightClass = `${typographyVariantClass}--${weight}`;

  return classesName(
    baseClass,
    typographyVariantClass,
    weightClass,
    colorClass,
    className
  );
};

const Typography = ({
  elementType: Element,
  size,
  weight = FontWeights.REGULAR,
  color = TypographyColors.DEFAULT,
  children,
  className = "",
  testId,
  href, // To support links
  ...props
}) => {
  const classNames = getTypographyClassnames(size, weight, color, className);

  return (
    <Element href={href} className={classNames} data-testid={testId} {...props}>
      {children}
    </Element>
  );
};

Typography.propTypes = {
  elementType: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(TypographyTypes)).isRequired,
  weight: PropTypes.oneOf(Object.values(FontWeights)),
  color: PropTypes.oneOf(Object.values(TypographyColors)),
  className: PropTypes.string, // Optional custom class
  children: PropTypes.node.isRequired,
  href: PropTypes.string, // Needed for anchor tags
  testId: PropTypes.string,
};

export default Typography;
