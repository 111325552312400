import React from "react";
import { Radio as AntRadio } from "antd";

import { classesName } from "../../utils/css";
import BodyMedium from "../../elements/Typography/BodyMedium";
import { TypographyColors } from "../../elements/Typography/enum";

import "./radio.scss";

export default function Radio(props) {
  const { className = "", label, testId, children, disabled, ...restProps } = props;
  return (
    <div
      className={classesName("lightup-radio", !label && "nolabel", className)}
      data-testid={testId}
    >
      <AntRadio disabled={disabled} {...restProps}>
        <BodyMedium
          color={disabled ? TypographyColors.DISABLED : TypographyColors.DEFAULT}
        >
          {children}
        </BodyMedium>
      </AntRadio>
    </div>
  );
}

export function RadioGroup(props) {
  const { className = "", testId, ...restProps } = props;
  return (
    <AntRadio.Group
      data-testid={testId}
      className={classesName("lightup-radio", className)}
      {...restProps}
    />
  );
}
