export const NOTIFICATION_DESCRIPTION_MAP = {
  "ME-001": "Metric failure: missing value filling error",
  "ME-002": "Metric failure: SQL error",
  "ME-003": "Metric failure: timeout",
  "ME-004": "Metric failure: configuration error",

  "ME-006": "Metric paused: excess unique category count",
  "ME-007": "Metric paused: configuration error",
  "ME-008": "Metric paused: SQL timeouts",

  "MO-001": "Monitor: new slices require training",
  "MO-002": "Monitor training successful",
  "MO-003": "Monitor preview successful",
  "MO-005": "Monitor configuration error",
  "MO-006": "Monitor training failed",
  "MO-007": "Monitor runtime error",

  "SO-001": "Datasource connection error",
  "SO-002": "Datasource schema scan failed",
};
