import React from "react";
import ProfilerMetricActionsMenu, {
  MetricActions,
} from "../../profiler/profiler-metric-actions-menu";
import MetricStatusBadge from "../../../components/status-badge/metric-status-badge";

export default function MetricStatus(props) {
  const { metricData, canModifyMetric, selectedRows, metricActionsSharedMenuProps } =
    props;
  const trigger = <MetricStatusBadge metric={metricData} clickable={canModifyMetric} />;

  const metricTargets =
    selectedRows.length > 0 ? selectedRows.map((row) => row.metricData) : [metricData];

  return (
    <ProfilerMetricActionsMenu
      trigger={trigger}
      actions={[MetricActions.STATUS_CHANGE]}
      metrics={metricTargets}
      {...metricActionsSharedMenuProps}
    />
  );
}
