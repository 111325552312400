import { AutoMetricsType, SymptomTypeConst } from "../../utils/enums";
import { isSliceEqual } from "../../utils/general";

export function getNewSymptomType(monitorType, autoMetricType, disableTrendMonitor) {
  if (
    [AutoMetricsType.FULL_COMPARE, AutoMetricsType.AGGREGATION_COMPARE].includes(
      autoMetricType
    ) &&
    monitorType !== SymptomTypeConst.MANUAL_THRESHOLD
  ) {
    return SymptomTypeConst.MANUAL_THRESHOLD;
  }

  if (
    [
      AutoMetricsType.NUMERICAL_DISTRIBUTION,
      AutoMetricsType.CATEGORICAL_DISTRIBUTION,
    ].includes(autoMetricType) &&
    [
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
    ].includes(monitorType)
  ) {
    return SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS;
  }

  if (
    disableTrendMonitor &&
    monitorType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND
  ) {
    return SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS;
  }

  return monitorType;
}

export function getSelectedMetric(metricList, selectedMetric) {
  const metricInfo = metricList.filter((kpi) => kpi.metadata.uuid === selectedMetric);
  if (metricInfo.length === 0) {
    console.log(`Can't find metric id ${selectedMetric}`);
    return null;
  }
  return metricInfo[0];
}

/**
 * Sanitizes the slice symptom configuration overrides by removing overrides for a specific slice
 * if, after an update to a specific key, the override no longer has any relevant symptom configuration.
 *
 * @param {Array} sliceOverrides - The array of slice symptom configuration overrides.
 * @param {Object} targetSlice - The slice that has been updated.
 * @param {string} overrideKeyToCheck - The key in the override that has been updated ('trainingPeriods' or 'bound').
 * @returns {Array} - The sanitized array of slice symptom configuration overrides.
 */
export function sanitizeSliceOverrideSymptomConfig(
  sliceOverrides,
  targetSlice,
  overrideKeyToCheck
) {
  return !targetSlice
    ? sliceOverrides
    : sliceOverrides.filter((currentOverride) => {
        // If the current override does not pertain to the target slice, retain it
        if (!isSliceEqual(currentOverride.metricSlice, targetSlice)) {
          return true;
        }

        // Destructure the symptom configuration for easier access
        const { symptomConfig } = currentOverride;

        // Determine the presence of specific configurations
        const hasTrainingPeriods =
          Array.isArray(symptomConfig?.trainingPeriods) &&
          symptomConfig.trainingPeriods.length > 0;
        const hasUpperBound = symptomConfig?.bound?.upper != null;
        const hasLowerBound = symptomConfig?.bound?.lower != null;

        // Logic based on the updated override key
        if (overrideKeyToCheck === "trainingPeriods") {
          // If 'trainingPeriods' was updated and no training periods remain,
          // check if bounds are also absent to decide on removal
          if (!hasTrainingPeriods && !hasUpperBound && !hasLowerBound) {
            return false; // Remove this override
          }
        } else if (overrideKeyToCheck === "bound") {
          // If 'bound' was updated and both upper and lower bounds are absent,
          // check if training periods are also absent to decide on removal
          if (!hasUpperBound && !hasLowerBound && !hasTrainingPeriods) {
            return false; // Remove this override
          }
        }

        // For other keys or if relevant configurations still exist, retain the override
        return true;
      });
}
