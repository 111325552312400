import React from "react";
import {
  isValueError,
  validateData,
  ValidationMethodNameConst,
} from "../../../../utils/validate";
import LegacyModalDialogAdapter from "../../../legacy-adapter/legacy-modal-dialog-adapter";
import ModalConfirmationButtons from "../../../modal-dialog/modal-confirmation-buttons";
import { LabeledDatePicker } from "../../../labeled-control/labeled-control";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import { ModalFieldRows } from "../../../modal-dialog/modal-field-group";

import "./rule-training-period-dialog.scss";
import { ConnectedSliceValueSelect } from "../../../../views/profiler/custom-metric/slice-value-select";
import BodySmall from "../../../../elements/Typography/BodySmall";
import { FontWeights, TypographyColors } from "../../../../elements/Typography/enum";
import NgTooltip from "../../../tooltip/ng-tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { isSlicedMetric } from "../../../../utils/metric";

function RuleTrainingPeriodDialogBodyComponent(props) {
  const {
    value: { trainingPeriod = {} },
    errors,
    onChange,
    metric,
    isSliceSelectionDisabled,
  } = props;

  const { startTs, endTs, slice } = trainingPeriod;
  function onTrainingStartTimeChange(newStartTime) {
    const newStartTimestamp = getUnixTime(newStartTime);
    if (newStartTimestamp !== startTs) {
      onChange({
        trainingPeriod: {
          startTs: newStartTimestamp,
          endTs,
          slice,
        },
      });
    }
  }

  function onTrainingEndTimeChange(newEndTime) {
    const newEndTimestamp = getUnixTime(newEndTime);
    if (newEndTimestamp !== endTs) {
      onChange({
        trainingPeriod: {
          startTs,
          endTs: newEndTimestamp,
          slice,
        },
      });
    }
  }

  function onSliceSelect(newSlice) {
    if (newSlice !== slice) {
      onChange({
        trainingPeriod: {
          startTs,
          endTs,
          slice: newSlice,
        },
      });
    }
  }

  const shouldShowSliceOverrideOptions = isSlicedMetric(metric);

  const startTimeHasError =
    !!errors["learningPeriod.startTimestamp"] ||
    !!errors["learningPeriod.startTimestamp.endTimestamp"];

  const endTimeHasError = !!errors["learningPeriod.endTimestamp"];

  return (
    <div className="rule-training-period-dialog-content">
      <ModalFieldRows>
        {shouldShowSliceOverrideOptions && (
          <>
            <div className="slice-select-label">
              <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
                Select slice
              </BodySmall>
              <NgTooltip title="If no slices are selected, training period applies to all slices">
                <InfoCircleOutlined />
              </NgTooltip>
            </div>
            <ConnectedSliceValueSelect
              metric={metric}
              value={slice}
              mode="single"
              onChange={onSliceSelect}
              style={{ width: "100%" }}
              disabled={isSliceSelectionDisabled}
            />
          </>
        )}
        <LabeledDatePicker
          label="Start Time"
          staticLabel
          showTime
          value={fromUnixTime(startTs)}
          status={startTimeHasError ? "error" : null}
          onChange={onTrainingStartTimeChange}
        />
        <LabeledDatePicker
          label="End Time"
          staticLabel
          showTime
          value={fromUnixTime(endTs)}
          status={endTimeHasError ? "error" : null}
          onChange={onTrainingEndTimeChange}
        />
      </ModalFieldRows>
    </div>
  );
}

function RuleTrainingPeriodDialogFooterComponent(props) {
  const { onCancelClicked, onApplyClicked, error } = props;

  return (
    <ModalConfirmationButtons
      onOkClick={onApplyClicked}
      onCancelClick={onCancelClicked}
      okButtonProps={{ disabled: Boolean(error) }}
    />
  );
}

function RuleTrainingPeriodDialogComponent(props) {
  const {
    value,
    isAdd = false,
    onApply,
    modalIsOpen,
    setIsOpen,
    sliceValueList,
    isSliceSelectionDisabled,
    metric,
  } = props;

  const [trainingTimeSettingValue, setTrainingTimeSettingValue] = React.useState(value);
  const [valueErrors, setValueErrors] = React.useState({});

  const dataValidationModal = {
    learningPeriod: {
      validators: [
        {
          path: "startTimestamp",
          errorPath: "learningPeriod.startTimestamp",
          method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
        },
        {
          path: ["startTimestamp", "endTimestamp"],
          errorPath: "learningPeriod.startTimestamp.endTimestamp",
          method: ValidationMethodNameConst.START_TIME_NO_LATE_THAN_END_TIME,
        },
        {
          path: "endTimestamp",
          errorPath: "learningPeriod.endTimestamp",
          method: ValidationMethodNameConst.TIME_NO_LATE_THAN_NOW,
        },
      ],
    },
  };

  function onCancelClicked() {
    setTrainingTimeSettingValue(value);
    setValueErrors({});
    setIsOpen(false);
  }

  function onApplyClicked() {
    if (onApply) {
      onApply(trainingTimeSettingValue);
    }
    setIsOpen(false);
  }

  function updateTrainingTimeSettingValue(newValue) {
    setTrainingTimeSettingValue(newValue);
    const newErrors = validateData(newValue, dataValidationModal);
    setValueErrors({ ...valueErrors, ...newErrors });
  }

  return (
    <LegacyModalDialogAdapter
      title={`${isAdd ? "Add" : "Edit"} training period`}
      modalIsOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      containerClassName={"rule-training-period-dialog-container"}
      triggerComponent={null}
      contentComponent={
        <RuleTrainingPeriodDialogBodyComponent
          value={trainingTimeSettingValue}
          errors={valueErrors}
          onChange={updateTrainingTimeSettingValue}
          sliceValueList={sliceValueList}
          isSliceSelectionDisabled={isSliceSelectionDisabled}
          metric={metric}
        />
      }
      footerComponent={
        <RuleTrainingPeriodDialogFooterComponent
          onCancelClicked={onCancelClicked}
          onApplyClicked={onApplyClicked}
          error={isValueError(valueErrors)}
        />
      }
    />
  );
}

export default RuleTrainingPeriodDialogComponent;
