import React, { useEffect, useState } from "react";
import AlertConfigSettingView from "../../components/alert-config-setting-view/";
import {
  columnColumn,
  columnFn,
  safeToLowerCase,
  tableColumn,
} from "../../components/entity-list/columns";
import { LabeledMultiSelect } from "../../components/labeled-control/labeled-control";
import ModalConfirmationButtons from "../../components/modal-dialog/modal-confirmation-buttons";
import ModalDialog from "../../components/modal-dialog/modal-dialog";
import NgTable, { NgTableTheme } from "../../components/table/ng-table";
import { NgTextTooltip } from "../../components/text-tooltip/ng-text-tooltip";
import { getFullTableName } from "../../utils/datasource";
import { MetricCategory, MetricType, TableType } from "../../utils/enums";
import { indexBy } from "../../utils/iterables";
import { isAutoCreationTypeMetric } from "../../utils/metric";
import { getGroupedAutoMetricsMapFromUsage } from "./utils";
import { getMetricTypeFromConfigData } from "../../components/metric/utils";
import NgTooltip from "../../components/tooltip/ng-tooltip";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Spinner } from "../../atom/spinner";
import SearchInput from "../../atom/SearchInput";

import "./profiler-monitor-all-dialog.scss";

function getTableRowsAndStats(data, opts = {}) {
  const {
    usage: { metrics: usageMetrics },
    refs,
  } = data.data;
  const { includeAutoMetrics = true, includeCustomMetrics = true, searchValue } = opts;

  const metricMapper = indexBy(refs, (ref) => ref.metadata.uuid);

  const isTargetMetricCreationType = (usageMetric) => {
    const isAutoMetric = isAutoCreationTypeMetric(metricMapper[usageMetric.uuid]);
    return (
      (includeAutoMetrics && isAutoMetric) || (includeCustomMetrics && !isAutoMetric)
    );
  };
  const isCompareMetric = (usageMetric) =>
    [MetricCategory.AGGREGATION_COMPARE, MetricCategory.FULL_COMPARE].includes(
      getMetricTypeFromConfigData(metricMapper[usageMetric.uuid])
    );
  const isUsageMetricMonitored = (usageMetric) => usageMetric.num_rules > 0;
  const matchesSearchValue = (usageMetric) =>
    !searchValue ||
    (metricMapper[usageMetric.uuid]?.metadata?.name ?? "")
      .toLowerCase()
      .includes(searchValue.trim().toLowerCase());

  // Auto monitor creation is not supported for compare metrics, so don't show them.
  const supportedUsageMetrics = usageMetrics.filter(
    (usageMetric) => !isCompareMetric(usageMetric)
  );
  const unmonitoredUsageMetrics = supportedUsageMetrics.filter(
    (usageMetric) =>
      !isCompareMetric(usageMetric) && !isUsageMetricMonitored(usageMetric)
  );
  const targetUsageMetrics = unmonitoredUsageMetrics.filter(
    (usageMetric) =>
      isTargetMetricCreationType(usageMetric) && matchesSearchValue(usageMetric)
  );

  const rows = targetUsageMetrics.map(({ uuid }) => {
    const metricObject = metricMapper[uuid];
    return {
      uuid,
      name: metricObject.metadata.name,
      tableName:
        metricObject.config?.table?.type === TableType.TABLE
          ? getFullTableName(metricObject.config.table)
          : "",
      columnName:
        metricObject.config.valueColumns && metricObject.config.valueColumns.length > 0
          ? metricObject.config.valueColumns[0].columnName
          : "",
    };
  });

  const total = supportedUsageMetrics.length;
  const unmonitoredTotal = unmonitoredUsageMetrics.length;
  const coverage =
    total > 0 ? `${((100 * (total - unmonitoredTotal)) / total).toFixed(2)}%` : "-";

  return { rows, total, unmonitoredTotal, coverage };
}

const metricsListColumns = [
  columnFn({
    title: "Name",
    key: "name",
    getCompareVal: (indexedData) => safeToLowerCase(indexedData),
    render: (text) => <NgTextTooltip>{text}</NgTextTooltip>,
  })({ dataIndex: "name" }),
  tableColumn({ dataIndex: "tableName" }),
  columnColumn({ dataIndex: "columnName" }),
];

function ProfilerMonitorAllDialogMetrics(props) {
  const {
    rows,
    searchValue,
    setSearchValue,
    selectedMetricUuids,
    total,
    unmonitoredTotal,
    coverage,
    selectedCreationTypes,
    setSelectedMetricUuids,
    setSelectedCreationTypes,
  } = props;

  const profileResultArray = [
    { label: "Total metrics", value: total },
    { label: "Monitored", value: total - unmonitoredTotal },
    { label: "Not monitored", value: unmonitoredTotal },
    { label: "Coverage", value: coverage },
  ];

  return (
    <div className="profiler-monitor-all-metrics">
      <div className="profiler-monitor-all-stats">
        {profileResultArray.map(({ label, value }) => (
          <div className="profiler-monitor-all-stat" key={label}>
            <div className="profiler-monitor-all-stat-label">{label}</div>
            <div className="profiler-monitor-all-stat-value">{value}</div>
          </div>
        ))}
      </div>
      <div className="profiler-monitor-all-search-container">
        <div className="profiler-monitor-all-search-container-header">
          <div className="profiler-monitor-all-search-container-header-left">
            Select metrics to monitor
            <NgTooltip title="Only metrics that support anomaly monitors will be monitored, so row by row and compare aggregate are excluded from bulk monitoring.">
              <InfoCircleOutlined />
            </NgTooltip>
          </div>
          <span className="profiler-monitor-all-search-container-activity-notice">
            All Activity Metrics will be monitored
          </span>
        </div>
        <SearchInput
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </div>
      <div>
        <div className="profiler-monitor-all-metrics-type-select">
          <LabeledMultiSelect
            label=""
            placeholder="Select metric types"
            maxTagCount="responsive"
            mode="multiple"
            options={[
              { label: "Show Auto Metrics", value: MetricType.AUTO },
              { label: "Show User Defined Metrics", value: MetricType.CUSTOM },
            ]}
            value={selectedCreationTypes}
            onChange={setSelectedCreationTypes}
          />
          <div className="profiler-monitor-all-metrics-list-selected-count">
            <span>Metrics Selected</span>
            <span className="profiler-monitor-all-metrics-list-selected-count-value">
              {selectedMetricUuids.length}
            </span>
          </div>
        </div>
      </div>
      {/* There seems to be some visual jitter in the table headings below. Excluding this section from
      Chromatic snapshot diffs. */}
      <div className="profiler-monitor-all-metrics-list chromatic-ignore">
        <NgTable
          theme={NgTableTheme.LIGHT}
          rowSelection={{
            selectedRowKeys: selectedMetricUuids,
            onChange: (selectedKeys) => {
              setSelectedMetricUuids(selectedKeys);
            },
            preserveSelectedRowKeys: true,
          }}
          dataSource={rows}
          columns={metricsListColumns}
          rowKey="uuid"
          size={"middle"}
          pagination={false}
          scroll={{ y: 190 }}
        />
      </div>
    </div>
  );
}

function ProfilerMonitorAllDialog(props) {
  const {
    modalIsOpen,
    setModalIsOpen,
    alertingChannelList = [],
    data,
    okClicked,
  } = props;

  const [value, setValue] = useState({
    isMuted: false,
    channels: [],
  });
  const [selectedMetricUuids, setSelectedMetricUuids] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCreationTypes, setSelectedCreationTypes] = useState([
    MetricType.AUTO,
    MetricType.CUSTOM,
  ]);

  const { rows, total, unmonitoredTotal, coverage } = data.loading
    ? {}
    : getTableRowsAndStats(data, {
        includeAutoMetrics: selectedCreationTypes.includes(MetricType.AUTO),
        includeCustomMetrics: selectedCreationTypes.includes(MetricType.CUSTOM),
        searchValue,
      });

  useEffect(() => {
    if (!data.loading) {
      setSelectedMetricUuids(rows.map(({ uuid }) => uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.loading]);

  function closeModal() {
    setModalIsOpen(false);
  }

  function onOkClicked() {
    const selectedMetricUuidsSet = new Set(selectedMetricUuids);
    const groupedAutoMetricsMap = getGroupedAutoMetricsMapFromUsage({
      ...data.data,
      usage: {
        ...data.data.usage,
        metrics: data.data.usage.metrics.filter((usageMetric) =>
          selectedMetricUuidsSet.has(usageMetric.uuid)
        ),
      },
    });
    okClicked(groupedAutoMetricsMap, value);
    setModalIsOpen(false);
  }

  const modalContent = data.loading ? (
    <div className="profiler-monitor-all-loading">
      <Spinner size="large" />
    </div>
  ) : (
    <div className="profiler-monitor-all-body">
      <ProfilerMonitorAllDialogMetrics
        rows={rows}
        searchValue={searchValue}
        selectedMetricUuids={selectedMetricUuids}
        total={total}
        unmonitoredTotal={unmonitoredTotal}
        coverage={coverage}
        setSearchValue={setSearchValue}
        selectedCreationTypes={selectedCreationTypes}
        setSelectedMetricUuids={setSelectedMetricUuids}
        setSelectedCreationTypes={setSelectedCreationTypes}
      />
      <AlertConfigSettingView
        value={value}
        alertingChannelList={alertingChannelList}
        onChange={setValue}
        enableMute={false}
        enableSchedule={false}
      />
      <ModalConfirmationButtons onOkClick={onOkClicked} onCancelClick={closeModal} />
    </div>
  );

  return (
    <ModalDialog
      title="Enable monitoring"
      closable
      wrapClassName="profiler-monitor-all-dialog"
      visible={modalIsOpen}
      onCancel={() => setModalIsOpen(false)}
      footer={null}
    >
      {modalContent}
    </ModalDialog>
  );
}

export default ProfilerMonitorAllDialog;
