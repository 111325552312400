import React, { useCallback, useState } from "react";
import Button, { ButtonType } from "../../../../atom/Button";
import Dialog from "../../../../atom/dialog";
import Icon, { IconName, IconSizes } from "../../../../elements/Icon";
import { DialogSize } from "../../../../atom/shared/enums";
import useToggle from "../../../../hooks/useToggle";
import BodyLarge from "../../../../elements/Typography/BodyLarge";
import BoundSettingView from "../../../bound-setting-view";
import { ConnectedSliceValueSelect } from "../../../../views/profiler/custom-metric/slice-value-select";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import NgDropdownMenu from "../../../ng-dropdown-menu";
import BodySmall from "../../../../elements/Typography/BodySmall";
import { FontWeights, TypographyColors } from "../../../../elements/Typography/enum";
import { getSliceDisplayString, isSliceEqual } from "../../../../utils/general";
import { MINUTE_IN_SECONDS } from "../../../../utils/time";
import { MONITOR_CONFIG_ACTIONS } from "./constants";

import "./rule-train-slice-threshold-config.scss";
import { sanitizeSliceOverrideSymptomConfig } from "../../utils";
import { isSlicedMetric } from "../../../../utils/metric";

export default function SliceThresholdConfig({
  sliceSymptomConfigs,
  onChange,
  errors,
  suffix,
  isDataDelayValueThreshold,
  isActivityMetric,
  disabled,
  metric,
}) {
  const [visible, toggleVisibility] = useToggle();
  const [currentEditIndex, setCurrentEditIndex] = useState(-1);
  const [thresholdState, setThresholdState] = useState({});

  const onNewThresholdOverrideClick = () => {
    setCurrentEditIndex(-1);
    setThresholdState({});
    toggleVisibility();
  };

  const onSliceChange = useCallback(
    (selectedSlice) => {
      const existingConfig = sliceSymptomConfigs?.find((config) =>
        isSliceEqual(config.metricSlice, selectedSlice)
      );

      if (existingConfig && existingConfig.symptomConfig?.bound) {
        // If an existing threshold is found, populate the threshold values
        setThresholdState({
          slice: selectedSlice,
          lower: existingConfig.symptomConfig.bound.lower,
          upper: existingConfig.symptomConfig.bound.upper,
        });
      } else {
        // If no existing threshold is found, just set the selected slice
        setThresholdState((currState) => ({ ...currState, slice: selectedSlice }));
      }
    },
    [sliceSymptomConfigs]
  );

  const onThresholdChange = useCallback((updates) => {
    setThresholdState((currState) => ({ ...currState, ...updates }));
  }, []);

  const onEditThresholdOverride = useCallback(
    ({ action, index }) => {
      if (action === MONITOR_CONFIG_ACTIONS.EDIT) {
        const editingThreshold = sliceSymptomConfigs[index];

        setThresholdState({
          slice: editingThreshold.metricSlice,
          ...editingThreshold.symptomConfig.bound,
        });
        setCurrentEditIndex(index);
        toggleVisibility();
      } else if (action === MONITOR_CONFIG_ACTIONS.DELETE) {
        const updatedSliceSymptomConfigs = sliceSymptomConfigs.filter(
          (_, idx) => idx !== index
        );

        onChange(updatedSliceSymptomConfigs);
      }
    },
    [sliceSymptomConfigs, onChange, toggleVisibility]
  );

  const onSave = () => {
    const { slice, upper, lower } = thresholdState;

    const thresholdOverrideConfig = {
      metricSlice: slice,
      symptomConfig: {
        bound: {
          lower,
          upper,
        },
      },
    };

    let updatedSliceSymptomConfigs = sliceSymptomConfigs
      ? [...sliceSymptomConfigs]
      : [];

    if (currentEditIndex !== -1) {
      // Editing mode: Check if the slice has been changed to match an existing entry
      const existingIndex = updatedSliceSymptomConfigs.findIndex(
        (config, index) =>
          index !== currentEditIndex && isSliceEqual(config.metricSlice, slice)
      );

      if (existingIndex !== -1) {
        // If a matching entry exists, update it without deleting the one at currentEditIndex
        updatedSliceSymptomConfigs[existingIndex] = thresholdOverrideConfig;
      } else {
        // No duplicates, directly update the item at currentEditIndex
        updatedSliceSymptomConfigs[currentEditIndex] = thresholdOverrideConfig;
      }
    } else {
      // Adding a new override: Check for an existing slice override
      const existingIndex = updatedSliceSymptomConfigs.findIndex((config) =>
        isSliceEqual(config.metricSlice, slice)
      );

      if (existingIndex !== -1) {
        // Update the existing item in place
        updatedSliceSymptomConfigs[existingIndex] = thresholdOverrideConfig;
      } else {
        // Add the new item to the end
        updatedSliceSymptomConfigs.push(thresholdOverrideConfig);
      }
    }

    // Remove config object from overrides array if it's bound object is empty
    const cleanedUpSliceOverrides = sanitizeSliceOverrideSymptomConfig(
      updatedSliceSymptomConfigs,
      slice,
      "bound"
    );

    // Save the updated configuration without deleting any entries
    toggleVisibility();
    onChange(cleanedUpSliceOverrides);
  };

  const renderThresholdOverridesList = () => {
    if (!sliceSymptomConfigs) return null;

    return (
      <>
        <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
          Slice thresholds
        </BodySmall>
        <div className="slice-threshold-list-container">
          {sliceSymptomConfigs.map((sliceOverrideConfig, index) => {
            const {
              metricSlice,
              symptomConfig: { bound },
            } = sliceOverrideConfig;

            const sliceString = getSliceDisplayString(metricSlice, metric);

            const menuItems = [
              {
                label: "Edit",
                key: "edit",
                icon: <EditOutlined />,
                onClick: () =>
                  onEditThresholdOverride({
                    action: MONITOR_CONFIG_ACTIONS.EDIT,
                    index,
                  }),
              },
              {
                label: "Delete",
                key: "delete",
                icon: <DeleteOutlined />,
                danger: true,
                onClick: () =>
                  onEditThresholdOverride({
                    action: MONITOR_CONFIG_ACTIONS.DELETE,
                    index,
                  }),
              },
            ];

            const boundKey = `${bound.lower}-${bound.upper}-${sliceString}`;
            return (
              <div key={boundKey} className="slice-threshold-override-item-wrapper">
                <BodySmall color={TypographyColors.INFO2}>{sliceString}</BodySmall>
                <NgDropdownMenu
                  menuItems={menuItems}
                  trigger={
                    <div className="slice-threshold-override-item">
                      <BodySmall color="#5C45FF">Lower </BodySmall>
                      <BodySmall weight={FontWeights.MEDIUM} color="#5C45FF">
                        {bound.lower !== undefined ? `${bound.lower} ${suffix}` : "-"}
                      </BodySmall>
                      <BodySmall color="#5C45FF"> | Upper </BodySmall>
                      <BodySmall weight={FontWeights.MEDIUM} color="#5C45FF">
                        {bound.upper !== undefined ? `${bound.upper} ${suffix}` : "-"}
                      </BodySmall>
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  if (!isSlicedMetric(metric)) {
    return null;
  }

  const isSaveDisabled =
    thresholdState.slice === undefined ||
    (thresholdState.lower === undefined && thresholdState.upper === undefined);

  return (
    <div className="slice-override-threshold-container">
      {renderThresholdOverridesList()}
      <Button
        icon={<Icon name={IconName.Plus} size={IconSizes.MEDIUM} />}
        type={ButtonType.LINK}
        label="Slice thresholds"
        onClick={onNewThresholdOverrideClick}
        disabled={disabled}
        className="add-slice-threshold-config-button"
      />
      <Dialog
        size={DialogSize.SMALL}
        visible={visible}
        onCancel={toggleVisibility}
        onOk={onSave}
        title={<BodyLarge>Slice thresholds</BodyLarge>}
        mask
        className="slice-threshold-config-dialog"
        okButtonProps={{ disabled: isSaveDisabled }}
      >
        <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
          Select slice
        </BodySmall>
        <ConnectedSliceValueSelect
          mode="single"
          onChange={onSliceChange}
          value={thresholdState.slice}
          style={{ width: "100%" }}
          metric={metric}
        />
        <BoundSettingView
          disabled={isActivityMetric}
          errors={errors}
          value={thresholdState}
          onChange={onThresholdChange}
          suffix={suffix}
          scale={isDataDelayValueThreshold ? MINUTE_IN_SECONDS : 1}
        />
      </Dialog>
    </div>
  );
}
