import React from "react";
import Icon, { IconName } from "../../elements/Icon";
import AlertingStatusBadge from "./alerting-status-badge";
import AlertingActionsMenu from "./alerting-actions-menu";
import "./monitor-list.scss";
export default function AlertStatus(props) {
  const {
    canModify,
    monitorData,
    selectedRows,
    workspaceUserPermissions,
    onAlertConfigUpdated,
    onManageChannels,
  } = props;
  const editBtn = (
    <Icon name={IconName.PencilSimpleLine} className="edit-notification" size={14} />
  );
  if (canModify) {
    const targetMonitors =
      selectedRows.length > 0
        ? selectedRows.map((row) => row.monitorData)
        : [monitorData];
    return (
      <div className="monitor-alert-status">
        <AlertingStatusBadge monitor={monitorData} />
        <AlertingActionsMenu
          trigger={editBtn}
          monitors={targetMonitors}
          workspaceUserPermissions={workspaceUserPermissions}
          onAlertConfigUpdated={onAlertConfigUpdated}
          onManageChannels={onManageChannels}
        />
      </div>
    );
  }
  return (
    <div className="monitor-alert-status">
      <AlertingStatusBadge monitor={monitorData} />
      {canModify && editBtn}
    </div>
  );
}
