import React from "react";
import TitleLarge from "../../elements/Typography/TitleLarge";
import { FontWeights } from "../../elements/Typography/enum";
import Icon from "../../elements/Icon";
import "./style.scss";
export default function PageHeader(props) {
  const { iconName, iconSize, title, rightContent } = props;
  return (
    <div className="page-header">
      <div className="left-panel">
        <TitleLarge weight={FontWeights.BOLD} className="header-title">
          <Icon name={iconName} size={iconSize} />
          {title}
        </TitleLarge>
      </div>
      <div className="right-panel">{rightContent}</div>
    </div>
  );
}
