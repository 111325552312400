import React, { Component } from "react";
import { fromUnixTime, getUnixTime } from "date-fns";
import GroupPanelItem from "../../../group-panel-item/group-panel-item";
import {
  LabeledDatePicker,
  LabeledInput,
  LabeledSelect,
} from "../../../labeled-control/labeled-control";
import StepContainer from "../../../step/step";
import NgToggleCheckbox from "../../../toggle-checkbox/ng-toggle-checkbox";
import { getRuleSymptomInfo } from "../../../../utils/icon";
import { getObjectUuidToInfoMapper } from "../../../../utils/general";
import { getAlertingChannelDisplayType } from "../../../../utils/alerting";
import {
  AutoMetricsType,
  SymptomTypeConst,
  getSymptomTypeDisplayName,
} from "../../../../utils/enums";
import { getDefaultTitle, getDefaultSymptomConfig } from "../../../../utils/defaults";
import {
  getAutoMetricTypeFromKPI,
  getKPIDisplayName,
  isActivityMetric,
  isTrendMonitorDisabled,
} from "../../../../utils/metric";
import { SubmitType } from "../../../../views/integrations/integration-metadata";
import { getNewSymptomType, getSelectedMetric } from "../../utils";
import AlertConfigSection, { RuleBasicConfigInfoHeader } from "./alert-config-section";
import SliceOverrideAlerts, {
  AlertChannelIcon,
  ScheduleIcon,
} from "./slice-overrides-alert-config";

import "./rule-basic-config-tab.scss";
const symptomTypeOptions = [];
Object.values(SymptomTypeConst).forEach((symptomType) => {
  if (
    ![
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
      SymptomTypeConst.SLOW_BURN_TREND_CHANGE,
    ].includes(symptomType)
  ) {
    symptomTypeOptions.push({
      label: getSymptomTypeDisplayName(symptomType),
      value: symptomType,
    });
  }
});

const valuesOutOfExpectationsSymptomOptions = [
  {
    label: getSymptomTypeDisplayName(SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS),
    value: SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS,
  },
];

const thresholdSymptomOptions = [
  {
    label: getSymptomTypeDisplayName(SymptomTypeConst.MANUAL_THRESHOLD),
    value: SymptomTypeConst.MANUAL_THRESHOLD,
  },
];

function BackFillIcon(props) {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path d="M0 0H68V68H0V0Z" fill="#F7F7FB" />
      <path
        d="M16 52L22 22L28 52L34 42L40 52L46 22L52 52"
        stroke="#4832F3"
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2071 56.2929C19.5976 56.6834 19.5976 57.3166 19.2071 57.7071L18.4142 58.5H37.5858L36.7929 57.7071C36.4024 57.3166 36.4024 56.6834 36.7929 56.2929C37.1834 55.9024 37.8166 55.9024 38.2071 56.2929L40.7064 58.7922C40.7088 58.7946 40.7112 58.797 40.7136 58.7995C40.8063 58.8938 40.8764 59.002 40.9241 59.1172C40.9727 59.2343 40.9996 59.3625 41 59.497L41 59.5L41 59.503C40.9996 59.6375 40.9727 59.7657 40.9241 59.8828C40.8746 60.0021 40.8027 60.1098 40.7136 60.2005C40.7112 60.203 40.7088 60.2054 40.7064 60.2078L38.2071 62.7071C37.8166 63.0976 37.1834 63.0976 36.7929 62.7071C36.4024 62.3166 36.4024 61.6834 36.7929 61.2929L37.5858 60.5H18.4142L19.2071 61.2929C19.5976 61.6834 19.5976 62.3166 19.2071 62.7071C18.8166 63.0976 18.1834 63.0976 17.7929 62.7071L16.5431 61.4573L15.2934 60.2076L15.2908 60.205C15.196 60.1096 15.1243 59.9999 15.0759 59.8828C15.027 59.7649 15 59.6356 15 59.5C15 59.3644 15.027 59.2351 15.0759 59.1172C15.1243 59.0001 15.196 58.8904 15.2908 58.795L15.2929 58.7929L17.7929 56.2929C18.1834 55.9024 18.8166 55.9024 19.2071 56.2929Z"
        fill="#121224"
      />
      <rect opacity="0.3" x="40" y="14" width="14" height="40" fill="#B80739" />
      <rect
        x="40.125"
        y="14.125"
        width="0.25"
        height="39.75"
        stroke="#B80739"
        strokeWidth="0.25"
      />
    </svg>
  );
}

class RuleBasicConfigTab extends Component {
  constructor(props) {
    super(props);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onKpiChange = this.onKpiChange.bind(this);
    this.onSymptomTypeChange = this.onSymptomTypeChange.bind(this);
    this.onIsBetaChange = this.onIsBetaChange.bind(this);
    this.onIsTrendChange = this.onIsTrendChange.bind(this);
    this.onLiveFilterStartTimeChange = this.onLiveFilterStartTimeChange.bind(this);
    this.onAlertChannelChange = this.onAlertChannelChange.bind(this);
    this.onMonitorStatusChange = this.onMonitorStatusChange.bind(this);
    this.onSliceAlertConfigUpdate = this.onSliceAlertConfigUpdate.bind(this);
    this.onSliceAlertConfigDelete = this.onSliceAlertConfigDelete.bind(this);
  }

  onTitleChange(e) {
    const newTitle = e.target.value;
    const { defaultData } = this.props;
    if (defaultData.metadata.name === newTitle) {
      return;
    }

    defaultData.metadata.name = newTitle;
    this.props.onConfigChange(defaultData);
  }

  onKpiChange(newSelectedKpi) {
    const { defaultData, isClone } = this.props;

    const {
      config: {
        metrics = [],
        symptom: { type },
      },
    } = defaultData;
    const oldSelectedKpi = metrics.length > 0 ? metrics[0] : "";
    if (newSelectedKpi !== oldSelectedKpi) {
      const kpiInfo = getSelectedMetric(this.props.kpiList, newSelectedKpi);
      const oldKpiInfo = getSelectedMetric(this.props.kpiList, oldSelectedKpi);

      if (!kpiInfo || !oldKpiInfo) {
        return;
      }

      const oldAutoMetricType = getAutoMetricTypeFromKPI(oldKpiInfo);
      const oldDisableTrendMonitor = isTrendMonitorDisabled(oldKpiInfo);
      const autoMetricType = getAutoMetricTypeFromKPI(kpiInfo);
      const disableTrendMonitor = isTrendMonitorDisabled(kpiInfo);

      const newSymptomType = getNewSymptomType(
        type,
        autoMetricType,
        disableTrendMonitor
      );

      let newTitle = getDefaultTitle(
        newSymptomType,
        kpiInfo.metadata.name,
        defaultData.config?.symptom?.featureConfig?.type
      );

      defaultData.config.metrics = [newSelectedKpi];

      if (
        oldAutoMetricType !== autoMetricType ||
        oldDisableTrendMonitor !== disableTrendMonitor
      ) {
        const { symptom, driftDuration, recoverDuration } = getDefaultSymptomConfig(
          newSymptomType,
          kpiInfo
        );

        defaultData.config.symptom = symptom;
        defaultData.config.driftDuration = driftDuration;
        defaultData.config.recoverDuration = recoverDuration;
        newTitle = getDefaultTitle(
          newSymptomType,
          kpiInfo.metadata.name,
          symptom?.featureConfig?.type
        );
      }

      if (!isClone) {
        defaultData.metadata.name = newTitle;
      }

      this.props.onConfigChange(defaultData);
    }
  }

  onSymptomTypeUpdate(newSymptomType) {
    const { defaultData } = this.props;

    const {
      config: { metrics },
    } = defaultData;
    const kpiInfo = getSelectedMetric(this.props.kpiList, metrics[0]);
    if (!kpiInfo) {
      return;
    }

    const { symptom, driftDuration, recoverDuration } = getDefaultSymptomConfig(
      newSymptomType,
      kpiInfo
    );
    const newTitle = getDefaultTitle(
      newSymptomType,
      kpiInfo.metadata.name,
      symptom.featureConfig?.type
    );
    defaultData.metadata.name = newTitle;
    defaultData.config.symptom = symptom;
    defaultData.config.driftDuration = driftDuration;
    defaultData.config.recoverDuration = recoverDuration;

    this.props.onConfigChange(defaultData);
  }

  onSymptomTypeChange(newSymptomType) {
    const {
      config: {
        symptom: { type },
      },
    } = this.props.defaultData;

    if (type !== newSymptomType) {
      this.onSymptomTypeUpdate(newSymptomType);
    }
  }

  onMonitorStatusChange(newState) {
    const { defaultData } = this.props;
    if (defaultData.config.isLive === newState) {
      return;
    }

    defaultData.config.isLive = newState;

    this.props.onConfigChange(defaultData);
  }

  onIsBetaChange() {
    const {
      config: {
        symptom: { type },
      },
    } = this.props.defaultData;

    if (type !== SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA) {
      this.onSymptomTypeUpdate(SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA);
    } else {
      this.onSymptomTypeUpdate(SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS);
    }
  }

  onIsTrendChange() {
    const {
      config: {
        symptom: { type },
      },
    } = this.props.defaultData;

    if (type !== SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND) {
      this.onSymptomTypeUpdate(SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND);
    } else {
      this.onSymptomTypeUpdate(SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS);
    }
  }
  onSliceAlertConfigDelete(index) {
    const { defaultData } = this.props;
    const oldSliceAlertConfigs = defaultData.config.sliceAlertConfigs || [];
    const newSliceAlertConfigs = [...oldSliceAlertConfigs];
    newSliceAlertConfigs.splice(index, 1);
    defaultData.config = {
      ...defaultData.config,
      sliceAlertConfigs: newSliceAlertConfigs,
    };
    this.props.onConfigChange(defaultData);
  }
  onSliceAlertConfigUpdate(updates, index) {
    const { defaultData } = this.props;
    const oldSliceAlertConfigs = defaultData.config.sliceAlertConfigs || [];

    let newSliceAlertConfigs;
    if (index >= 0) {
      newSliceAlertConfigs = [...oldSliceAlertConfigs];
      newSliceAlertConfigs[index] = updates;
    } else {
      newSliceAlertConfigs = [...oldSliceAlertConfigs, updates];
    }

    defaultData.config = {
      ...defaultData.config,
      sliceAlertConfigs: newSliceAlertConfigs,
    };
    this.props.onConfigChange(defaultData);
  }

  onLiveFilterStartTimeChange(newLiveStartTime) {
    const { defaultData } = this.props;

    const {
      config: { liveStartTs },
    } = defaultData;
    const newLiveStartTs = getUnixTime(newLiveStartTime);
    if (liveStartTs !== newLiveStartTs) {
      defaultData.config.liveStartTs = newLiveStartTs;
      this.props.onConfigChange(defaultData);
    }
  }

  onAlertChannelChange(newAlertConfig) {
    const { defaultData } = this.props;
    defaultData.config.alertConfig = newAlertConfig;
    this.props.onConfigChange(defaultData);
  }

  getBasicConfigComponent() {
    const {
      defaultData: {
        metadata: { name, idSerial },
        config: {
          metrics = [],
          symptom = {},
          liveStartTs,
          alertConfig = { isMuted: false, mutingSchedules: [], channels: [] },
          isLive,
        },
      },
      dataSourceList,
      integrationList,
      scheduleList,
      kpiList,
      isEdit,
      isClone,
      enableModifyFilter,
    } = this.props;

    const { type = "" } = symptom;

    const dataSourceUuidToInfoMapper = getObjectUuidToInfoMapper(dataSourceList, false);
    const selectedKpi = metrics.length > 0 ? metrics[0] : "";
    const allKpiListOptions = [];
    const kpiListOptionsPerMetricTypeMapper = {};
    let kpiInfo = null;
    for (let currentKpi of kpiList) {
      const currentKpiOption = {
        label: getKPIDisplayName(
          currentKpi,
          dataSourceUuidToInfoMapper[currentKpi.config.sources[0]] || null
        ),
        value: currentKpi.metadata.uuid,
      };

      if (currentKpi.metadata.uuid === selectedKpi) {
        kpiInfo = currentKpi;
      }
      allKpiListOptions.push(currentKpiOption);
      const currentMetricType = getAutoMetricTypeFromKPI(currentKpi);
      if (!isEdit && isActivityMetric(currentKpi)) {
        continue;
      }

      if (!kpiListOptionsPerMetricTypeMapper.hasOwnProperty(currentMetricType)) {
        kpiListOptionsPerMetricTypeMapper[currentMetricType] = [];
      }
      kpiListOptionsPerMetricTypeMapper[currentMetricType].push(currentKpiOption);
    }

    let kpiListOption;
    const autoMetricType = getAutoMetricTypeFromKPI(kpiInfo ? kpiInfo : "");
    if (isClone) {
      kpiListOption = kpiListOptionsPerMetricTypeMapper[autoMetricType] || [];
    } else {
      kpiListOption = allKpiListOptions;
    }

    const symptomTypeInfo = getRuleSymptomInfo(type);
    const SymptomIcon = symptomTypeInfo ? symptomTypeInfo.icon : null;
    const description = symptomTypeInfo ? symptomTypeInfo.msg : "";
    const normalizedType = [
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
    ].includes(type)
      ? SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS
      : type;

    let normalizedSymptomTypeOptions;
    let isTrendDisabled =
      !!isEdit || !!isClone || isTrendMonitorDisabled(kpiInfo || "");
    let isBetaDisabled = !!isEdit || !!isClone;
    if (
      [
        AutoMetricsType.NUMERICAL_DISTRIBUTION,
        AutoMetricsType.CATEGORICAL_DISTRIBUTION,
      ].includes(autoMetricType)
    ) {
      normalizedSymptomTypeOptions = valuesOutOfExpectationsSymptomOptions;
      isBetaDisabled = true;
      isTrendDisabled = true;
    } else if (
      [AutoMetricsType.FULL_COMPARE, AutoMetricsType.AGGREGATION_COMPARE].includes(
        autoMetricType
      )
    ) {
      normalizedSymptomTypeOptions = thresholdSymptomOptions;
    } else {
      normalizedSymptomTypeOptions = symptomTypeOptions;
    }

    const { isMuted = false, channels = [], mutingSchedules = [] } = alertConfig;
    const alertChannelOptions = [];
    integrationList.forEach((currentAlertChannel) => {
      if (currentAlertChannel.mode === SubmitType.MANUAL) {
        return;
      }

      alertChannelOptions.push({
        label: `${getAlertingChannelDisplayType(currentAlertChannel.type)}: ${
          currentAlertChannel.title
        }`,
        value: currentAlertChannel.id,
      });
    });

    const scheduleOptions = scheduleList.map((currentSchedule) => {
      return {
        label: currentSchedule.title,
        value: currentSchedule.id,
      };
    });

    const monitorStatusOptions = [
      { label: "Live", value: true },
      { label: "Paused", value: false },
    ];

    return (
      <GroupPanelItem chunkSize={1}>
        {isEdit && <LabeledInput label="ID" disabled value={idSerial} />}
        <LabeledSelect
          label="Metrics"
          showSearch
          disabled={!!isEdit}
          value={selectedKpi}
          options={kpiListOption}
          enableSorting={true}
          onChange={this.onKpiChange}
        />
        {type && (
          <LabeledSelect
            label="Symptom to detect"
            disabled={!!isEdit || !!isClone}
            value={normalizedType}
            options={normalizedSymptomTypeOptions}
            onChange={this.onSymptomTypeChange}
          />
        )}
        {symptomTypeInfo && (
          <div className="rule-analysis-type-info-container lightup-horizon-flex-container">
            <SymptomIcon height={24} width={24} />
            {description}
          </div>
        )}
        {[
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS,
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
        ].includes(type) && (
          <div className="rule-is-trend-container">
            <NgToggleCheckbox
              disabled={isTrendDisabled}
              value={type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND}
              helpTooltip={"Account for trend will learn for metric trend changes."}
              onChange={this.onIsTrendChange}
              label={"Account for trend"}
              labelPosition={"left"}
              size={"mini"}
            />
          </div>
        )}
        {[
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS,
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
          SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
        ].includes(type) && (
          <div className="rule-is-beta-container">
            <NgToggleCheckbox
              disabled={isBetaDisabled}
              value={type === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA}
              helpTooltip={
                "The beta version should be considered experimental. Avoid using this for critical metrics."
              }
              onChange={this.onIsBetaChange}
              label={"Advanced forecasting"}
              labelPosition={"left"}
              size={"mini"}
            />
          </div>
        )}
        <LabeledInput label="Monitor name" value={name} onChange={this.onTitleChange} />
        <LabeledSelect
          label="Monitor status"
          value={isLive}
          options={monitorStatusOptions}
          onChange={this.onMonitorStatusChange}
        />
        <div className="rule-back-fill-container">
          <RuleBasicConfigInfoHeader
            icon={<BackFillIcon />}
            text={
              "Backfill identifies how far in the past incidents will be detected, once the monitor goes live"
            }
          />
          <LabeledDatePicker
            label="Backfill incidents starting"
            showTime
            staticLabel
            value={typeof liveStartTs === "number" ? fromUnixTime(liveStartTs) : null}
            onChange={this.onLiveFilterStartTimeChange}
            disabled={isEdit}
          />
        </div>
        <div className="rule-alert-channel-container">
          <AlertConfigSection
            icon={<AlertChannelIcon />}
            text={
              "Manage alerts (Optional) - Where do you want to receive notifications about this monitor?"
            }
            disabled={!enableModifyFilter}
            value={channels.map(({ channelId }) => channelId)}
            placeholder={"Select"}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
            }
            options={alertChannelOptions}
            onChange={(newChannels) => {
              this.onAlertChannelChange({
                channels: newChannels.map((channelId) => {
                  return {
                    channelId,
                  };
                }),
                mutingSchedules,
                isMuted,
              });
            }}
          />
        </div>
        <div className="rule-alert-channel-container">
          <AlertConfigSection
            icon={<ScheduleIcon />}
            text={
              "Notification muting schedule (Optional) - Use schedules to control when notifications should be sent."
            }
            size="large"
            disabled={!enableModifyFilter}
            value={mutingSchedules}
            placeholder={"Select"}
            filterOption={true}
            options={scheduleOptions}
            onChange={(newMutingSchedules) => {
              this.onAlertChannelChange({
                isMuted,
                channels,
                mutingSchedules: newMutingSchedules,
              });
            }}
          />
        </div>
        <NgToggleCheckbox
          size="mini"
          disabled={!enableModifyFilter}
          value={isMuted}
          onChange={() => {
            const newIsMuted = !isMuted;
            this.onAlertChannelChange({
              channels: channels.map(({ channelId }) => {
                return {
                  channelId,
                };
              }),
              mutingSchedules,
              isMuted: newIsMuted,
            });
          }}
          label={"Mute notifications"}
          labelPosition={"left"}
        />
        <SliceOverrideAlerts
          metric={kpiInfo}
          sliceValueList={this.props.sliceValueList}
          alertChannelOptions={alertChannelOptions}
          scheduleOptions={scheduleOptions}
          onUpdate={this.onSliceAlertConfigUpdate}
          sliceAlertConfigs={this.props.defaultData?.config?.sliceAlertConfigs}
          onDelete={this.onSliceAlertConfigDelete}
        />
      </GroupPanelItem>
    );
  }

  render() {
    const steps = [
      {
        title: "Define",
        component: this.getBasicConfigComponent(),
        className: "kpi-wizard-basic-setting-define-step",
        skipIndex: true,
        description:
          "Define the basic settings for your monitor. These selections cannot be changed once the monitor is saved.",
      },
    ];

    return (
      <div className="rule-basic-config-tab">
        <StepContainer steps={steps} className="rule-basic-tab-step-container" />
      </div>
    );
  }
}
export default RuleBasicConfigTab;
