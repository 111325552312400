import React, { Component, createRef } from "react";
import { Skeleton } from "antd";
import ProfilerDataSourceZeroStatusView from "../../components/profiler/profiler-data-source-zero-status-view";
import ProfilerDataSourceNode from "./data-source/profiler-data-source-node";
import ProfilerDataSourceOverview from "./data-source/profiler-data-source-overview";
import ProfilerSchemaOverview from "./schema/profiler-schema-overview";
import ProfilerDataSourceTableOverview from "./table/profiler-data-source-table-overview";
import ProfilerConfirmationDialog from "./profiler-confirmation-dialog";
import ProfilerCustomMetricOverview from "./custom-metric/profiler-custom-metric-overview";
import ProfilerColumnOverview from "./column/profiler-column-overview";
import ProfilerIncidentListPage from "./profiler-incident-list-page";
import ProfilerSettingTableConfigTakeoverView from "./takeover/config/table/";
import ProfilerSchemaVirtualTableTakeoverView, {
  ProfilerSchemaVirtualTableTakeOverViewClassName,
} from "./takeover/schema/profiler-schema-virtual-table-takeover-view";
import ProfilerMetricQueryHistory from "./takeover/query-history";
import queryString from "query-string";
import { getColumnTypeCategory } from "../../utils/column";
import {
  DraftState,
  DraftType,
  MetricMode,
  MetricType,
  TakeoverWidth,
} from "../../utils/enums";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { debounced } from "../../utils/input";
import {
  findDatasourceTreeMetric,
  getSelectedNodeTypeAndId,
  isCustomMetricType,
  ProfilerTreeNodeType,
  showTableNode,
  treeData,
} from "./tree-data";
import { ProfilerSummaryTabKey, ProfilerTabOptionOperation } from "./utils";
import { indexBy } from "../../utils/iterables";
import { isDataProfileEnabled } from "../../utils/data-profiling";
import {
  PAGE,
  EVENT,
  trackEvent,
  getTableDetailProps,
  getColumnDetailProps,
  getMetricDetailProps,
} from "../../utils/telemetry";
import { defaultTabKey } from "./profiler-summary-tab-view";
import { canNavigate } from "../../hooks/useNavigationBlocker";
import { classesName } from "../../utils/css";
import { getApprovalConfig } from "../../utils/approvals";
import { isAutoMetric } from "../../utils/metric";
import DraftMetricSendApprovalModal from "../../components/metric/modals/draft-metric-send-approval-modal";
import { deepcopy } from "../../utils/objects";
import { toast } from "../../components/toast/toast";
import "./profiler.scss";
import SearchInput from "../../atom/SearchInput";

// How frequently to refresh metric data for status changes.
const METRIC_REFRESH_POLLING_INTERVAL = 20000;

// When the data profile is being generated, how often should we poll for updates?
export const DATA_PROFILE_POLLING_INVERVAL = 60000;

function scrollToSelectedNode() {
  let selectedItems = document.getElementsByClassName("ant-tree-node-selected");
  selectedItems &&
    selectedItems[0] &&
    selectedItems[0].scrollIntoView({
      block: "center",
      inline: "nearest",
    });
}

class Profiler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectNode: null,
      inputSearchValue: "",
      searchValue: "",
      profilerTreeData: {
        loading: true,
        data: [],
        dataSourceList: [],
        type: MetricType.AUTO,
      },
      profilerCustomMetricTreeData: {
        loading: true,
        data: [],
        dataSourceList: [],
      },
      locationSearchString: props.location.search,
      incidentPageConfig: null,
      workspaceUuid: "",
      deleteMetricConfirmationDialogTarget: null,
      deleteMonitorConfirmationDialogTarget: null,
      firstWorkspaceLoad: true,
      isTreeExpand: true,
      approvalConfig: null,
      metricToDeleteApproval: null,
    };

    this.handleSearchChange = debounced(() => {
      const newSearchValue =
        this.state.inputSearchValue.length > 1 ? this.state.inputSearchValue : "";
      this.setState({ searchValue: newSearchValue });
      Profiler.updateUrl(
        this.props,
        this.state.currentSelectNode,
        newSearchValue,
        this.state.tabKey
      );
    }, 800).bind(this);

    this.onCurrentSelectNodeChange = this.onCurrentSelectNodeChange.bind(this);
    this.onCurrentTabChange = this.onCurrentTabChange.bind(this);
    this.onDeleteMetricClick = this.onDeleteMetricClick.bind(this);
    this.onDeleteMetricConfirmed = this.onDeleteMetricConfirmed.bind(this);
    this.updateTableList = this.updateTableList.bind(this);
    this.onTabOptionsChange = this.onTabOptionsChange.bind(this);
    this.onTreeExpandChange = this.onTreeExpandChange.bind(this);
    this.onDeleteDraftConfirmed = this.onDeleteDraftConfirmed.bind(this);
    this.virtualTableTakeoverRef = createRef();
  }

  static updateUrl(
    props,
    currentSelectTreeNode,
    searchValue,
    tabKey = "",
    tabOptions = [],
    isPush = true
  ) {
    if (!currentSelectTreeNode) {
      return;
    }

    const { dataSource, tableInfo, schemaInfo, columnInfo, metric } =
      currentSelectTreeNode;
    let newQueryString = "";

    const queryStringArray = [];

    if (dataSource) {
      queryStringArray.push(`dataSourceUuid=${dataSource.metadata.uuid}`);
    }

    if (tableInfo) {
      queryStringArray.push(`tableUuid=${tableInfo.uuid}`);
    }

    if (schemaInfo) {
      queryStringArray.push(`schemaUuid=${schemaInfo.uuid}`);
    }

    if (columnInfo) {
      queryStringArray.push(`columnUuid=${columnInfo.uuid}`);
    }

    if (metric) {
      queryStringArray.push(`metricUuid=${metric.uuid}`);
    }

    if (searchValue) {
      queryStringArray.push(`searchValue=${searchValue}`);
    }

    if (tabKey) {
      queryStringArray.push(`tabKey=${tabKey}`);
    }

    if (tabOptions.length > 0) {
      queryStringArray.push(`tabOptions=${tabOptions.join(",")}`);
    }

    if (queryStringArray.length > 0) {
      newQueryString = `?${queryStringArray.join("&")}`;
    }

    const { location, history } = props;
    const newLocation = `${location.pathname}${newQueryString}`;
    // Update the URL when current render is done. Query params update will trigger another render process which can read the latest state value.
    setTimeout(
      () => (isPush ? history.push(newLocation) : history.replace(newLocation)),
      0
    );
  }

  static getParams(locationSearchString) {
    const queryParams = queryString.parse(locationSearchString);
    const dataSourceUuid = queryParams.dataSourceUuid || "";
    const schemaUuid = queryParams.schemaUuid || "";
    const schemaName = queryParams.schemaName || "";
    const tableUuid = queryParams.tableUuid || "";
    const columnUuid = queryParams.columnUuid || "";
    const metricUuid = queryParams.metricUuid || "";
    const tabKey = queryParams.tabKey || "";
    const tabOptions = queryParams.tabOptions || "";
    const searchValue = queryParams.searchValue || "";
    const type = queryParams.type || MetricType.AUTO;

    return {
      dataSourceUuid,
      schemaUuid,
      schemaName,
      tableUuid,
      columnUuid,
      metricUuid,
      tabKey,
      tabOptions,
      type,
      searchValue,
    };
  }

  static getUpdatedStateFromSearchParams(
    props,
    state,
    locationSearchString,
    profilerTreeData,
    profilerCustomMetricTreeData,
    forceUpdateSelectNode = false
  ) {
    if (profilerTreeData.loading || profilerCustomMetricTreeData.loading) {
      return {};
    }

    const {
      dataSourceUuid,
      tableUuid,
      schemaUuid,
      schemaName,
      columnUuid,
      metricUuid,
      searchValue,
      tabKey,
      tabOptions,
    } = Profiler.getParams(locationSearchString);

    let currentSelectNode = null;
    if (dataSourceUuid) {
      currentSelectNode = {
        dataSource: null,
        schemaInfo: null,
        tableInfo: null,
        columnInfo: null,
        metric: null,
      };
      currentSelectNode.dataSource = profilerTreeData.data.find(
        (source) => source.metadata.uuid === dataSourceUuid
      );
      if ((schemaUuid || schemaName) && currentSelectNode.dataSource) {
        currentSelectNode.schemaInfo = currentSelectNode.dataSource.schemas.find(
          (schema) => schema.uuid === schemaUuid || schema.name === schemaName
        );
      }
      if (tableUuid && currentSelectNode.schemaInfo) {
        currentSelectNode.tableInfo = currentSelectNode.schemaInfo.tables.find(
          (table) => table.uuid === tableUuid && showTableNode(table)
        );
      }
      if (columnUuid && currentSelectNode.tableInfo) {
        currentSelectNode.columnInfo = currentSelectNode.tableInfo.columns.find(
          (column) => column.uuid === columnUuid
        );
      }
      if (metricUuid) {
        currentSelectNode.metric = findDatasourceTreeMetric(
          currentSelectNode.dataSource,
          metricUuid
        );
      }
    }

    if (!currentSelectNode && profilerTreeData.data.length > 0) {
      currentSelectNode = {
        dataSource: profilerTreeData.data[0],
        tableInfo: null,
        schemaInfo: null,
        columnInfo: null,
        metric: null,
      };
    }

    const updatedStateObject = {};
    if (
      forceUpdateSelectNode ||
      !state.currentSelectNode ||
      state.currentSelectNode.dataSource?.metadata?.uuid !==
        currentSelectNode.dataSource?.metadata?.uuid ||
      state.currentSelectNode.schemaInfo?.uuid !== currentSelectNode.schemaInfo?.uuid ||
      state.currentSelectNode.tableInfo?.uuid !== currentSelectNode.tableInfo?.uuid ||
      state.currentSelectNode.columnInfo?.uuid !== currentSelectNode.columnInfo?.uuid ||
      (state.currentSelectNode.metric?.metadata?.uuid ||
        state.currentSelectNode.metric?.uuid) !==
        (currentSelectNode.metric?.metadata?.uuid || currentSelectNode.metric?.uuid)
    ) {
      if (!currentSelectNode?.tableInfo && state.currentSelectNode?.tableInfo) {
        // selected table node is removed
        updatedStateObject.tabKey = ProfilerSummaryTabKey.SUMMARY;
      }
      updatedStateObject.currentSelectNode = currentSelectNode;
    }

    if (state.searchValue !== searchValue) {
      updatedStateObject.searchValue = searchValue;
    }

    if (state.tabKey !== tabKey && !("tabKey" in updatedStateObject)) {
      updatedStateObject.tabKey = tabKey;
    }

    if (state.tabOptions?.toString() !== tabOptions.toString()) {
      updatedStateObject.tabOptions = tabOptions !== "" ? tabOptions.split(",") : [];
    }

    return updatedStateObject;
  }

  static getDerivedStateFromProps(props, state) {
    if (!canNavigate({ noConfirm: true })) {
      return state;
    }

    const {
      profilerTreeData,
      profilerCustomMetricTreeData,
      match: {
        params: { workspaceUuid },
      },
      location: { search: locationSearchString },
    } = props;

    if (workspaceUuid !== state.workspaceUuid) {
      props.getIntegrationList(workspaceUuid);
      props.getProfilerTreeData(workspaceUuid);
      props.getTagList(workspaceUuid);

      const currentSelectNode = null;
      const searchValue = "";
      const tabKey = null;
      const currentWorkspace = props.workspaceList?.find(
        ({ uuid }) => uuid === workspaceUuid
      );
      const approvalConfig = getApprovalConfig(
        currentWorkspace,
        props.workspaceUserPermissions
      );

      Profiler.updateUrl(props, currentSelectNode, searchValue, tabKey, false);

      return {
        currentSelectNode,
        inputSearchValue: "",
        searchValue,
        profilerTreeData: {
          loading: true,
          data: [],
          dataSourceList: [],
        },
        profilerCustomMetricTreeData: {
          loading: true,
          data: [],
          dataSourceList: [],
        },
        metricLookup: {},
        incidentPageConfig: null,
        tabKey,
        workspaceUuid,
        approvalConfig,
      };
    } else if (locationSearchString !== state.locationSearchString) {
      const updatedStateObject = Profiler.getUpdatedStateFromSearchParams(
        props,
        state,
        locationSearchString,
        profilerTreeData,
        profilerCustomMetricTreeData
      );
      if (updatedStateObject.currentSelectNode) {
        console.log("current select node is updated");
      }

      return {
        ...state,
        ...updatedStateObject,
        locationSearchString,
      };
    } else if (
      profilerTreeData !== state.profilerTreeData ||
      profilerCustomMetricTreeData !== state.profilerCustomMetricTreeData
    ) {
      if (!profilerTreeData.loading && !profilerCustomMetricTreeData.loading) {
        // If tree data is updated, we always get new select node for it is difficult to know if underlying data of object is changed.
        const updatedStateObject = Profiler.getUpdatedStateFromSearchParams(
          props,
          state,
          locationSearchString,
          profilerTreeData,
          profilerCustomMetricTreeData,
          true
        );
        if (state.firstWorkspaceLoad) {
          // We've just finished loading the data. Need to give the tree a chance to render
          // before scrolling to the selected node.
          setTimeout(() => {
            scrollToSelectedNode();
          }, 0);
        }

        const metricLookup = indexBy(
          profilerCustomMetricTreeData.data,
          (metric) => metric.metadata.uuid
        );

        // Selected node is updated. Need to update url
        if (updatedStateObject.currentSelectNode) {
          Profiler.updateUrl(
            props,
            updatedStateObject.currentSelectNode,
            state.searchValue,
            updatedStateObject.tabKey || state.tabKey,
            updatedStateObject.tabOptions || state.tabOptions,
            false
          );
        }

        return {
          ...state,
          ...updatedStateObject,
          locationSearchString,
          inputSearchValue: updatedStateObject.searchValue || "",
          profilerTreeData,
          profilerCustomMetricTreeData,
          metricLookup,
          firstWorkspaceLoad: false,
        };
      }

      return {
        ...state,
        profilerTreeData,
        profilerCustomMetricTreeData,
      };
    }

    return null;
  }

  componentWillUnmount() {
    this.props.resetProfilerTreeData();
  }
  componentDidUpdate() {
    const searchString = this.props.history.location.search || "";
    const searchparams = new URLSearchParams(searchString);
    const tabKey = searchparams.get("tabKey");

    if (tabKey && this.state.tabKey !== tabKey) {
      this.setState((prevState) => ({ ...prevState, tabKey }));
    }
  }
  onOpenTableConfig(workspaceUuid, enableModifyMetric, configParams) {
    const outsideClickWrap = {};
    this.props.openWorkspaceTakeover(
      <ProfilerSettingTableConfigTakeoverView
        workspaceUuid={workspaceUuid}
        config={configParams}
        closeTakeover={this.props.closeTakeover}
        enableEdit={enableModifyMetric}
        isEdit={configParams.tableList[0]?.profilerConfig?.enabled}
        onSave={async (tableList) => {
          await this.updateTableList(configParams.dataSource, tableList);
        }}
        outsideClickWrap={outsideClickWrap}
      />,
      TakeoverWidth.WIDE,
      () => outsideClickWrap?.fn()
    );
  }

  onOpenMetricQueryHistory(workspaceUuid, metric) {
    this.props.openWorkspaceTakeover(
      <ProfilerMetricQueryHistory
        workspaceUuid={workspaceUuid}
        metric={metric}
        closeTakeover={this.props.closeTakeover}
      />,
      TakeoverWidth.EXTENDED,
      () => this.props.closeTakeover()
    );
  }

  onCreateTable(workspaceUuid, dataSource, schema) {
    this.props.openWorkspaceTakeover(
      <ProfilerSchemaVirtualTableTakeoverView
        closeTakeover={this.props.closeTakeover}
        dataSource={dataSource}
        schema={schema}
        defaultValue={null}
        onSave={(newVirtualTable) => {
          return this.props.createProfilerVirtualTable(
            workspaceUuid,
            dataSource,
            {
              ...newVirtualTable,
              schemaUuid: schema.uuid,
            },
            true
          );
        }}
        workspaceUuid={workspaceUuid}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
        isEdit={false}
        ref={this.virtualTableTakeoverRef}
      />,
      TakeoverWidth.FULLSCREEN,
      () => this.virtualTableTakeoverRef.current?.onCancelClicked(),
      ProfilerSchemaVirtualTableTakeOverViewClassName
    );
  }

  onEditTable(workspaceUuid, dataSource, schema, table) {
    this.props.openWorkspaceTakeover(
      <ProfilerSchemaVirtualTableTakeoverView
        closeTakeover={this.props.closeTakeover}
        dataSource={dataSource}
        schema={schema}
        defaultValue={table}
        onSave={(newVirtualTable) => {
          return this.updateTableList(dataSource, [newVirtualTable]);
        }}
        workspaceUuid={workspaceUuid}
        workspaceUserPermissions={this.props.workspaceUserPermissions}
        isEdit={true}
        ref={this.virtualTableTakeoverRef}
      />,
      TakeoverWidth.FULLSCREEN,
      () => this.virtualTableTakeoverRef.current?.onCancelClicked(),
      ProfilerSchemaVirtualTableTakeOverViewClassName
    );
  }

  updateTableActivityLog(workspaceUuid, dataSourceUuid, updatedTable) {
    const {
      currentSelectNode: { tableInfo },
    } = this.state;
    if (updatedTable?.uuid !== tableInfo?.uuid) {
      return;
    }

    this.props.getProfilerTableActivityLog(
      workspaceUuid,
      dataSourceUuid,
      updatedTable?.uuid
    );
  }

  updateTableList(dataSource, tableList, isOptimisticUpdate = false) {
    const paramsList = tableList.map(({ uuid, profilerConfig, status }) => {
      return {
        uuid,
        profilerConfig,
        status,
      };
    });

    return this.props
      .updateProfilerConfigTableList(this.state.workspaceUuid, dataSource, paramsList, {
        isRefreshTree: true,
        isOptimisticUpdate,
      })
      .then((response) => {
        // This function call handles a special case where the updated activity logs are being fetched on virtual table update
        this.updateTableActivityLog(
          this.state.workspaceUuid,
          dataSource.metadata.uuid,
          paramsList[0]
        );
        return response;
      });
  }

  onCreateCustomMetric(workspaceUuid, dataSource, schema, table, column) {
    const nextUrlPath = getURIInstance(URIPath.ADD_METRIC, { workspaceUuid });
    const isUserDefinedTable = table?.uuid && table?.isUserDefined;
    const queryArgs = queryString.stringify({
      dataSourceUuid: dataSource.metadata.uuid,
      dataSourceName: dataSource.metadata.name,
      schemaUuid: schema?.uuid,
      schemaName: schema?.name,
      tableUuid: table?.uuid,
      tableName: table?.tableName,
      columnUuid: column?.uuid,
      columnName: column?.columnName,
      columnType: getColumnTypeCategory(column),
      returnTo: "profilerMetricNode",
      ...(isUserDefinedTable ? { isUserDefinedTable } : {}),
    });
    const nextUrl = `${nextUrlPath}?${queryArgs}`;
    this.props.history.push(nextUrl);
  }

  onDeleteMetricClick(metric, numRules) {
    const { approvalConfig } = this.state;

    if (approvalConfig?.canCreateDraft && !isAutoMetric(metric)) {
      this.setState({
        metricToDeleteApproval: metric,
      });
    } else {
      this.setState({
        deleteMetricConfirmationDialogTarget: { metric, numRules },
      });
    }
  }

  onDeleteMetricConfirmed() {
    const {
      workspaceUuid,
      deleteMetricConfirmationDialogTarget: { metric },
      currentSelectNode,
    } = this.state;
    // Select the parent node of the metric we're going to delete.
    const newSelectNode = {
      ...currentSelectNode,
      metric: null,
    };

    trackEvent(
      EVENT.DELETE_METRIC,
      getMetricDetailProps(metric, currentSelectNode?.dataSource || {})
    );

    this.props.deleteKpi(workspaceUuid, metric).then(() => {
      this.setState({
        deleteMetricConfirmationDialogTarget: null,
        currentSelectNode: newSelectNode,
      });
      this.props.getProfilerTreeData(workspaceUuid);
    });
  }

  onDeleteDraftConfirmed(draftMetric) {
    const { workspaceUuid } = this.state;

    const deleteDraftMetric = deepcopy(draftMetric);
    deleteDraftMetric.draftMetadata = {
      ...deleteDraftMetric.draftMetadata,
      state: DraftState.APPROVAL_PENDING,
      type: DraftType.DELETE,
      targetUuid: deleteDraftMetric.metadata.uuid,
    };
    deleteDraftMetric.metadata.uuid = null;
    deleteDraftMetric.mode = MetricMode.DRAFT;

    this.props
      .addKpi(workspaceUuid, deleteDraftMetric)
      .then((data) => {
        if (data) {
          toast("Delete draft created successfully.");
        }
      })
      .finally(() => {
        this.setState({
          metricToDeleteApproval: null,
        });
      });
  }

  onCurrentSelectNodeChange(currentSelectNode, tabKey = null) {
    if (!canNavigate()) {
      return;
    }

    const { workspaceUserPermissions } = this.props;
    const newNodeType = getSelectedNodeTypeAndId(currentSelectNode)[0];
    const newTabKey =
      tabKey ??
      defaultTabKey(newNodeType, { ...currentSelectNode, workspaceUserPermissions });
    this.setState({
      currentSelectNode,
      tabKey: newTabKey,
    });
    Profiler.updateUrl(
      this.props,
      currentSelectNode,
      this.state.searchValue,
      newTabKey
    );
  }

  onCurrentTabChange(newTabKey) {
    if (!canNavigate()) {
      return;
    }

    const { tabKey, currentSelectNode, searchValue } = this.state;
    if (tabKey === newTabKey) {
      return;
    }

    const selectedNode = this.state.currentSelectNode;

    const eventProps = selectedNode?.columnInfo
      ? {
          ...getColumnDetailProps(
            selectedNode.columnInfo,
            selectedNode.schemaInfo,
            selectedNode.dataSource
          ),
          page: PAGE.EXPLORER_TABLE_AUTO_METRICS,
        }
      : {
          ...getTableDetailProps(
            selectedNode.tableInfo,
            selectedNode.schemaInfo,
            selectedNode.dataSource
          ),
          page: PAGE.EXPLORER_TABLE,
        };

    switch (newTabKey) {
      case ProfilerSummaryTabKey.HEALTH:
        trackEvent(EVENT.SELECT_TABLE_HEALTH_TAB, eventProps);
        break;
      case ProfilerSummaryTabKey.DATA_PROFILE:
        trackEvent(EVENT.SELECT_DATA_PROFILE_TAB, eventProps);
        break;
      case ProfilerSummaryTabKey.AUTO_METRICS:
        trackEvent(EVENT.SELECT_AUTO_METRICS_TAB, eventProps);
        break;
      case ProfilerSummaryTabKey.MANAGE_COLUMNS:
        trackEvent(EVENT.MANAGE_COLUMNS, eventProps);
        break;
      default:
        console.warn(`Unrecognized tab key ${newTabKey}`);
    }

    this.setState({
      tabKey: newTabKey,
    });

    Profiler.updateUrl(this.props, currentSelectNode, searchValue, newTabKey);
  }

  onTabOptionsChange({ operation, options }) {
    const { tabKey, currentSelectNode, searchValue, tabOptions } = this.state;

    if (!Object.values(ProfilerTabOptionOperation).includes(operation)) {
      return;
    }

    const newTabOptions =
      operation === ProfilerTabOptionOperation.ADD
        ? [...tabOptions, ...options]
        : tabOptions.filter((option) => !options.includes(option));
    newTabOptions.sort();

    if (newTabOptions.toString() === tabOptions.toString()) {
      return;
    }

    this.setState({
      tabOptions: newTabOptions,
    });
    Profiler.updateUrl(
      this.props,
      currentSelectNode,
      searchValue,
      tabKey,
      newTabOptions
    );
  }

  onTreeExpandChange(isTreeExpand) {
    this.setState({
      isTreeExpand,
    });
  }

  render() {
    const {
      profilerTreeData,
      profilerCustomMetricTreeData,
      metricLookup,
      searchValue,
      incidentPageConfig,
      workspaceUuid,
      tabKey,
      tabOptions,
      isTreeExpand,
      approvalConfig,
      metricToDeleteApproval,
    } = this.state;
    let { currentSelectNode } = this.state;

    const {
      waffle,
      workspaceUserPermissions = {
        isSuperuser: true,
        permissions: [],
      },
    } = this.props;

    if (!!incidentPageConfig) {
      return (
        <ProfilerIncidentListPage
          config={incidentPageConfig}
          getCurrentIncidentList={this.props.getProfilerCurrentIncidentList.bind(
            this,
            workspaceUuid
          )}
          resetCurrentIncidentList={this.props.resetProfilerCurrentIncidentList}
          getCurrentIncidentMetricData={this.props.getProfilerCurrentIncidentMetricData.bind(
            this,
            workspaceUuid
          )}
          currentMetricsData={this.props.profilerCurrentIncidentMetricsData}
          currentIncidentList={this.props.profilerCurrentIncidentList}
          onIncidentStatusChanged={this.props.updateProfilerIncidentStatus.bind(
            this,
            workspaceUuid
          )}
          userInfo={this.props.userInfo}
          onBack={() => this.setState({ incidentPageConfig: null })}
          workspaceUserPermissions={workspaceUserPermissions}
        />
      );
    }

    if (profilerTreeData.loading || profilerCustomMetricTreeData.loading) {
      return (
        <div className="profiler-skeleton-container">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </div>
      );
    }

    const [treeDataBySource, treeNodeIdMapper] = profilerTreeData.data.reduce(
      ([treeDataMap, idMap], dataSource) => {
        const dataSourceUuid = dataSource.metadata.uuid;
        const [dsTreeData, dsIdMap] = treeData(
          dataSource,
          workspaceUserPermissions,
          metricLookup
        );
        treeDataMap[dataSourceUuid] = dsTreeData;
        Object.assign(idMap, dsIdMap);
        return [treeDataMap, idMap];
      },
      [{}, {}]
    );
    const availableNodeKeys = new Set(Object.keys(treeNodeIdMapper));

    let currentSelectMetric;
    if (currentSelectNode?.metric) {
      // Retrieve the full metric information.
      currentSelectMetric = profilerCustomMetricTreeData.data.find(
        (metric) => metric.metadata.uuid === currentSelectNode.metric.uuid
      );
      // We may be missing the path context for the current metric, e.g. in case the user got here
      // via "View in Explorer". We can fetch the full context info from the tree data.
      currentSelectNode =
        treeNodeIdMapper[currentSelectNode.metric.uuid] ?? currentSelectNode;
    }

    const [selectedNodeType] = getSelectedNodeTypeAndId(currentSelectNode);

    const enableModifyMetric = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_STREAM_VIEWS_EDIT_STREAMDETAIL,
    ]);
    return (
      <div className="profiler-container lightup-vertical-flex-container">
        {profilerTreeData.dataSourceList &&
        profilerTreeData.dataSourceList.length === 0 ? (
          <div className="profiler-data-source-zero-status-view-outer-container lightup-horizon-flex-container">
            <ProfilerDataSourceZeroStatusView
              workspaceUuid={workspaceUuid}
              workspaceUserPermissions={workspaceUserPermissions}
            />
          </div>
        ) : (
          <div className="profiler-content-container lightup-horizon-flex-container lightup-rest-flex-item-container">
            <div
              className={classesName(
                "profiler-tree-navigator-outer-container",
                !isTreeExpand && "hidden"
              )}
            >
              <div className="profiler-tree-navigator-container lightup-vertical-flex-container">
                <div className="profiler-tree-search-container">
                  <SearchInput
                    value={this.state.inputSearchValue}
                    size="large"
                    onChange={(e) => {
                      this.setState({ inputSearchValue: e.target.value });
                      this.handleSearchChange();
                    }}
                  />
                </div>
                <div className="profiler-tree-datasource-list-outer-container lightup-rest-flex-item-container">
                  <div className="profiler-tree-datasource-list-container">
                    {profilerTreeData.data.map((profilerDataSource, index) => {
                      const dsUuid = profilerDataSource.metadata.uuid;
                      const dsTreeData = treeDataBySource[dsUuid];
                      return (
                        <ProfilerDataSourceNode
                          workspaceUuid={workspaceUuid}
                          history={this.props.history}
                          value={profilerDataSource}
                          dataSourceTreeData={dsTreeData}
                          idMapper={treeNodeIdMapper}
                          key={index}
                          selectedNode={currentSelectNode}
                          searchValue={searchValue}
                          onSelect={this.onCurrentSelectNodeChange}
                          workspaceUserPermissions={workspaceUserPermissions}
                          onCreateCustomMetric={this.onCreateCustomMetric.bind(
                            this,
                            workspaceUuid
                          )}
                          setProfilerAfterTreeNavigationAction={
                            this.props.setProfilerAfterTreeNavigationAction
                          }
                          onCreateTable={this.onCreateTable.bind(this, workspaceUuid)}
                          onEditTable={this.onEditTable.bind(this, workspaceUuid)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="profiler-result-outer-container lightup-rest-flex-item-container">
              <div className="profiler-result-container">
                {selectedNodeType === ProfilerTreeNodeType.DATASOURCE && (
                  <ProfilerDataSourceOverview
                    key={currentSelectNode.dataSource.metadata.uuid}
                    tabKey={tabKey}
                    onTabChange={this.onCurrentTabChange}
                    dataSource={currentSelectNode.dataSource}
                    isTreeExpand={isTreeExpand}
                    onTreeExpandChange={this.onTreeExpandChange}
                    availableNodeKeys={availableNodeKeys}
                    alertingChannelList={this.props.alertingChannelList}
                    refreshTreeDataSourceNode={this.props.refreshTreeDataSourceNode.bind(
                      this,
                      workspaceUuid
                    )}
                    dataSourceAllTableList={this.props.dataSourceAllTableList}
                    getProfilerConfigTableList={this.props.getProfilerConfigTableList.bind(
                      this,
                      workspaceUuid
                    )}
                    onCreateCustomMetric={this.onCreateCustomMetric.bind(
                      this,
                      workspaceUuid
                    )}
                    currentDataSourceSummary={
                      this.props.profilerCurrentDataSourceSummary
                    }
                    getProfilerCurrentDataSourceSummary={this.props.getProfilerCurrentDataSourceSummary.bind(
                      this,
                      workspaceUuid
                    )}
                    resetProfilerCurrentDataSourceSummary={
                      this.props.resetProfilerCurrentDataSourceSummary
                    }
                    updateSystemProfileDataSourceRuleAlertingChannel={this.props.updateSystemProfileDataSourceRuleAlertingChannel.bind(
                      this,
                      workspaceUuid
                    )}
                    profilerCurrentMonitorObject={
                      this.props.profilerCurrentMonitorObject
                    }
                    getProfilerCurrentMonitorObject={this.props.getProfilerCurrentMonitorObject.bind(
                      this,
                      workspaceUuid
                    )}
                    monitorAllForDataSource={this.props.monitorAllForDataSource.bind(
                      this,
                      workspaceUuid
                    )}
                    toggleKpiLiveStatus={this.props.toggleKpiLiveStatus}
                    workspaceUserPermissions={workspaceUserPermissions}
                    workspaceUuid={workspaceUuid}
                    updateTableList={this.updateTableList.bind(
                      this,
                      currentSelectNode.dataSource
                    )}
                    currentSelectNode={currentSelectNode}
                    onCurrentSelectNodeChange={this.onCurrentSelectNodeChange}
                    profilerAfterTreeNavigationAction={
                      this.props.profilerAfterTreeNavigationAction
                    }
                    setProfilerAfterTreeNavigationAction={
                      this.props.setProfilerAfterTreeNavigationAction
                    }
                    waffle={waffle}
                  />
                )}
                {selectedNodeType === ProfilerTreeNodeType.SCHEMA && (
                  <ProfilerSchemaOverview
                    workspaceUuid={workspaceUuid}
                    tabKey={tabKey}
                    onTabChange={this.onCurrentTabChange}
                    isTreeExpand={isTreeExpand}
                    onTreeExpandChange={this.onTreeExpandChange}
                    availableNodeKeys={availableNodeKeys}
                    alertingChannelList={this.props.alertingChannelList}
                    dataSourceTableList={this.props.dataSourceTableList}
                    dataSource={currentSelectNode.dataSource}
                    schemaInfo={currentSelectNode.schemaInfo}
                    refreshTreeDataSourceNode={this.props.refreshTreeDataSourceNode.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerConfigDataSourceTableList={this.props.getProfilerConfigDataSourceTableList.bind(
                      this,
                      workspaceUuid
                    )}
                    onCreateCustomMetric={this.onCreateCustomMetric.bind(
                      this,
                      workspaceUuid
                    )}
                    currentSchemaSummary={this.props.profilerCurrentSchemaSummary}
                    metricRefreshInterval={METRIC_REFRESH_POLLING_INTERVAL}
                    getProfilerCurrentSchemaSummary={this.props.getProfilerCurrentSchemaSummary.bind(
                      this,
                      workspaceUuid
                    )}
                    resetProfilerCurrentSchemaSummary={
                      this.props.resetProfilerCurrentSchemaSummary
                    }
                    getProfilerConfigTableList={this.props.getProfilerConfigTableList.bind(
                      this,
                      workspaceUuid
                    )}
                    updateSystemProfileSchemaRuleAlertingChannel={this.props.updateSystemProfileSchemaRuleAlertingChannel.bind(
                      this,
                      workspaceUuid
                    )}
                    profilerCurrentMonitorObject={
                      this.props.profilerCurrentMonitorObject
                    }
                    getProfilerCurrentMonitorObject={this.props.getProfilerCurrentMonitorObject.bind(
                      this,
                      workspaceUuid
                    )}
                    monitorAllForSchema={this.props.monitorAllForSchema.bind(
                      this,
                      workspaceUuid
                    )}
                    onOpenTableConfig={this.onOpenTableConfig.bind(
                      this,
                      workspaceUuid,
                      enableModifyMetric
                    )}
                    onGoToIncidentPage={(incidentPageConfig) =>
                      this.setState({ incidentPageConfig })
                    }
                    updateTableList={this.updateTableList.bind(
                      this,
                      currentSelectNode.dataSource
                    )}
                    toggleKpiLiveStatus={this.props.toggleKpiLiveStatus}
                    workspaceUserPermissions={workspaceUserPermissions}
                    currentSelectNode={currentSelectNode}
                    onCurrentSelectNodeChange={this.onCurrentSelectNodeChange}
                    profilerAfterTreeNavigationAction={
                      this.props.profilerAfterTreeNavigationAction
                    }
                    setProfilerAfterTreeNavigationAction={
                      this.props.setProfilerAfterTreeNavigationAction
                    }
                    waffle={waffle}
                  />
                )}
                {selectedNodeType === ProfilerTreeNodeType.TABLE && (
                  <ProfilerDataSourceTableOverview
                    tabKey={tabKey}
                    onTabChange={this.onCurrentTabChange}
                    tabOptions={tabOptions}
                    onTabOptionsChange={this.onTabOptionsChange}
                    onSelectNodeChange={this.onCurrentSelectNodeChange}
                    availableNodeKeys={availableNodeKeys}
                    isTreeExpand={isTreeExpand}
                    onTreeExpandChange={this.onTreeExpandChange}
                    alertingChannelList={this.props.alertingChannelList}
                    dataSource={currentSelectNode.dataSource}
                    isDataProfileEnabled={isDataProfileEnabled(
                      currentSelectNode.dataSource
                    )}
                    schemaInfo={currentSelectNode.schemaInfo}
                    tableInfo={currentSelectNode.tableInfo}
                    tableColumnList={this.props.tableColumnList}
                    profilerCurrentTableAutoMetricsData={
                      this.props.profilerCurrentTableAutoMetricsData
                    }
                    profilerCurrentTableOverviewSummary={
                      this.props.profilerCurrentTableOverviewSummary
                    }
                    profilerCurrentTableHealthData={
                      this.props.profilerCurrentTableHealthData
                    }
                    profilerTableMetadataMetricsData={
                      this.props.profilerTableMetadataMetricsData
                    }
                    profilerTableDataProfileData={
                      this.props.profilerTableDataProfileData
                    }
                    metricRefreshInterval={METRIC_REFRESH_POLLING_INTERVAL}
                    getProfilerConfigTableColumnList={this.props.getProfilerConfigTableColumnList.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerTableColumnListDataProfileData={this.props.getProfilerTableColumnListDataProfileData.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerCurrentTableAutoMetricsData={this.props.getProfilerCurrentTableAutoMetricsData.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerCurrentTableOverviewSummary={this.props.getProfilerCurrentTableOverviewSummary.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerCurrentTableHealthData={this.props.getProfilerCurrentTableHealthData.bind(
                      this,
                      workspaceUuid
                    )}
                    getProfilerCurrentTableMetadataMetricsData={this.props.getProfilerCurrentTableMetadataMetricsData.bind(
                      this,
                      workspaceUuid
                    )}
                    updateProfilerTableAutoMetricsChartData={this.props.updateProfilerTableAutoMetricsChartData.bind(
                      this,
                      workspaceUuid
                    )}
                    updateProfilerTableMetadataMetricsChartData={this.props.updateProfilerTableMetadataMetricsChartData.bind(
                      this,
                      workspaceUuid
                    )}
                    resetProfilerCurrentTableSummary={
                      this.props.resetProfilerCurrentTableSummary
                    }
                    createSystemProfilerRule={this.props.createSystemProfilerRule.bind(
                      this,
                      workspaceUuid
                    )}
                    toggleSystemProfilerRuleStatus={this.props.toggleSystemProfilerRuleStatus.bind(
                      this,
                      workspaceUuid
                    )}
                    updateSystemProfileTableRuleAlertingChannel={this.props.updateSystemProfileTableRuleAlertingChannel.bind(
                      this,
                      workspaceUuid
                    )}
                    updateSystemProfileDataSourceTableRuleAlertingChannel={this.props.updateSystemProfileDataSourceTableRuleAlertingChannel.bind(
                      this,
                      workspaceUuid
                    )}
                    onGoToIncidentPage={(incidentPageConfig) =>
                      this.setState({ incidentPageConfig })
                    }
                    onCreateCustomMetric={this.onCreateCustomMetric.bind(
                      this,
                      workspaceUuid
                    )}
                    profilerCurrentMonitorObject={
                      this.props.profilerCurrentMonitorObject
                    }
                    getProfilerCurrentMonitorObject={this.props.getProfilerCurrentMonitorObject.bind(
                      this,
                      workspaceUuid
                    )}
                    monitorAllForTable={this.props.monitorAllForTable.bind(
                      this,
                      workspaceUuid
                    )}
                    triggerProfilerMetrics={this.props.triggerProfilerMetrics.bind(
                      this,
                      workspaceUuid
                    )}
                    onOpenTableConfig={this.onOpenTableConfig.bind(
                      this,
                      workspaceUuid,
                      enableModifyMetric
                    )}
                    updateTableConfig={(newTableInfo, isRefreshTree = true) => {
                      return this.props
                        .updateProfilerConfigTableList(
                          workspaceUuid,
                          currentSelectNode.dataSource,
                          [
                            {
                              uuid: newTableInfo.uuid,
                              profilerConfig: newTableInfo.profilerConfig,
                              status: newTableInfo.status,
                            },
                          ],
                          { isRefreshTree }
                        )
                        .then((allResponses) => {
                          const [firstResponse] = allResponses.data;
                          if (firstResponse.status_code === 200) {
                            this.setState({
                              currentSelectNode: {
                                ...currentSelectNode,
                                tableInfo: newTableInfo,
                              },
                            });
                          }
                          return allResponses;
                        });
                    }}
                    updateColumnConfig={(newColumnInfo) => {
                      return this.props.updateProfilerConfigColumnList(
                        workspaceUuid,
                        currentSelectNode.dataSource,
                        currentSelectNode.tableInfo,
                        [
                          {
                            uuid: newColumnInfo.uuid,
                            profilerConfig: newColumnInfo.profilerConfig,
                          },
                        ],
                        false
                      );
                    }}
                    userInfo={this.props.userInfo}
                    toggleKpiLiveStatus={this.props.toggleKpiLiveStatus}
                    deleteRule={this.props.deleteRule}
                    workspaceUserPermissions={workspaceUserPermissions}
                    workspaceUuid={workspaceUuid}
                    currentSelectNode={currentSelectNode}
                    onCurrentSelectNodeChange={this.onCurrentSelectNodeChange}
                    onDeleteMetricClick={this.onDeleteMetricClick}
                    profilerAfterTreeNavigationAction={
                      this.props.profilerAfterTreeNavigationAction
                    }
                    setProfilerAfterTreeNavigationAction={
                      this.props.setProfilerAfterTreeNavigationAction
                    }
                    createCustomMetricRule={this.props.createCustomMetricRule.bind(
                      this.props,
                      workspaceUuid
                    )}
                    onOpenMetricQueryHistory={this.onOpenMetricQueryHistory.bind(
                      this,
                      workspaceUuid
                    )}
                    waffle={waffle}
                    openTakeover={this.props.openWorkspaceTakeover}
                    closeTakeover={this.props.closeTakeover}
                  />
                )}
                {selectedNodeType === ProfilerTreeNodeType.COLUMN && (
                  <ProfilerColumnOverview
                    isDataProfileEnabled={isDataProfileEnabled(
                      currentSelectNode.dataSource
                    )}
                    waffle={waffle}
                    tabKey={tabKey}
                    onTabChange={this.onCurrentTabChange}
                    isTreeExpand={isTreeExpand}
                    onTreeExpandChange={this.onTreeExpandChange}
                    alertingChannelList={this.props.alertingChannelList}
                    dataSource={currentSelectNode.dataSource}
                    schemaInfo={currentSelectNode.schemaInfo}
                    tableInfo={currentSelectNode.tableInfo}
                    columnInfo={currentSelectNode.columnInfo}
                    metricRefreshInterval={METRIC_REFRESH_POLLING_INTERVAL}
                    profilerCurrentColumnMetricsData={
                      this.props.profilerCurrentColumnMetricsData
                    }
                    dataProfileData={this.props.profilerColumnDataProfileData}
                    getProfilerColumnCurrentMetricsData={this.props.getProfilerColumnCurrentMetricsData.bind(
                      this,
                      workspaceUuid
                    )}
                    createColumnSystemProfilerRule={this.props.createColumnSystemProfilerRule.bind(
                      this,
                      workspaceUuid
                    )}
                    toggleSystemProfilerRuleStatus={this.props.toggleSystemProfilerRuleStatus.bind(
                      this,
                      workspaceUuid
                    )}
                    updateProfilerColumnChartData={this.props.updateProfilerColumnChartData.bind(
                      this,
                      workspaceUuid
                    )}
                    updateProfilerColumnRuleAlertingChannel={this.props.updateProfilerColumnRuleAlertingChannel.bind(
                      this,
                      workspaceUuid
                    )}
                    updateProfilerColumnCategoryListChange={this.props.updateProfilerColumnCategoryListChange.bind(
                      this,
                      workspaceUuid
                    )}
                    updateColumnConfig={(newColumnInfo) => {
                      return this.props.updateProfilerConfigColumnList(
                        workspaceUuid,
                        currentSelectNode.dataSource,
                        currentSelectNode.tableInfo,
                        [
                          {
                            uuid: newColumnInfo.uuid,
                            profilerConfig: newColumnInfo.profilerConfig,
                          },
                        ]
                      );
                    }}
                    triggerProfilerMetrics={this.props.triggerProfilerMetrics.bind(
                      this,
                      workspaceUuid
                    )}
                    getDataProfileData={this.props.getProfilerColumnDataProfileData.bind(
                      this.props,
                      workspaceUuid
                    )}
                    resetProfilerColumn={this.props.resetProfilerColumn}
                    onGoToIncidentPage={(incidentPageConfig) =>
                      this.setState({ incidentPageConfig })
                    }
                    onCreateCustomMetric={this.onCreateCustomMetric.bind(
                      this,
                      workspaceUuid
                    )}
                    onGoToTable={() => {
                      const currentTableNode = {
                        ...currentSelectNode,
                        columnInfo: null,
                      };
                      this.onCurrentSelectNodeChange(
                        currentTableNode,
                        ProfilerSummaryTabKey.DATA_PROFILE
                      );
                    }}
                    profilerCurrentMonitorObject={
                      this.props.profilerCurrentMonitorObject
                    }
                    getProfilerCurrentMonitorObject={this.props.getProfilerCurrentMonitorObject.bind(
                      this,
                      workspaceUuid
                    )}
                    monitorAllForColumn={this.props.monitorAllForColumn.bind(
                      this,
                      workspaceUuid
                    )}
                    toggleKpiLiveStatus={this.props.toggleKpiLiveStatus}
                    deleteRule={this.props.deleteRule}
                    workspaceUserPermissions={workspaceUserPermissions}
                    workspaceUuid={workspaceUuid}
                    currentSelectNode={currentSelectNode}
                    onCurrentSelectNodeChange={this.onCurrentSelectNodeChange}
                    profilerAfterTreeNavigationAction={
                      this.props.profilerAfterTreeNavigationAction
                    }
                    setProfilerAfterTreeNavigationAction={
                      this.props.setProfilerAfterTreeNavigationAction
                    }
                    onOpenMetricQueryHistory={this.onOpenMetricQueryHistory.bind(
                      this,
                      workspaceUuid
                    )}
                  />
                )}
                {isCustomMetricType(selectedNodeType) && (
                  <ProfilerCustomMetricOverview
                    key={currentSelectMetric.metadata.uuid}
                    workspaceUuid={workspaceUuid}
                    alertingChannelList={this.props.alertingChannelList}
                    isTreeExpand={isTreeExpand}
                    onTreeExpandChange={this.onTreeExpandChange}
                    dataSource={currentSelectNode.dataSource}
                    schemaInfo={currentSelectNode.schemaInfo}
                    tableInfo={currentSelectNode.tableInfo}
                    columnInfo={currentSelectNode.columnInfo}
                    metric={currentSelectMetric}
                    profilerCurrentMetricMetricsData={
                      this.props.profilerCurrentMetricMetricsData
                    }
                    getProfilerCurrentMetricMetricsData={
                      this.props.getProfilerCurrentMetricMetricsData
                    }
                    resetProfilerCurrentMetricMetricsData={
                      this.props.resetProfilerCurrentMetricMetricsData
                    }
                    profilerCurrentMetricSliceValues={
                      this.props.profilerCurrentMetricSliceValues
                    }
                    getProfilerCurrentMetricSliceValue={
                      this.props.getProfilerCurrentMetricSliceValue
                    }
                    resetProfilerCurrentMetricSliceValue={
                      this.props.resetProfilerCurrentMetricSliceValue
                    }
                    createCustomMetricRule={this.props.createCustomMetricRule.bind(
                      this.props,
                      workspaceUuid
                    )}
                    toggleSystemProfilerRuleStatus={this.props.toggleSystemProfilerRuleStatus.bind(
                      this,
                      workspaceUuid
                    )}
                    updateCustomMetricRuleAlertingChannel={this.props.updateCustomMetricRuleAlertingChannel.bind(
                      this.props,
                      workspaceUuid
                    )}
                    triggerProfilerMetrics={this.props.triggerProfilerMetrics.bind(
                      this,
                      workspaceUuid
                    )}
                    resetProfilerCurrentMetricSummary={
                      this.props.resetProfilerCurrentMetricSummary
                    }
                    metricRefreshInterval={METRIC_REFRESH_POLLING_INTERVAL}
                    refreshProfilerTreeCustomMetric={
                      this.props.refreshProfilerTreeCustomMetric
                    }
                    onGoToIncidentPage={(incidentPageConfig) =>
                      this.setState({ incidentPageConfig })
                    }
                    toggleKpiLiveStatus={this.props.toggleKpiLiveStatus}
                    onDeleteMetricClick={this.onDeleteMetricClick}
                    deleteRule={this.props.deleteRule}
                    workspaceUserPermissions={workspaceUserPermissions}
                    currentSelectNode={currentSelectNode}
                    onCurrentSelectNodeChange={this.onCurrentSelectNodeChange}
                    onOpenMetricQueryHistory={this.onOpenMetricQueryHistory.bind(
                      this,
                      workspaceUuid
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.deleteMetricConfirmationDialogTarget && (
          <ProfilerConfirmationDialog
            modalIsOpen={Boolean(this.state.deleteMetricConfirmationDialogTarget)}
            setIsOpen={(isOpen) => {
              if (!isOpen) {
                this.setState({ deleteMetricConfirmationDialogTarget: null });
              }
            }}
            okClicked={this.onDeleteMetricConfirmed}
            usage={{
              loading: false,
              data: {
                totalMetrics: 1,
                totalRules: this.state.deleteMetricConfirmationDialogTarget?.numRules,
              },
            }}
            title="Delete metric"
            context={{
              kpi: this.state.deleteMetricConfirmationDialogTarget?.metric,
            }}
            defaultConfirmationMsg="You are about to delete 1 metric and associated monitors. This action is not reversible."
          />
        )}
        {Boolean(metricToDeleteApproval) && (
          <DraftMetricSendApprovalModal
            visible={Boolean(metricToDeleteApproval)}
            configData={metricToDeleteApproval}
            onConfirm={this.onDeleteDraftConfirmed}
            onCancel={() => this.setState({ metricToDeleteApproval: null })}
            integrationList={this.props.alertingChannelList}
            tagList={this.props.tagList}
            defaultNotificationChannels={approvalConfig?.alertChannels}
          />
        )}
      </div>
    );
  }
}

export default Profiler;
