export const RequestStatus = Object.freeze({
  UNKNOWN: 0,
  DOING: 1,
  SUCCESS: 2,
  FAILURE: 3,
  CANCELING: 4,
  CANCEL_SUCCESS: 5,
});

export const IncidentStatus = Object.freeze({
  NONE: 0,
  UNVIEWED: 1,
  VIEWED: 2,
  REJECTED: 3,
  SUBMITTED: 4,
  CLOSED: 5,
});

export const IncidentValidationStatus = Object.freeze({
  NOT_RUNNING: "notRunning",
  RUNNING: "running",
  ERROR: "error",
  RESOLVED: "resolved",
  UNRESOLVED: "unresolved",
  CANCELING: "canceling",
});

export function getIncidentValidationStatus(validation) {
  return validation ? validation.status : IncidentValidationStatus.NOT_RUNNING;
}

export const FilterTypeConst = Object.freeze({
  THRESHOLD: "threshold",
  CHANGEPOINT: "trendChangepoint", // Rename it to changepoint
  TOLERANCE_INTERVAL: "toleranceInterval",
});

export const FeatureTypeConst = Object.freeze({
  ROLLING_MEAN: "rollingMean",
  TREND_COEFFICIENT: "trendCoefficient",
  VALUE: "value",
  DOUBLE_DERIVATIVE: "doubleDerivative",
  ROLLING_NAN_PERCENTAGE: "rollingNanPercentage",
  ROLLING_COUNT: "rollingCount",
  FORECAST_ERROR: "forecastError",
  PERCENTAGE_CHANGE: "percentageChange",
  CHANGE: "change",
});

export const featureTypeDisplayNameMap = {
  [FeatureTypeConst.ROLLING_MEAN]: "Rolling mean",
  [FeatureTypeConst.TREND_COEFFICIENT]: "Trend coefficient",
  [FeatureTypeConst.VALUE]: "Value",
  [FeatureTypeConst.DOUBLE_DERIVATIVE]: "Double derivative",
  [FeatureTypeConst.ROLLING_NAN_PERCENTAGE]: "Rolling nan percentage",
  [FeatureTypeConst.ROLLING_COUNT]: "Rolling count",
  [FeatureTypeConst.FORECAST_ERROR]: "Forecast error",
  [FeatureTypeConst.PERCENTAGE_CHANGE]: "Percentage change",
  [FeatureTypeConst.CHANGE]: "Change",
};

export function getFeatureTypeDisplayName(feature) {
  if (feature === undefined) {
    return "Automatic";
  }

  return featureTypeDisplayNameMap[feature] || "N/A";
}

export const SymptomTypeConst = Object.freeze({
  VALUES_OUT_OF_EXPECTATIONS: "valueOutsideExpectations",
  VALUES_OUT_OF_EXPECTATIONS_BETA: "valueOutsideExpectationsBeta",
  VALUES_OUT_OF_EXPECTATIONS_WITH_TREND: "valueOutsideExpectationsWithTrend",
  SHARP_CHANGE: "sharpChange",
  SLOW_BURN_TREND_CHANGE: "slowBurnTrendChange",
  MANUAL_THRESHOLD: "manualThreshold",
});

const symptomTypeDisplayNameMap = {
  [SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS]: "Values out of expectation",
  [SymptomTypeConst.SHARP_CHANGE]: "Sharp change",
  [SymptomTypeConst.SLOW_BURN_TREND_CHANGE]: "Slow burn trend change",
  [SymptomTypeConst.MANUAL_THRESHOLD]: "Manual threshold",
};

export function getSymptomTypeDisplayName(type, featureType = "") {
  if (
    [
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA,
      SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND,
    ].includes(type)
  ) {
    return symptomTypeDisplayNameMap[SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS];
  }

  if (
    type === SymptomTypeConst.MANUAL_THRESHOLD &&
    [FeatureTypeConst.VALUE, FeatureTypeConst.PERCENTAGE_CHANGE].includes(featureType)
  ) {
    if (featureType === FeatureTypeConst.VALUE) {
      return "Value Threshold";
    } else {
      return "Percent Change Threshold";
    }
  }

  if (symptomTypeDisplayNameMap[type]) {
    return `${symptomTypeDisplayNameMap[type]} `;
  } else {
    console.log(`Fail to get symptom display name for ${type}`);
    return "N/A";
  }
}

export function getTypesAndFeaturesBySymptomType(symptomType) {
  let types = [FilterTypeConst.TOLERANCE_INTERVAL];
  let features = [];

  if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS) {
    features = [FeatureTypeConst.VALUE];
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_BETA) {
    features = [FeatureTypeConst.FORECAST_ERROR];
  } else if (symptomType === SymptomTypeConst.VALUES_OUT_OF_EXPECTATIONS_WITH_TREND) {
    features = [FeatureTypeConst.CHANGE, FeatureTypeConst.PERCENTAGE_CHANGE];
  } else if (symptomType === SymptomTypeConst.SHARP_CHANGE) {
    features = [FeatureTypeConst.DOUBLE_DERIVATIVE];
  } else if (symptomType === SymptomTypeConst.SLOW_BURN_TREND_CHANGE) {
    features = [FeatureTypeConst.TREND_COEFFICIENT];
    types = [FilterTypeConst.CHANGEPOINT];
  } else if (symptomType === SymptomTypeConst.MANUAL_THRESHOLD) {
    features = [
      FeatureTypeConst.VALUE,
      FeatureTypeConst.PERCENTAGE_CHANGE,
      FeatureTypeConst.CHANGE,
    ];
    types = [FilterTypeConst.THRESHOLD];
  } else {
    features = [];
    types = [];
  }

  return {
    features,
    types,
  };
}

const filterTypeDisplayNameMap = {
  [FilterTypeConst.THRESHOLD]: "threshold",
  [FilterTypeConst.CHANGEPOINT]: "change point",
  [FilterTypeConst.PREDICTION_INTERVAL]: "prediction interval",
  [FilterTypeConst.TOLERANCE_INTERVAL]: "tolerance interval",
};

export function getFilterTypeDisplayName(filterType) {
  if (filterTypeDisplayNameMap[filterType]) {
    return filterTypeDisplayNameMap[filterType];
  } else {
    return "N/A";
  }
}

export const SymptomConfigTypeConst = Object.freeze({
  AUTO: "auto",
  CONFIG: "config",
});

export const SeasonalityTypeConst = Object.freeze({
  AUTO: "Auto",
  NONE: "None",
  FIFTEEN_MIN_OF_DAY: "fifteen min of day",
  ONE_HOUR_OF_DAY: "one hour of day",
  EIGHT_HOUR_OF_DAY: "eight hour of day",
  FIFTEEN_MIN_OF_WEEK: "fifteen min of week",
  ONE_HOUR_OF_WEEK: "one hour of week",
  EIGHT_HOUR_OF_WEEK: "eight hour of week",
  ONE_DAY_OF_WEEK: "one day of week",
});

const seasonalityTypeDisplayNameMap = {
  [SeasonalityTypeConst.AUTO]: "Auto seasonality",
  [SeasonalityTypeConst.NONE]: "No Seasonality",
  [SeasonalityTypeConst.FIFTEEN_MIN_OF_DAY]: "Daily with 15 minute seasons",
  [SeasonalityTypeConst.ONE_HOUR_OF_DAY]: "Daily with 1 hour seasons",
  [SeasonalityTypeConst.EIGHT_HOUR_OF_DAY]: "Daily with 8 hour seasons",
  [SeasonalityTypeConst.FIFTEEN_MIN_OF_WEEK]: "Weekly with 15 minute seasons",
  [SeasonalityTypeConst.ONE_HOUR_OF_WEEK]: "Weekly with 1 hour seasons",
  [SeasonalityTypeConst.EIGHT_HOUR_OF_WEEK]: "Weekly with 8 hour seasons",
  [SeasonalityTypeConst.ONE_DAY_OF_WEEK]: "Weekly with 1 day seasons",
};

export function getSeasonalityDisplayName(seasonality) {
  if (seasonalityTypeDisplayNameMap[seasonality]) {
    return seasonalityTypeDisplayNameMap[seasonality];
  } else {
    return "N/A";
  }
}

export const NotificationTypeConst = Object.freeze({
  FAILURE: "failure",
  WARNING: "warning",
  SUCCESS: "success",
  INFO: "info",
});

export const RuleTabNameConst = Object.freeze({
  CONFIG: "config",
  TRAINING: "training",
  PREVIEW: "preview",
});

export const PodJobStatus = Object.freeze({
  NOT_AVAILABLE: "job_status_not_available_yet",
  UNKNOWN: "unknown",
  COMPLETE: "complete",
  SUCCESS: "success",
  FAILURE: "failed",
});

// Keep in sync with lumen/lux/lux/common/anomaly.py
export const IncidentDirection = Object.freeze({
  UNDEFINED: 0,
  UP: 1,
  DOWN: 2,
  BOTH: 3,
});

export const ConfigDirection = Object.freeze({
  UP: "up",
  DOWN: "down",
  BOTH: "both",
});

export const StreamDataStatus = Object.freeze({
  UNDEFINED: 0,
  NORMAL: 1,
  ANOMALY: 2,
  UNPROCESSED: 3,
});

export const WorkspaceUserRole = Object.freeze({
  ADMIN: "admin",
  VIEWER: "viewer",
  OBSERVER: "observer",
  EDITOR: "editor",
});

export const AppUserRole = Object.freeze({
  ADMIN: "app_admin",
  VIEWER: "app_viewer",
  EDITOR: "app_editor",
});

export const DataSourceType = Object.freeze({
  INCORTA: "incorta",
  POSTGRES: "postgres",
  REDSHIFT: "redshift",
  SNOWFLAKE: "snowflake",
  BIGQUERY: "bigquery",
  MYSQL: "mysql",
  ATHENA: "athena",
  DATABRICKS: "databricks",
  HIVE: "hive",
  MICROSOFTSQL: "microsoftsql",
  ORACLE: "oracle",
  TERADATA: "teradata",
  GREENPLUM: "greenplum",
  AZUREBLOBSTORAGE: "azureblobstorage",
  DREMIO: "dremio",
  S3BUCKET: "s3bucket",
  SAPHANA: "hana",
});

const dataSourceDisplayNameMapper = {
  [DataSourceType.POSTGRES]: "Postgres",
  [DataSourceType.REDSHIFT]: "Redshift",
  [DataSourceType.SNOWFLAKE]: "Snowflake",
  [DataSourceType.BIGQUERY]: "BigQuery",
  [DataSourceType.MYSQL]: "MySQL",
  [DataSourceType.ATHENA]: "Athena",
  [DataSourceType.DATABRICKS]: "Databricks",
  [DataSourceType.HIVE]: "Hive",
  [DataSourceType.MICROSOFTSQL]: "Microsoft SQL",
  [DataSourceType.ORACLE]: "Oracle",
  [DataSourceType.INCORTA]: "Incorta",
  [DataSourceType.TERADATA]: "Teradata",
  [DataSourceType.GREENPLUM]: "Greenplum",
  [DataSourceType.AZUREBLOBSTORAGE]: "Azure blob storage",
  [DataSourceType.DREMIO]: "Dremio",
  [DataSourceType.S3BUCKET]: "Amazon S3 bucket",
  [DataSourceType.SAPHANA]: "SAP Hana",
};

export function isFileSource(dataSourceType) {
  return [DataSourceType.AZUREBLOBSTORAGE, DataSourceType.S3BUCKET].includes(
    dataSourceType
  );
}

export function getDataSourceDisplayName(dataSourceType) {
  return dataSourceDisplayNameMapper[dataSourceType] || "N/A";
}

export const HiveAuthType = Object.freeze({
  NO_AUTH: "noAuth",
  KERBEROS: "kerberos",
  LDAP: "ldap",
});

export const OracleConnectionType = Object.freeze({
  DB_NAME: "dbName",
  SERVICE_NAME: "serviceName",
});

export const ScheduleType = Object.freeze({
  RECURRING: "recurring",
  STATIC: "static",
});

export const ScheduleRecurringType = Object.freeze({
  NONE: "",
  HOURLY: "hourly",
  DAILY: "daily",
  WEEKLY: "weekly",
});

export const MissingValueFillingType = Object.freeze({
  NULL: "null",
  ZERO: "zero",
});

export function coerceMissingValueFillingToNull(missingValueFilling) {
  return missingValueFilling ?? MissingValueFillingType.NULL;
}

export const AggregationType = Object.freeze({
  NULL_FRACTION: "nullFraction",
  COUNT: "count",
  SUM: "sum",
  MAX: "max",
  MIN: "min",
  COUNT_UNIQUE: "countUnique",
  AVG: "avg",
  STDDEV: "stddev",
  MEDIAN: "median",
  PERCENTILE: "percentile",
  DATA_DELAY: "dataDelay",
  VOLUME: "volume",
  CONFORMITY_COUNT: "conformityCount",
  MULTI_PERCENTILE: "multiPercentile",
  CATEGORICAL_DISTRIBUTION: "categoricalDistribution",
  BYTE_COUNT: "byteCount",
  ROW_COUNT: "rowCount",
  UPDATE_DELAY: "updateDelay",
  TABLE_ACTIVITY: "tableActivity",
  COLUMN_ACTIVITY: "columnActivity",
  CATEGORY_ACTIVITY: "categoryActivity",
});

// The definitions here need to match the definitions in the backend/lux (see
// DEFAULT_AGGREGATION_TO_MISSING_VALUE_FILLING_MAP in metric.py
export const DefaultAggregationMissingValueFilling = Object.freeze({
  // nan missing value filling
  [AggregationType.SUM]: MissingValueFillingType.NULL,
  [AggregationType.AVG]: MissingValueFillingType.NULL,
  [AggregationType.MAX]: MissingValueFillingType.NULL,
  [AggregationType.MIN]: MissingValueFillingType.NULL,
  [AggregationType.NULL_FRACTION]: MissingValueFillingType.NULL,
  [AggregationType.MEDIAN]: MissingValueFillingType.NULL,
  [AggregationType.STDDEV]: MissingValueFillingType.NULL,
  [AggregationType.PERCENTILE]: MissingValueFillingType.NULL,
  [AggregationType.MULTI_PERCENTILE]: MissingValueFillingType.NULL,
  // zero missing value filling
  [AggregationType.COUNT]: MissingValueFillingType.ZERO,
  [AggregationType.COUNT_UNIQUE]: MissingValueFillingType.ZERO,
  [AggregationType.CONFORMITY_COUNT]: MissingValueFillingType.ZERO,
  [AggregationType.VOLUME]: MissingValueFillingType.ZERO,
  // no missing value filling
  [AggregationType.CATEGORICAL_DISTRIBUTION]: null,
  [AggregationType.BYTE_COUNT]: null,
  [AggregationType.ROW_COUNT]: null,
  [AggregationType.UPDATE_DELAY]: null,
  [AggregationType.DATA_DELAY]: null,
});

export const SliceTrainingStatus = Object.freeze({
  TRAINED: "Trained",
  UNTRAINED: "Untrained",
  TRAINING_FAILED: "TrainingFailed",
});

export const MonitorTrainingStatus = Object.freeze({
  PAUSED_ON_SOURCE: "pausedSource",
  IN_PROGRESS: "inProgress",
  COMPLETE: "complete",
  FAILED: "failed",
  NOT_APPLICABLE: "notApplicable",
});

export const MonitorStatus = Object.freeze({
  NOT_MONITORED: "NotMonitored",
  MONITOR_STOPPED: "MonitorStopped",
  MONITORED: "Monitored",
  IN_PROCESS: "InProgress",
  FAILED: "Failed",
});

export const TableColumnTypeCategory = Object.freeze({
  STRING: "string",
  NUMERIC: "numeric",
  TIMESTAMP: "timestamp",
  BOOLEAN: "boolean",
  OTHER: "other",
});

export const DbColumType = Object.freeze({
  TIMESTAMP_TZ: "timestamp_tz",
  DATETIME: "datetime",
  DATE: "date",
});

export const EventItemType = Object.freeze({
  NONE: "none",
  NORMAL: "normal",
  INCIDENT: "incident",
  HIGH_IMPACT_INCIDENT: "high_impact_incident",
  UNPROCESSED: "unprocessed",
});

export function getObjKeyByValue(obj, val) {
  for (let key in obj) {
    if (obj[key] === val) {
      return key;
    }
  }

  return null;
}

export const IncidentScore = Object.freeze({
  HIGH: 1,
  NORMAL: 2,
});

export function getIncidentScore(score) {
  if (score <= 3) {
    return IncidentScore.HIGH;
  }

  return IncidentScore.NORMAL;
}

// @todo Clean up metric type with the help of backend.
export const MetricConfigType = Object.freeze({
  METRIC_CONFIG: "metricConfig",
  FULL_TABLE_METRIC_CONFIG: "fullTableMetricConfig",
  AGGREGATION_COMPARE_METRIC_CONFIG: "aggregationCompareMetricConfig",
  FULL_COMPARE_METRIC_CONFIG: "fullCompareMetricConfig",
});

export const MetricType = Object.freeze({
  AUTO: "auto",
  CUSTOM: "custom",
  METADATA: "metadata",
  DATA: "data",
  DATA_COMPARE: "dataCompare",
});

export const MetricCategory = Object.freeze({
  AGGREGATION: "Aggregation",
  CUSTOM_SQL: "CustomSql",
  DATA_DELAY: "dataDelay",
  DATA_VOLUME: "volume",
  FULL_COMPARE: "fullCompare",
  NULL_PERCENT: "nullPercent",
  AGGREGATION_COMPARE: "aggregationCompare",
  CONFORMITY_COUNT: "conformityCount",
  DISTRIBUTION: "distribution",
  BYTE_COUNT: "byteCount",
  ROW_COUNT: "rowCount",
  UPDATE_DELAY: "updateDelay",
  TABLE_ACTIVITY: "tableActivity",
  COLUMN_ACTIVITY: "columnActivity",
  CATEGORY_ACTIVITY: "categoryActivity",
  EVENT: "event",
});

export const MetricCategoryGroup = Object.freeze({
  TABLE_METRICS: "tableMetricsGroup",
  COLUMN_METRICS: "columnMetricsGroup",
  COMPARISON_METRICS: "comparisonMetricsGroup",
  SQL_METRICS: "sqlMetricsGroup",
});

export const AutoMetricsType = Object.freeze({
  DATA_DELAY: "dataDelay",
  MISSING_VALUE: "nullFraction",
  VOLUME: "volume",
  NUMERICAL_DISTRIBUTION: "multiPercentile",
  CATEGORICAL_DISTRIBUTION: "categoricalDistribution",
  CATEGORICAL_CARDINALITY: "categoricalCardinality", // remove
  CONFORMITY_COUNT: "conformityCount",
  FULL_COMPARE: "fullCompare",
  AGGREGATION_COMPARE: "aggregationCompare",
  BYTE_COUNT: "byteCount",
  ROW_COUNT: "rowCount",
  UPDATE_DELAY: "updateDelay",
  TABLE_ACTIVITY: "tableActivity",
  COLUMN_ACTIVITY: "columnActivity",
  CATEGORY_ACTIVITY: "categoryActivity",
  NONE: "none", // For custom metric
});

export const autoMetricTypeToMetricCategoryMap = Object.freeze({
  [AutoMetricsType.DATA_DELAY]: MetricCategory.DATA_DELAY,
  [AutoMetricsType.MISSING_VALUE]: MetricCategory.NULL_PERCENT,
  [AutoMetricsType.VOLUME]: MetricCategory.DATA_VOLUME,
  [AutoMetricsType.NUMERICAL_DISTRIBUTION]: MetricCategory.DISTRIBUTION,
  [AutoMetricsType.CATEGORICAL_DISTRIBUTION]: MetricCategory.DISTRIBUTION,
  [AutoMetricsType.CONFORMITY_COUNT]: MetricCategory.CONFORMITY_COUNT,
  [AutoMetricsType.FULL_COMPARE]: MetricCategory.FULL_COMPARE,
  [AutoMetricsType.AGGREGATION_COMPARE]: MetricCategory.AGGREGATION_COMPARE,
  [AutoMetricsType.BYTE_COUNT]: MetricCategory.BYTE_COUNT,
  [AutoMetricsType.ROW_COUNT]: MetricCategory.ROW_COUNT,
  [AutoMetricsType.UPDATE_DELAY]: MetricCategory.UPDATE_DELAY,
  [AutoMetricsType.TABLE_ACTIVITY]: MetricCategory.TABLE_ACTIVITY,
  [AutoMetricsType.COLUMN_ACTIVITY]: MetricCategory.COLUMN_ACTIVITY,
  [AutoMetricsType.CATEGORY_ACTIVITY]: MetricCategory.CATEGORY_ACTIVITY,
});

export const MetricDataAssetType = Object.freeze({
  DATASOURCE: "datasource",
  TABLE: "table",
  COLUMN: "column",
});

export const MetricTreeType = Object.freeze({
  CUSTOM: "custom",
  AUTO: "auto",
  CUSTOM_SQL: "customSql",
  ROW_BY_ROW_COMPARE: "rowByRowCompare",
  METRIC_COMPARE: "metricCompare",
});

export const IncidentType = Object.freeze({
  ANOMALY: "anomalyIncident",
  EVENT: "eventIncident",
});

export const IncidentCommentLogType = Object.freeze({
  USER_GENERATED: "userGenerated",
  SYSTEM_GENERATED: "systemGenerated",
});

export const IncidentCreatorType = Object.freeze({
  FILTER: "filter",
  EVENT: "event",
  SOURCE: "source_profiler",
  TABLE: "table_profiler",
  COLUMN: "column_profiler",
});

export const AggregationWindowType = Object.freeze({
  NONE: "",
  SECOND: "second",
  MINUTE: "minute",
  FIVE_MIN: "5min",
  TEN_MIN: "10min",
  FIFTEEN_MIN: "15min",
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
});

export const TableType = Object.freeze({
  TABLE: "table",
  CUSTOM_SQL: "customSql",
});

export const ConformityConditionType = Object.freeze({
  // For all types
  EQUAL: "equal",
  NOT_EQUAL: "notEqual",
  NULL: "null",
  NOT_NULL: "notNull",
  IS_UNIQUE: "isUnique",
  DATA_TYPE_MATCHES: "dataTypeMatches",
  IN: "in",
  NOT_IN: "notIn",
  IN_COLUMN: "inColumn",
  NOT_IN_COLUMN: "notInColumn",
  // for numeric, timestamp columns
  LESS_THAN: "lessThan",
  LESS_EQUAL_THAN: "lessEqualThan",
  GREATER_THAN: "greaterThan",
  GREATER_EQUAL_THAN: "greaterEqualThan",
  IS_INCREASING: "isIncreasing",
  IS_DECREASING: "isDecreasing",
  // string only
  LENGTH_EQUAL: "lengthEqual",
  LENGTH_GREATER_THAN: "lengthGreaterThan",
  LENGTH_LESS_THAN: "lengthLessThan",
  LENGTH_GREATER_EQUAL_THAN: "lengthGreaterEqualThan",
  LENGTH_LESS_EQUAL_THAN: "lengthLessEqualThan",
  CONTAIN_STRING_CASE_SENSITIVE: "containString",
  NOT_CONTAIN_STRING_CASE_SENSITIVE: "notContainString",
  MATCH_PATTERN_CASE_SENSITIVE: "matchPattern",
  NOT_MATCH_PATTERN_CASE_SENSITIVE: "notMatchPattern",
  MATCH_PATTERN_CASE_INSENSITIVE: "matchPatternCaseInsensitive",
  NOT_MATCH_PATTERN_CASE_INSENSITIVE: "notMatchPatternCaseInsensitive",
  MATCH_REGEX: "matchRegex",
  NOT_MATCH_REGEX: "notMatchRegex",
});

const conformityConditionTypeNameMap = {
  [ConformityConditionType.EQUAL]: "Equal",
  [ConformityConditionType.NOT_EQUAL]: "Not equal",
  [ConformityConditionType.NULL]: "Null",
  [ConformityConditionType.NOT_NULL]: "Not null",
  [ConformityConditionType.IN]: "In",
  [ConformityConditionType.NOT_IN]: "Not in",
  [ConformityConditionType.IN_COLUMN]: "In column",
  [ConformityConditionType.NOT_IN_COLUMN]: "Not in column",
  [ConformityConditionType.IS_UNIQUE]: "Is unique",
  [ConformityConditionType.LESS_THAN]: "Less than",
  [ConformityConditionType.LESS_EQUAL_THAN]: "Less than or equal to",
  [ConformityConditionType.GREATER_THAN]: "Greater than",
  [ConformityConditionType.GREATER_EQUAL_THAN]: "Greater than or equal to",
  [ConformityConditionType.LENGTH_EQUAL]: "Length equal to",
  [ConformityConditionType.LENGTH_GREATER_THAN]: "Length greater than",
  [ConformityConditionType.LENGTH_LESS_THAN]: "Length less than",
  [ConformityConditionType.LENGTH_GREATER_EQUAL_THAN]:
    "Length greater than or equal to",
  [ConformityConditionType.LENGTH_LESS_EQUAL_THAN]: "Length less than or equal to",
  [ConformityConditionType.IS_INCREASING]: "Is increasing",
  [ConformityConditionType.IS_DECREASING]: "Is decreasing",
  [ConformityConditionType.CONTAIN_STRING_CASE_SENSITIVE]: "Contains string",
  [ConformityConditionType.NOT_CONTAIN_STRING_CASE_SENSITIVE]:
    "Does not contain string",
  [ConformityConditionType.MATCH_PATTERN_CASE_SENSITIVE]: "Match pattern",
  [ConformityConditionType.NOT_MATCH_PATTERN_CASE_SENSITIVE]: "Does not match pattern",
  [ConformityConditionType.MATCH_REGEX]: "Match regex",
  [ConformityConditionType.NOT_MATCH_REGEX]: "Does not match regex",
};

export function getConformityConditionTypeDisplayName(conditionType) {
  return conformityConditionTypeNameMap[conditionType] || "";
}

export const IncidentCategoryType = Object.freeze({
  BEFORE: "BEFORE",
  OVERLAP: "OVERLAP",
  AFTER: "AFTER",
});

export const RunStatus = Object.freeze({
  OK: "ok",
  EXCEPTION: "exception",
  PAUSED: "paused",
  PAUSED_SOURCE: "pausedSource",
  PAUSED_TIMEOUTS: "pausedTimeouts",
  PAUSED_EXCESS_UNIQUE_CATEGORIES: "pausedExcessUniqueCategories",
  // Monitor statuses on the backend are in the same enum as metric statuses. Doing the same
  // thing here for consistency.
  RULE_PAUSED_TRAINING_INCOMPLETE: "pausedTraining",
  RULE_PAUSED_METRIC_PAUSE: "pausedMetric",
  RULE_PAUSED_TRAINING_FAILURE: "pausedTrainingFailure",
});

export const MetricStatus = {
  EXCEPTION: "exception",
  OK: "ok",
  PAUSED_BY_USER: "pausedByUser",
  PAUSED_EXCEPTION: "pausedException",
  PAUSED_EXCESS_UNIQUE_CATEGORIES: "pausedExcessUniqueCategories",
  PAUSED_EXCESS_UNIQUE_SLICES: "pausedExcessUniqueSlices",
  PAUSED_FULL_COMPARE_DATA_GREATER_THAN_ALLOWED:
    "pausedFullCompareDataGreaterThanAllowed",
  PAUSED_SOURCE: "pausedSource",
  PAUSED_TIMEOUTS: "pausedTimeouts",
  TIMEOUT: "timeout",
};

export const MonitorRunStatus = Object.freeze({
  EXCEPTION: "exception",
  LIVE: "live",
  LOADING: "loading",
  OK: "ok",
  PARTIALLY_LIVE: "partiallyLive",
  PAUSED_BY_USER: "pausedByUser",
  PAUSED_METRIC_PAUSE: "pausedMetric",
  PAUSED_TRAINING_FAILURE: "pausedTrainingFailure",
  PAUSED_TRAINING_FAILURE_INSUFFICIENT_DATA: "pausedTrainingFailureInsufficientData",
  PAUSED_SOURCE: "pausedSource",
  RESUMING: "resuming",
  TRAINING_IN_PROGRESS: "trainingInProgress",
  UNKNOWN: "unknown",
});

export const QueryRecordStatus = Object.freeze({
  PROCESSING: "processing",
  COMPLETED: "completed",
  FAILED: "failed",
  CANCELLED: "cancelled",
});

export const SupportedFeature = Object.freeze({
  METRIC_STREAMING: "metric.streaming",
  DATAEXPORT_DASHBOARD: "dataexport.dashboard",
  ALLOW_INVITE_TO_WORKSPACE: "invite.to.workspace",
  FULL_TABLE_METRIC_HOURLY: "fullTableMetric.hourly",
  ENABLE_BLOB_STORE: "enableBlobStore",
  ENABLE_SECOND_AND_MINUTE_AGGREGATION: "enableSecondAndMinuteAggregation",
  ENABLE_TRIAL_MODE: "enableTrialMode",
  ENABLE_DEBUG_LOGS: "enableFrontendDebugLogs",
  ENABLE_NEW_MONITOR_UX: "enableNewMonitorUX",
});

export const DashboardChartType = Object.freeze({
  TABLE: "table",
  PIE: "pie",
  BAR: "bar",
  NUMBER: "number",
});

export const DashboardFilterType = Object.freeze({
  DATASOURCES: "datasources",
  METRICS: "metrics",
  MONITORS: "monitors",
  METRIC_TYPES: "metricTypes",
  SLICES: "slice",
  TABLES: "tables",
  COLUMNS: "columns",
  STATUS: "status",
  VALIDATION_STATUS: "validationStatus",
  SEVERITY: "severity",
  DIRECTION: "direction",
  TIME_DURATION: "timeDuration",
  TIME_RANGE: "timeRange",
  TAGS: "tags",
});

export const DashboardFilterValue = Object.freeze({
  TAG: "tagValues",
  TIME_DURATION: "timeDurationValue",
  TIME_RANGE: "timeRangeValue",
  STR: "stringValues",
  INT: "intValues",
});

export const TimeDurationUnit = Object.freeze({
  SECONDS: "seconds",
  MINUTES: "minutes",
  HOURS: "hours",
  DAYS: "days",
  WEEKS: "weeks",
  MONTHS: "months",
  YEARS: "years",
});

export const WorkspaceIconName = Object.freeze({
  I1: "I1",
  I2: "I2",
  I3: "I3",
  I4: "I4",
  I5: "I5",
  I6: "I6",
  I7: "I7",
  I8: "I8",
  I9: "I9",
});

export const WorkspaceFormMode = Object.freeze({
  CREATE: 0,
  EDIT: 1,
});

export const TreePathLevel = Object.freeze({
  DATASOURCE: "datasource",
  SCHEMA: "schema",
  TABLE: "table",
  COLUMN: "column",
  CUSTOM_METRIC: "customMetric",
});

export const QueryGovernanceSchedulingMode = Object.freeze({
  ALLOW: "allow",
  DENY: "deny",
});

export const DimensionType = Object.freeze({
  COMPLETENESS: "completeness",
  ACCURACY: "accuracy",
  TIMELINESS: "timeliness",
  CUSTOM: "custom",
});

const dimensionTypeDisplayNameMap = {
  [DimensionType.COMPLETENESS]: "Completeness",
  [DimensionType.ACCURACY]: "Accuracy",
  [DimensionType.TIMELINESS]: "Timeliness",
  [DimensionType.CUSTOM]: "Custom",
};

export function getDimensionTypeDisplayName(dimensionType) {
  return dimensionTypeDisplayNameMap[dimensionType] || "";
}

export const DqDashboardDimensionType = Object.freeze({
  OVERALL: "overall",
  ...DimensionType,
});

export const TakeoverWidth = Object.freeze({
  FULLSCREEN: "fullscreen",
  WIDE: "wide",
  EXTENDED: "extended",
  MEDIUM: "medium",
  NORMAL: "normal",
  NARROW: "narrow",
});

export const SideBarWidth = Object.freeze({
  MAX_WIDTH: 166,
  MIN_WIDTH: 44,
});

export const QueryScope = Object.freeze({
  TIME_RANGE: "timeRange",
  FULL_TABLE: "fullTable",
});

export const ListPageColumnKey = Object.freeze({
  ALERTING: "alerting",
  ACTIONS: "actions",
  COLUMN_NAME: "columnName",
  CREATED_AT: "createdAt",
  CREATED_BY: "createdBy",
  CREATED_TIME: "createdTime",
  DATASOURCE: "datasource",
  DIMENSION: "dimension",
  INCIDENT_PROGRESS: "incidentProgress",
  INCIDENT_COUNT_7D: "incidentCount7D",
  INCIDENT_CREATION_TIME: "incidentCreationTime",
  INCIDENT_DIRECTION: "incidentDirection",
  INCIDENT_DURATION: "incidentDuration",
  INCIDENT_ID: "incidentId",
  INCIDENT_SEVERITY: "incidentSeverity",
  INCIDENT_START_TIME: "incidentStartTime",
  INCIDENT_STATUS: "incidentStatus",
  INCIDENT_VALIDATION: "incidentValidation",
  METRIC_CREATION_TYPE: "metricCreationType",
  METRIC_ID: "metricId",
  METRIC_NAME: "metricName",
  METRIC_STATUS: "metricStatus",
  METRIC_TYPE: "metricType",
  METRIC_QUERY_SCOPE: "metricQueryScope",
  METRIC_SCHEDULE: "metricSchedule",
  MONITOR_COUNT: "monitorCount",
  MONITOR_ID: "monitorId",
  MONITOR_NAME: "monitorName",
  MONITOR_STATUS: "monitorStatus",
  MONITOR_TYPE: "monitorType",
  SCHEMA: "schema",
  SLICE_COLUMN: "sliceColumn",
  SLICE_NAME: "sliceName",
  TABLE: "table",
  TAGS: "tags",
  TRAINING_STATUS: "trainingStatus",
  UPDATED_AT: "updatedAt",
  UPDATED_BY: "updatedBy",
  FAILING_RECORD_COUNT: "failingRecordsCount",
});

export const ListPageName = Object.freeze({
  METRICS: "metrics",
  MONITORS: "monitors",
  INCIDENTS: "incidents",
});

export const JiraAuthType = Object.freeze({
  BASIC_AUTH: "basicAuth",
  TOKEN_AUTH: "tokenAuth",
});

export const ScannedStatus = Object.freeze({
  SUCCESS: "success",
  FAILED: "failed",
  USER_PAUSED: "userPaused",
  SCHEDULE_PAUSED: "schedulePaused",
});

export const EventMetricTypeName = {
  [AutoMetricsType.TABLE_ACTIVITY]: "Table activity",
  [AutoMetricsType.COLUMN_ACTIVITY]: "Column activity",
  [AutoMetricsType.CATEGORY_ACTIVITY]: "Category activity",
};

export const CollectionModeType = Object.freeze({
  TRIGGERED: "triggered",
  SCHEDULED: "scheduled",
  CUSTOM_SCHEDULED: "customScheduled",
});

const collectionModeTypeDisplayNameMap = {
  [CollectionModeType.TRIGGERED]: "Triggered",
  [CollectionModeType.SCHEDULED]: "Scheduled",
  [CollectionModeType.CUSTOM_SCHEDULED]: "Custom scheduled",
};

export function getCollectionModeTypeDisplayName(collectionModeType) {
  return collectionModeTypeDisplayNameMap[collectionModeType] || "";
}

export const TriggerRunStatus = Object.freeze({
  PENDING: "pending",
  COMPLETE: "complete",
  PAUSED: "paused",
});

export const ConnectionStatusPauseTypes = Object.freeze({
  USER_PAUSED: "userPaused",
  SCHEDULE_PAUSED: "schedulePaused",
});

export const ConnectionStatusPauseDisplayMessage = Object.freeze({
  [ConnectionStatusPauseTypes.USER_PAUSED]: "Paused by user",
  [ConnectionStatusPauseTypes.SCHEDULE_PAUSED]: "Paused due to schedule",
});

export const ConnectionHealth = Object.freeze({
  SUCCESS: "success",
  ERROR: "failed",
  UNKNOWN: "unknown",
  PAUSED: "paused",
});

const ConnectionHealthDisplayNameMap = {
  [ConnectionHealth.SUCCESS]: "Healthy",
  [ConnectionHealth.ERROR]: "Error",
  [ConnectionHealth.UNKNOWN]: "Unknown",
  [ConnectionHealth.PAUSED]: "Paused",
};

export function getConnectionHealthDisplayName(connectionHealth) {
  return ConnectionHealthDisplayNameMap[connectionHealth] || "Unknown";
}

export const CollectionWindow = Object.freeze({
  COMPLETE: "complete",
  PARTIAL: "partial",
});

export const dataSourceTypeToMetadataMetricMapping = Object.freeze({
  [DataSourceType.DATABRICKS]: [MetricCategory.UPDATE_DELAY, MetricCategory.BYTE_COUNT],
  [DataSourceType.BIGQUERY]: [MetricCategory.UPDATE_DELAY, MetricCategory.ROW_COUNT],
  [DataSourceType.REDSHIFT]: [MetricCategory.UPDATE_DELAY, MetricCategory.ROW_COUNT],
  [DataSourceType.SNOWFLAKE]: [MetricCategory.UPDATE_DELAY, MetricCategory.ROW_COUNT],
  [DataSourceType.MICROSOFTSQL]: [
    MetricCategory.UPDATE_DELAY,
    MetricCategory.ROW_COUNT,
  ],
  [DataSourceType.ORACLE]: [MetricCategory.ROW_COUNT],
  [DataSourceType.POSTGRES]: [MetricCategory.ROW_COUNT],
  [DataSourceType.INCORTA]: [MetricCategory.ROW_COUNT, MetricCategory.UPDATE_DELAY],
  [DataSourceType.MYSQL]: [MetricCategory.ROW_COUNT, MetricCategory.UPDATE_DELAY],
  [DataSourceType.SAPHANA]: [MetricCategory.ROW_COUNT],
});

export const DataProfileRunStatus = Object.freeze({
  OK: "ok",
  NONE: "none",
  EXCEPTION: "exception",
  IN_PROGRESS: "inProgress",
});

export const EventLevel = Object.freeze({
  UNKNOWN: "unknown",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
});

export const EventCategory = Object.freeze({
  ME001: "ME-001",
  ME002: "ME-002",
  ME003: "ME-003",
  ME004: "ME-004",
  ME006: "ME-006",
  ME007: "ME-007",
  ME008: "ME-008",
  ME009: "ME-009",
  MO001: "MO-001",
  MO002: "MO-002",
  MO003: "MO-003",
  MO005: "MO-005",
  MO006: "MO-006",
  MO007: "MO-007",
  IN001: "IN-001",
  SO001: "SO-001",
  SO002: "SO-002",
});

export const EventObjectType = Object.freeze({
  UNKNOWN: "UNKNOWN",
  DATASOURCE: "datasource",
  METRIC: "metric",
  MONITOR: "monitor",
  INTEGRATION: "integration",
});

export const SlicesShowMode = Object.freeze({
  ALL: "all",
  ONLY_INCIDENTS: "onlyIncidents",
});

export const OpsGenieResponderType = Object.freeze({
  TEAM: "team",
  USER: "user",
  ESCALATION: "escalation",
  SCHEDULE: "schedule",
});

export const OpsGeniePriorityType = Object.freeze({
  P1: "P1",
  P2: "P2",
  P3: "P3",
  P4: "P4",
  P5: "P5",
});

export const MetricFilterType = Object.freeze({
  DATA: "data",
  METADATA: "metadata",
  AUTO: "auto",
  CUSTOM: "custom",
});

export const MetricWhereClauseType = Object.freeze({
  AND: "AND",
  OR: "OR",
  CUSTOM: "CUSTOM",
});

export const TeradataAuthLogMech = Object.freeze({
  DATABASE: "database",
  LDAP: "ldap",
});

export const TeradataAuthLogMechNameMap = Object.freeze({
  [TeradataAuthLogMech.DATABASE]: "Database",
  [TeradataAuthLogMech.LDAP]: "LDAP",
});

export const MetricMode = Object.freeze({
  PUBLISHED: "published",
  DRAFT: "draft",
});

export const DraftType = Object.freeze({
  NEW: "new",
  UPDATE: "update",
  DELETE: "delete",
});

export const DraftTypeNameMap = Object.freeze({
  [DraftType.NEW]: "New",
  [DraftType.UPDATE]: "Update",
  [DraftType.DELETE]: "Delete",
});

export const DraftState = Object.freeze({
  DRAFT: "draft",
  APPROVAL_PENDING: "approvalPending",
  APPROVED: "approved",
  REJECTED: "rejected",
});

export const DraftStateNameMap = Object.freeze({
  [DraftState.DRAFT]: "Draft",
  [DraftState.APPROVAL_PENDING]: "Approval pending",
  [DraftState.APPROVED]: "Approved",
  [DraftState.REJECTED]: "Rejected",
});

export const VirtualTableType = Object.freeze({
  VIRTUAL_TABLE: "virtualTable",
  USER_DEFINED_VIEW: "userDefinedView",
});

export const UserRole = Object.freeze({
  ADMIN: "admin",
  EDITOR: "editor",
  VIEWER: "viewer",
  OBSERVER: "observer",
});

const workspaceRoleDisplayNameMap = Object.freeze({
  [WorkspaceUserRole.ADMIN]: "Admin",
  [WorkspaceUserRole.EDITOR]: "Editor",
  [WorkspaceUserRole.VIEWER]: "Viewer",
  [WorkspaceUserRole.OBSERVER]: "Observer",
});

export function getWorkspaceRoleDisplayName(workspaceUserRole) {
  return workspaceRoleDisplayNameMap[workspaceUserRole];
}

const appRoleDisplayNameMapper = Object.freeze({
  [AppUserRole.ADMIN]: "Admin",
  [AppUserRole.EDITOR]: "Editor",
  [AppUserRole.VIEWER]: "Viewer",
});

export function getAppRoleDisplayName(appUserRole) {
  return appRoleDisplayNameMapper[appUserRole] || "";
}
