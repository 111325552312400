export const InputStatus = {
  DEFAULT: "default",
  ERROR: "error",
};

export const AccordionExpand = {
  SINGLE: "single",
  MULTIPLE: "multiple",
};

export const AccordionVariant = {
  LONG: "long",
  SHORT: "short",
};

export const DialogSize = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  FULLSCREEN: "fullscreen",
};
