import React, { useState } from "react";
import Popover from "../popover";
import { CheckboxGroup } from "../../atom/checkbox";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "../../atom/Button";
import Icon, { IconName } from "../../elements/Icon";

import "./column-list-control.scss";
function ColumnListControl(props) {
  const { value, onChange, options } = props;
  const [visible, setVisible] = useState(false);

  function onVisibleChange(visible) {
    setVisible(visible);
  }

  const popOverContent = (
    <div className="column-list-controls-popover-content-container">
      <CheckboxGroup options={options} defaultValue={value} onChange={onChange} />
    </div>
  );

  // The extra wrapping div here seems necessary because <Popover> will clobber
  // the class on the div immediately within it when it gets opened.
  const trigger = (
    <div>
      <Button
        icon={<Icon name={IconName.Funnel} size={14} />}
        variant={ButtonVariant.ICON}
        type={ButtonType.SECONDARY}
        size={ButtonSize.LARGE}
        testId="column-list-controls"
        aria-label="column-list"
      />
    </div>
  );

  return (
    <Popover
      overlayClassName="column-list-controls-popover-container"
      content={popOverContent}
      trigger="click"
      placement={"bottomLeft"}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      {trigger}
    </Popover>
  );
}

export default ColumnListControl;
