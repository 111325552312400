import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

import BodySmall from "../../elements/Typography/BodySmall";
import { FontWeights, TypographyColors } from "../../elements/Typography/enum";

import "./inputlabel.scss";

export default function InputLabel(props) {
  if (!props.label) {
    return null;
  }
  return (
    <div className="input-header" data-testid={props.testId}>
      <div className="input-label-section">
        <BodySmall weight={FontWeights.MEDIUM} color={TypographyColors.INFO1}>
          {props.label}
        </BodySmall>
        {props.tooltipText && (
          <Tooltip title={props.tooltipText}>
            <QuestionCircleFilled className="input-tooltip" />
          </Tooltip>
        )}
      </div>
      {props.isOptional && (
        <span className="optional-text">{props.optionalText || "Optional"}</span>
      )}
    </div>
  );
}
