import React, { useState } from "react";
import Popover from "../popover";
import { LabeledAutoComplete } from "../labeled-control/labeled-control";
import { classesName } from "../../utils/css";
import Button, {
  ButtonShape,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../atom/Button";
import Icon, { IconName } from "../../elements/Icon";
import Tag from "../../atom/tag";
import "./ng-tag-group.scss";

export const TagChangeOperation = Object.freeze({
  ADD: "add",
  REMOVE: "remove",
});

export function getUpdatedTags(existingTags, changeObject) {
  const tagSet = new Set(existingTags);
  const { operation, tag: changeTag } = changeObject;
  if (operation === TagChangeOperation.ADD) {
    tagSet.add(changeTag);
  } else if (operation === TagChangeOperation.REMOVE) {
    tagSet.delete(changeTag);
  }
  return [...tagSet];
}

function AddTagPopover(props) {
  const { tagList, onAddTag, disabled = false } = props;

  const [tagInput, setTagInput] = useState(null);

  return (
    <div className="ng-tag-group-add-tag-popover">
      <LabeledAutoComplete
        label="Tag"
        options={tagList.map((tag) => ({ value: tag }))}
        value={tagInput}
        onChange={setTagInput}
        onSelect={(selectedTag) => {
          setTagInput(null);
          onAddTag(selectedTag);
        }}
        filterOption
      />
      <Button
        disabled={(tagInput || "").trim().length === 0 || disabled}
        size={ButtonSize.LARGE}
        onClick={() => {
          setTagInput(null);
          onAddTag(tagInput);
        }}
        label="Add Tag +"
      />
    </div>
  );
}

function TagList(props) {
  const { tags, editEnabled, onRemoveTag, allowWrap = false, disabled = false } = props;

  return (
    <div className={classesName("ng-tag-group-tags", allowWrap && "allow-wrap")}>
      {tags.map((tagLabel) => (
        <Tag
          key={tagLabel}
          closable={editEnabled}
          disabled={disabled}
          onClose={() => onRemoveTag?.(tagLabel)}
        >
          {tagLabel}
        </Tag>
      ))}
    </div>
  );
}

function NgTagGroup(props) {
  const {
    value,
    tagList = [],
    editEnabled = true,
    disabled = false,
    maxVisibleTags = 4,
    onChange,
    AddPopover = AddTagPopover,
    hideAddBtn = false,
  } = props;

  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const sortedTags = [...value].sort();
  const visibleTags = sortedTags.slice(0, maxVisibleTags);
  const collapsedTags = sortedTags.slice(maxVisibleTags);

  const addOnePopover = (
    <AddPopover
      disabled={disabled}
      tagList={tagList}
      onAddTag={(tag) => {
        setAddDialogOpen(false);
        onAddTag(tag);
      }}
    />
  );

  const collapsedPopoverContent = (
    <div className="ng-tag-group-collapsed-popover">
      <TagList
        tags={collapsedTags}
        disabled={disabled}
        editEnabled={editEnabled}
        onRemoveTag={onRemoveTag}
        allowWrap
      />
    </div>
  );

  function onAddTag(addedTag) {
    const newTags = new Set(value);
    newTags.add(addedTag);
    onChange([...newTags], { operation: TagChangeOperation.ADD, tag: addedTag });
  }

  function onRemoveTag(removedTag) {
    const newTags = new Set(value);
    newTags.delete(removedTag);
    onChange([...newTags], { operation: TagChangeOperation.REMOVE, tag: removedTag });
  }
  return (
    <div className="ng-tag-group">
      {!hideAddBtn && editEnabled && (
        <Popover
          visible={addDialogOpen}
          onVisibleChange={setAddDialogOpen}
          trigger="click"
          content={addOnePopover}
        >
          <Button
            type={ButtonType.SECONDARY}
            variant={ButtonVariant.ICON}
            size={ButtonSize.SMALL}
            shape={ButtonShape.ROUND}
            disabled={disabled}
            onClick={() => setAddDialogOpen(true)}
            icon={<Icon name={IconName.Plus} size={12} />}
          />
        </Popover>
      )}
      <div className="ng-tag-group-visible-tags">
        <TagList
          tags={visibleTags}
          editEnabled={editEnabled}
          onRemoveTag={onRemoveTag}
          disabled={disabled}
        />
      </div>
      {collapsedTags.length > 0 && (
        <Popover
          trigger="hover"
          content={collapsedPopoverContent}
          overlayClassName="ng-tag-group-collapsed-overlay"
        >
          <div className="ng-tag-group-collapsed-tags-trigger">
            <Tag closable={false}>{`+${collapsedTags.length}`}</Tag>
          </div>
        </Popover>
      )}
    </div>
  );
}

export default NgTagGroup;
