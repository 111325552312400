import React from "react";
import {
  ModalFieldGroup,
  ModalFieldRows,
} from "../../components/modal-dialog/modal-field-group";
import { LabeledSelect } from "../../components/labeled-control/labeled-control";
import { HOUR_IN_SECONDS, DAY_IN_SECONDS, WEEK_IN_SECONDS } from "../../utils/time";

import "./integration-digest-period-dropdown.scss";

const DigestPeriodOptions = [
  { label: "Immediately", value: 0 },
  { label: "Hourly", value: HOUR_IN_SECONDS },
  { label: "Daily", value: DAY_IN_SECONDS },
  { label: "Weekly", value: WEEK_IN_SECONDS },
];

function IntegrationDigestPeriodDropdown(props) {
  const { value, onChange, testId } = props;

  return (
    <ModalFieldGroup>
      <ModalFieldRows>
        <LabeledSelect
          label="Post alerts to this channel"
          value={value}
          options={DigestPeriodOptions}
          onChange={onChange}
          testId={testId}
        />
      </ModalFieldRows>
    </ModalFieldGroup>
  );
}

export default IntegrationDigestPeriodDropdown;
