import React, { useRef, useState } from "react";
import { useResizeObserver } from "../../utils/hooks";
import { ConfigProvider, Table } from "antd";
import NoDataIcon from "../icons/empty-state";
import { NgTextTooltip } from "../text-tooltip/ng-text-tooltip";
import { classesName } from "../../utils/css";
import { TableBanner } from "./table-banner";
import { Spinner } from "../../atom/spinner";
import { withResizableHeader } from "./with-resizable-header";
import BodySmall from "../../elements/Typography/BodySmall";

import "./ng-table.scss";

const ResizableHeaderTable = withResizableHeader(Table);

export function NgTableEmptyState(props) {
  const {
    title = "No data available",
    description = "",
    icon = <NoDataIcon />,
  } = props;
  return (
    <div className="ng-table-empty-state">
      <div className="ng-table-no-data-icon">{icon}</div>
      <div className="ng-table-empty-state-title">{title}</div>
      {description && (
        <div className="ng-table-empty-state-description">{description}</div>
      )}
    </div>
  );
}

export function NgTableLoading(props) {
  const { title } = props;
  return (
    <div className="ng-table-empty-state ng-table-loading">
      <Spinner size="large" />
      <div className="ng-table-empty-state-title">{title}</div>
    </div>
  );
}

export function NgTableClickableText(props) {
  const {
    children,
    onClick,
    overflow = "ellipsis",
    clickable = true,
    highlighted = false,
  } = props;

  const onClickHandler = () => {
    if (!clickable) {
      return;
    }
    onClick?.();
  };
  const clickableClass = clickable ? "clickable" : "";

  return overflow === "break" ? (
    <span
      className={classesName("ng-table-text", clickableClass)}
      onClick={onClickHandler}
      style={{ wordBreak: "break-all" }}
    >
      <BodySmall>{children}</BodySmall>
    </span>
  ) : (
    <NgTextTooltip
      hideTooltipOnClick
      className={classesName(
        "ng-table-text",
        clickableClass,
        highlighted && "highlighted"
      )}
      onClick={onClickHandler}
    >
      <BodySmall>{children}</BodySmall>
    </NgTextTooltip>
  );
}

export const NgTableTheme = Object.freeze({
  DARK: "dark",
  LIGHT: "light",
});

function NgTable(props) {
  const {
    emptyState: propsEmptyState,
    loading: propsLoading,
    loadingTitle,
    theme = NgTableTheme.DARK,
    resizableHeader = null,
    testId = "",
    ...restProps
  } = props;
  const pagination = {
    position: ["bottomRight"],
    hideOnSinglePage: true,
  };

  function renderEmpty() {
    const emptyState = propsEmptyState ?? <NgTableEmptyState />;
    // By default, Antd will show a spinner on top of the table empty state; this looks weird. If we're loading,
    // and we've got no data, just show the spinner.
    return loading ? null : emptyState;
  }

  const loading = propsLoading
    ? { indicator: <NgTableLoading title={loadingTitle} /> }
    : false;

  const tableClasses = ["ng-table", `ng-table-theme-${theme}`];
  if (loading) {
    tableClasses.push("ng-table-loading");
  }

  return (
    <div className={tableClasses.join(" ")} data-testid={testId}>
      <ConfigProvider renderEmpty={renderEmpty}>
        <ResizableHeaderTable
          size="small"
          showSorterTooltip={false}
          pagination={pagination}
          sortDirections={["ascend", "descend"]}
          loading={loading}
          resizableHeader={{
            enabled: true,
            defaultMinWidth: 80,
            ignoreLastColumn: true,
            ...resizableHeader,
          }}
          bordered
          {...restProps}
        />
      </ConfigProvider>
    </div>
  );
}

export default NgTable;

// Resize observer is somewhat new, and might not be supported in every browser.
const resizeObserverSupported = typeof ResizeObserver !== "undefined";

// We assume that the controls container, under normal circumstances, has a height of 53px.
const singleLineControlsHeight = 53;

const topControlsPlaceholder = <div style={{ height: singleLineControlsHeight }} />;

export function DynamicHeightTable(props) {
  const {
    topControls = topControlsPlaceholder,
    loading,
    scroll = {},
    pagination = {},
    dataSource,
    selectionStats,
    isFiltered,
    onClearAllRows,
    onSelectAllRows,
    rowSelection,
    ...restProps
  } = props;

  const tableContainerRef = useRef();
  const controlsContainerRef = useRef();
  const [tableContainerHeight, setTableContainerHeight] = useState(0);
  const [controlsContainerHeight, setControlsContainerHeight] = useState(0);

  useResizeObserver(tableContainerRef, (container) => {
    setTableContainerHeight(container.clientHeight);
  });
  useResizeObserver(controlsContainerRef, (container) => {
    setControlsContainerHeight(container.clientHeight);
  });

  // The amount by which the height of the controls exceeds the normal height.
  const controlsVerticalOverflow = controlsContainerHeight - singleLineControlsHeight;

  // This value represents the total vertical size of the table minus: controls, table headers,
  // scrollbars, or anything that is not the actual table row data. This particular value was
  // arrived at through experimentation.
  const tableScrollOffset = 90;
  const titleHeight = Boolean(rowSelection) ? 32 : 0;
  const scrollHeight =
    tableContainerHeight - tableScrollOffset - controlsVerticalOverflow - titleHeight;

  // If resize observer is not supported, let the table size itself.
  const tableScroll = resizeObserverSupported ? { ...scroll, y: scrollHeight } : scroll;

  const title =
    Boolean(rowSelection) &&
    (() => (
      <TableBanner
        isFiltered={isFiltered}
        totalRows={dataSource.length}
        selectionStats={selectionStats}
        onClearAll={onClearAllRows}
        onSelectAll={onSelectAllRows}
      />
    ));

  return (
    <div className="dynamic-height-table" ref={tableContainerRef}>
      <div
        className={classesName("dynamic-height-table-table", loading && "loading")}
        style={{ marginTop: controlsVerticalOverflow }}
      >
        {/* The pagination controls are hidden while the table loads, so add in a spacer
        to keep the layout from jumping around. */}
        {(loading || dataSource.length === 0) && (
          <div className="dynamic-height-table-pagination-placeholder" />
        )}
        <NgTable
          loading={loading}
          pagination={{ position: ["topRight"], ...pagination }}
          rowSelection={rowSelection}
          scroll={loading ? null : tableScroll}
          title={title}
          dataSource={dataSource}
          bordered
          striped={false}
          {...restProps}
        />
      </div>
      <div
        className="dynamic-height-table-controls"
        ref={controlsContainerRef}
        style={{ top: -controlsVerticalOverflow }}
      >
        <div className="dynamic-height-table-table-controls-inner">{topControls}</div>
      </div>
    </div>
  );
}
