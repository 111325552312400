import React from "react";
import { Popover } from "antd";
import Tag from "../../atom/tag";
import NotificationAddAlertChannelPopover from "./notification-add-alert-channel-popover";
import { getAlertChannelDisplayName } from "./utils";

import "./notification-alert-channel-cell.scss";
import NgTagGroup from "../../components/tag-group/ng-tag-group";

function CollapsedAlertChannelListPopover(props) {
  const { alertChannels, onDelete, isReadonly } = props;
  if (alertChannels.length === 0) {
    return null;
  }

  const popOverContent = (
    <div className="collapsed-alert-channel-list-popover-content-container">
      {alertChannels.map((currentAlertChannel) => {
        return (
          <Tag
            closable={!isReadonly}
            onClose={() => onDelete(currentAlertChannel)}
            className="collapsed-alert-channel-list-popover-alert-channel-item-container"
            key={currentAlertChannel.id}
          >
            {getAlertChannelDisplayName(currentAlertChannel)}
          </Tag>
        );
      })}
    </div>
  );

  return (
    <Popover content={popOverContent}>
      <Tag> +{alertChannels.length}</Tag>
    </Popover>
  );
}

function NotificationAlertChannelCell(props) {
  const { value, alertChannelList, onChange, isReadonly = false } = props;
  const assignedAlertChannelList = [];
  const notAssignedAlertChannelList = [];
  for (let currentAlertChannel of alertChannelList) {
    if (value.find((currentValue) => currentValue === currentAlertChannel.id)) {
      assignedAlertChannelList.push(currentAlertChannel);
    } else {
      notAssignedAlertChannelList.push(currentAlertChannel);
    }
  }

  const flattenAssignedAlertChannelList = assignedAlertChannelList.slice(0, 2);
  const collapsedAssignedAlertChannelList = assignedAlertChannelList.slice(2);
  return (
    <div className="notification-alert-channel-cell-container">
      {!isReadonly && (
        <NotificationAddAlertChannelPopover
          alertChannelList={notAssignedAlertChannelList}
          onAdd={(newAlertChannel) => {
            onChange([...value, newAlertChannel.id]);
          }}
        />
      )}
      <NgTagGroup
        hideAddBtn
        value={flattenAssignedAlertChannelList.map(
          (alertChannel) => alertChannel.title
        )}
        tagList={notAssignedAlertChannelList.map((alertChannel) => alertChannel.title)}
        editEnabled={!isReadonly}
        onChange={(newAlertChannelIds) => {
          onChange(newAlertChannelIds);
        }}
      />

      <CollapsedAlertChannelListPopover
        isReadonly={isReadonly}
        alertChannels={collapsedAssignedAlertChannelList}
        onDelete={(deletedAlertChannel) =>
          onChange(value.filter((currentId) => currentId !== deletedAlertChannel.id))
        }
      />
    </div>
  );
}

export default NotificationAlertChannelCell;
