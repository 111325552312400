import { getFullTableName } from "../../../utils/datasource";
import { IncidentCreatorType, TableType } from "../../../utils/enums";
import { getSliceDisplayString } from "../../../utils/general";
import { getTagsFromCreatorInfo } from "../../../utils/incident";
import {
  getAutoMetricTypeFromKPI,
  isIncidentValidationSupported,
  metricCreationTypeLabel,
} from "../../../utils/metric";
import { getMonitorTypeName } from "../../../utils/monitor";
import {
  getOptionByValue,
  incidentDirectionOptions,
  incidentSeverityOptions,
  incidentStatusOptions,
  incidentValidationStatusOptions,
} from "../../../utils/options";
import {
  getStringCompareFunction,
  listOfStringListCompareFunction,
  normalizeFilterListOfTagListParams,
  stringListCompareFunction,
} from "../../../utils/search";
import { getMetricTypeFromConfigData, metricTypeNames } from "../../metric/utils";
import { dataSourceFields } from "./use-search-datasource-config";
import { GENERAL_SEARCH_FIELD, metricFields } from "./use-search-metrics-config";
import { monitorFields } from "./use-search-monitors-config";

const stringCompareFunction = getStringCompareFunction(false);
const strictStringCompareFunction = getStringCompareFunction(true);

export const incidentFields = {
  INCIDENT_STATUS: "status",
  INCIDENT_PROGRESS: "incidentProgress",
  DIRECTION: "direction",
  SEVERITY: "severity",
  VALIDATION_STATUS: "validationStatus",
  SLICE: "slice",
  ALERT_CHANNEL_ID: "alertChannelId",
  MONITOR_ID: monitorFields.MONITOR_ID,
  MONITOR_NAME: monitorFields.MONITOR_NAME,
  MONITOR_TYPE: monitorFields.MONITOR_TYPE,
  METRIC_ID: metricFields.METRIC_ID,
  KPI_NAME: metricFields.KPI_NAME,
  DATA_SOURCE_ID: dataSourceFields.DATA_SOURCE_ID,
  DATA_SOURCE_NAME: metricFields.DATA_SOURCE_NAME,
  METRIC_TYPE: metricFields.METRIC_TYPE,
  TABLE_NAME: metricFields.TABLE_NAME,
  COLUMN_NAME: metricFields.COLUMN_NAME,
  CREATION_TYPE: metricFields.CREATION_TYPE,
  DIMENSION: metricFields.DIMENSION,
  INCIDENT_ID: "incidentId",
  TAG_NAME: "tagName",
};

export const nestedIncidentFields = [
  incidentFields.INCIDENT_ID,
  incidentFields.INCIDENT_PROGRESS,
  incidentFields.DIRECTION,
  incidentFields.INCIDENT_STATUS,
  incidentFields.SEVERITY,
  incidentFields.VALIDATION_STATUS,
];

export const incidentFieldOptionValueGetterFn = {
  [incidentFields.MONITOR_NAME]: (rule) => rule.creatorInfo.filterInfo.metadata.name,
  [incidentFields.MONITOR_TYPE]: (rule) =>
    getMonitorTypeName(rule.creatorInfo.filterInfo),
  [incidentFields.MONITOR_ID]: (rule) =>
    rule.creatorInfo.filterInfo.metadata.idSerial.toString(),
  [incidentFields.KPI_NAME]: (rule) =>
    rule.creatorInfo.type === IncidentCreatorType.FILTER
      ? rule.creatorInfo.kpiInfo.metadata.name
      : "",
  [incidentFields.INCIDENT_ID]: (rule) => rule.incidents.map(({ id }) => id.toString()),
  [incidentFields.DATA_SOURCE_ID]: (rule) =>
    rule.creatorInfo.type === IncidentCreatorType.FILTER
      ? rule.creatorInfo.dataSourceInfo.metadata.idSerial?.toString()
      : "",
  [incidentFields.METRIC_ID]: (rule) =>
    rule.creatorInfo.type === IncidentCreatorType.FILTER
      ? rule.creatorInfo.kpiInfo.metadata.idSerial.toString()
      : "",
  [incidentFields.SLICE]: (rule) => {
    const sliceDisplayValue = getSliceDisplayString(
      rule.slice,
      rule.creatorInfo?.kpiInfo,
      true
    );
    return sliceDisplayValue && !sliceDisplayValue.includes("lightup__")
      ? sliceDisplayValue
      : "";
  },
  [incidentFields.TAG_NAME]: (rule) => getTagsFromCreatorInfo(rule.creatorInfo, false),
  [incidentFields.TABLE_NAME]: (rule) => {
    if (
      rule.creatorInfo.kpiInfo.config.table &&
      rule.creatorInfo.kpiInfo.config.table.type === TableType.TABLE
    ) {
      return getFullTableName(rule.creatorInfo.kpiInfo.config.table);
    }
  },
  [incidentFields.COLUMN_NAME]: (rule) => {
    const kpiInfo = rule.creatorInfo.kpiInfo;
    if (kpiInfo.config.valueColumns && kpiInfo.config.valueColumns.length > 0) {
      return kpiInfo.config.valueColumns.map(({ columnName }) => columnName).join(", ");
    }
    return "";
  },
  [incidentFields.ALERT_CHANNEL_ID]: (rule) => {
    if (rule.creatorInfo.type === IncidentCreatorType.FILTER) {
      const sliceAlertConfigs =
        rule.creatorInfo.filterInfo.config?.sliceAlertConfigs || [];
      const sliceAlertingChannels = sliceAlertConfigs.flatMap(
        (sliceConfig) =>
          sliceConfig.alertConfig?.channels.map(({ channelId }) => channelId) || []
      );
      const channels = rule.creatorInfo.filterInfo.config.alertConfig?.channels || [];
      const alertChannelIds = channels.map(({ channelId }) => channelId);
      return [...alertChannelIds, ...sliceAlertingChannels];
    }
  },
  [incidentFields.CREATION_TYPE]: (rule) =>
    rule && metricCreationTypeLabel(rule.creatorInfo.kpiInfo),
  [incidentFields.DIMENSION]: (rule) => rule?.creatorInfo.kpiInfo?.config.dimension,
};

export const incidentFilterFn = {
  [GENERAL_SEARCH_FIELD]: (row, creatorIsFilter, searchName) => {
    const tagsNames = getTagsFromCreatorInfo(row.creatorInfo, true);
    const monitorName = row.creatorInfo.filterInfo.metadata.name;
    const kpiName = row.creatorInfo.kpiInfo?.metadata.name;
    const dataSourceName = row.creatorInfo.dataSourceInfo.metadata.name;
    const tableName = getFullTableName(row.creatorInfo.kpiInfo?.config.table);
    const columnNames = row.creatorInfo.kpiInfo?.config.valueColumns
      ?.map(({ columnName }) => columnName)
      .join(", ");
    const sliceName = row?.slice ? getSliceDisplayString(row.slice, null, true) : [];
    return (
      searchName.length &&
      !stringListCompareFunction(
        [
          monitorName,
          kpiName,
          dataSourceName,
          tableName,
          columnNames,
          sliceName,
          ...tagsNames,
        ],
        searchName,
        stringCompareFunction
      )
    );
  },
  /* Nested incidents (rule.incidents) functions */
  [incidentFields.INCIDENT_ID]: (incident, kpiInfo, searchIncidentsIds) => {
    return !searchIncidentsIds.includes(incident.id.toString());
  },
  [incidentFields.INCIDENT_PROGRESS]: (incident, kpiInfo, progressState) => {
    return (
      progressState?.length &&
      !progressState.includes(incident.ongoing ? "Active" : "Completed")
    );
  },
  [incidentFields.DIRECTION]: (incident, kpiInfo, direction) => {
    const directionLabel = getOptionByValue(
      incidentDirectionOptions,
      incident.direction
    )?.label;
    return direction?.length && !direction.includes(directionLabel);
  },
  [incidentFields.INCIDENT_STATUS]: (incident, kpiInfo, status) => {
    const statusLabel = getOptionByValue(incidentStatusOptions, incident.status)?.label;
    return status?.length && !status.includes(statusLabel);
  },
  [incidentFields.SEVERITY]: (incident, kpiInfo, severity) => {
    const severityLabel = getOptionByValue(
      incidentSeverityOptions,
      incident.score
    )?.label;
    return severity?.length && !severity.includes(severityLabel);
  },
  [incidentFields.VALIDATION_STATUS]: (incident, kpiInfo, validationStatus) => {
    const validationStatusLabel = getOptionByValue(
      incidentValidationStatusOptions,
      incident.validationStatus
    )?.label;
    const validationStatusSupported = isIncidentValidationSupported(
      getAutoMetricTypeFromKPI(kpiInfo),
      kpiInfo
    );
    return (
      validationStatus?.length &&
      (!validationStatus.includes(validationStatusLabel) || !validationStatusSupported)
    );
  },
  [incidentFields.MONITOR_NAME]: (row, creatorIsFilter, monitorName) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(row.creatorInfo.filterInfo.metadata.name, monitorName)
    );
  },
  [incidentFields.MONITOR_TYPE]: (row, creatorIsFilter, monitorType) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(
        getMonitorTypeName(row.creatorInfo.filterInfo),
        monitorType
      )
    );
  },
  [incidentFields.MONITOR_ID]: (row, creatorIsFilter, monitorId) => {
    return (
      creatorIsFilter &&
      !monitorId.includes(row.creatorInfo.filterInfo.metadata.idSerial.toString())
    );
  },
  [incidentFields.DATA_SOURCE_ID]: (row, creatorIsFilter, dataSourceId) => {
    return (
      creatorIsFilter &&
      !dataSourceId.includes(
        row.creatorInfo.dataSourceInfo.metadata.idSerial.toString()
      )
    );
  },
  [incidentFields.DATA_SOURCE_NAME]: (row, creatorIsFilter, dataSourceName) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(
        row.creatorInfo.dataSourceInfo.metadata.name,
        dataSourceName
      )
    );
  },
  [incidentFields.TABLE_NAME]: (row, creatorIsFilter, tableName) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(
        getFullTableName(row.creatorInfo.kpiInfo.config.table),
        tableName
      )
    );
  },
  [incidentFields.COLUMN_NAME]: (row, creatorIsFilter, columnName) => {
    const columns = row.creatorInfo.kpiInfo.config.valueColumns;
    const hasColumns = Boolean(columns?.length);
    const columnExists =
      creatorIsFilter &&
      hasColumns &&
      columns.some((column) => stringCompareFunction(column.columnName, columnName));
    return !columnExists;
  },
  [incidentFields.KPI_NAME]: (row, creatorIsFilter, kpiName) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(row.creatorInfo.kpiInfo.metadata.name, kpiName)
    );
  },
  [incidentFields.METRIC_TYPE]: (row, creatorIsFilter, metricType) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(
        metricTypeNames[getMetricTypeFromConfigData(row.creatorInfo.kpiInfo)],
        metricType
      )
    );
  },
  [incidentFields.METRIC_ID]: (row, creatorIsFilter, metricId) => {
    return (
      creatorIsFilter &&
      !metricId.includes(row.creatorInfo.kpiInfo.metadata.idSerial.toString())
    );
  },
  [incidentFields.SLICE]: (row, creatorIsFilter, slice) => {
    const normalizedSliceName = getSliceDisplayString(
      row.slice,
      row.creatorInfo?.kpiInfo,
      true
    );
    return !stringCompareFunction(normalizedSliceName, slice);
  },
  [incidentFields.TAG_NAME]: (row, creatorIsFilter, tagName) => {
    const tags = getTagsFromCreatorInfo(row.creatorInfo, false);
    tagName = normalizeFilterListOfTagListParams(tagName);
    return (
      creatorIsFilter &&
      (!tags.length ||
        !listOfStringListCompareFunction(tagName, tags, strictStringCompareFunction))
    );
  },
  [incidentFields.ALERT_CHANNEL_ID]: (row, creatorIsFilter, alertChannelIds) => {
    if (creatorIsFilter) {
      const channels = row.creatorInfo.filterInfo.config.alertConfig?.channels || [];
      const monitorAlertingChannels = channels.map(({ channelId }) => channelId);

      // New code to check slice alert configs
      const sliceAlertConfigs =
        row.creatorInfo.filterInfo.config.sliceAlertConfigs || [];
      const sliceAlertingChannels = sliceAlertConfigs.flatMap(
        (sliceConfig) =>
          sliceConfig.alertConfig?.channels.map(({ channelId }) => channelId) || []
      );

      // Combine both channel arrays
      const allAlertingChannels = [
        ...monitorAlertingChannels,
        ...sliceAlertingChannels,
      ];

      return !allAlertingChannels.some((channelId) =>
        alertChannelIds.includes(channelId)
      );
    }
  },
  [incidentFields.CREATION_TYPE]: (row, creatorIsFilter, creationType) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(
        metricCreationTypeLabel(row.creatorInfo.kpiInfo),
        creationType
      )
    );
  },
  [incidentFields.DIMENSION]: (row, creatorIsFilter, dimension) => {
    return (
      creatorIsFilter &&
      !stringCompareFunction(row.creatorInfo.kpiInfo?.config.dimension, dimension)
    );
  },
};
