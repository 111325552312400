import React from "react";
import PropTypes from "prop-types";
import { default as AntIcon } from "@ant-design/icons";
import IconMap from "./iconmap";
import { classesName } from "../../utils/css";
import { TypographyColors } from "../Typography/enum";

import "./icon.scss";

export default function Icon(props) {
  const {
    name,
    size = IconSizes.LARGE,
    className,
    color = TypographyColors.DEFAULT,
    ...restProps
  } = props;
  const icon = IconMap[name];
  return (
    <AntIcon
      component={icon}
      className={classesName("lightup-icon", className)}
      style={{ fontSize: size, color }}
      {...restProps}
    />
  );
}

const IconName = Object.fromEntries(Object.keys(IconMap).map((key) => [key, key]));
Object.freeze(IconName);
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  testId: PropTypes.string,
};

const IconSizes = {
  XSMALL: 12,
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 20,
  XLARGE: 24,
};
export { IconName, IconSizes };
