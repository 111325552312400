import { useSelector } from "react-redux";
import { useCallback } from "react";

import { isFeatureEnabled } from "../utils/general";

/**
 * Custom hook to check if given feature flags are enabled.
 *
 * @returns {Object} - An object containing the `isFeatureEnabled` function that accepts an array of feature flag names and returns an array of booleans.
 */
function useFeatureFlag() {
  const featureFlags = useSelector(
    (state) => state.userReducer.currentUserInfo?.waffle || {}
  );

  /**
   * Checks if specific feature flags are enabled.
   *
   * @param {string[]} arrayOfFlags - An array of feature flag names to check.
   * @returns {boolean[]} - An array of booleans indicating whether each feature flag is enabled.
   */
  const isFeatureFlagEnabled = useCallback(
    (arrayOfFlags = []) =>
      arrayOfFlags.map((flagName) => !!isFeatureEnabled(featureFlags, flagName)),
    [featureFlags]
  );

  return { isFeatureEnabled: isFeatureFlagEnabled };
}

export default useFeatureFlag;
