import React from "react";
import { Modal } from "antd";
import Button from "../button/ng-button";
import { classesName } from "../../utils/css";

import "./ng-index.scss";

function ConfirmationDialog(props) {
  const {
    modalIsOpen,
    setModalIsOpen,
    okClicked,
    context = {},
    title = "Confirmation",
    confirmationMsg = "Confirmation",
    okText = "Yes",
    cancelText = "Cancel",
    isOKEnabled = true,
    showCancelButton = true,
    leftFooterControls = null,
    className = "",
    onCancel,
  } = props;
  const onCancelClick = () => {
    onCancel?.();
    setModalIsOpen(false);
  };
  const footer = [
    <div className="ng-confirm-footer" key="footer">
      {leftFooterControls}
      <div className="ng-confirm-buttons" key="buttons">
        <Button
          primary
          size="large"
          disabled={!isOKEnabled}
          onClick={() => {
            okClicked(context);
            setModalIsOpen(false);
          }}
        >
          {okText}
        </Button>
        {showCancelButton && (
          <Button size="large" outline onClick={onCancelClick}>
            {cancelText}
          </Button>
        )}
      </div>
    </div>,
  ];

  return (
    <Modal
      visible={modalIsOpen}
      title={title}
      closable={false}
      footer={footer}
      className={classesName("ng-confirm", className)}
    >
      {confirmationMsg}
    </Modal>
  );
}

export default ConfirmationDialog;
