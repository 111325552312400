import React, { useState } from "react";
import { LabeledInput } from "../labeled-control/labeled-control";
import ConfirmationDialog from "../confirmation-dialog/ng-index";

import "./passcode-confirmation-modal.scss";

function PasscodeConfirmationModal(props) {
  const {
    visible = false,
    children,
    okText = "OK",
    cancelText = "Cancel",
    title = "Confirm changes?",
    targetPasscode = "ok",
    onConfirm,
    onCancel,
    setModalIsOpen,
  } = props;

  const [passcodeText, setPasscodeText] = useState("");

  const isOKEnabled =
    passcodeText.trim().toLowerCase() === targetPasscode.toLowerCase();

  const leftFooterControls = (
    <div className="passcode-confirm-passcode-input">
      <LabeledInput
        key="passcode"
        value={passcodeText}
        onChange={(e) => setPasscodeText(e.target.value)}
        label={`Type "${targetPasscode}" to confirm`}
      />
    </div>
  );

  return (
    <ConfirmationDialog
      modalIsOpen={visible}
      setModalIsOpen={setModalIsOpen}
      okClicked={() => {
        setPasscodeText("");
        onConfirm();
      }}
      title={title}
      confirmationMsg={children}
      okText={okText}
      cancelText={cancelText}
      isOKEnabled={isOKEnabled}
      leftFooterControls={leftFooterControls}
      onCancel={onCancel}
    />
  );
}

export default PasscodeConfirmationModal;
