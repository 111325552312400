import React from "react";

import BodySmall from "../../elements/Typography/BodySmall";
import { TypographyColors } from "../../elements/Typography/enum";
import Link from "../../elements/Typography/Link";

import "./inputhelp.scss";

export default function InputHelp({ helpText, helpLink, helpLinkText, error, testId }) {
  if (!helpText && !helpLink && !helpLinkText) {
    return null;
  }

  const helpTextColor = error ? TypographyColors.ERROR : TypographyColors.INFO2;

  return (
    <div className="input-help-section" data-testid={testId}>
      {helpText && <BodySmall color={helpTextColor}>{helpText}</BodySmall>}
      {helpLink && (
        <Link target="_blank" rel="noreferrer" href={helpLink} testId={testId}>
          {helpLinkText || helpLink}
        </Link>
      )}
    </div>
  );
}
