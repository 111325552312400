import React from "react";
import PropTypes from "prop-types";
import Typography from "./Typography";
import { FontWeights, TypographyTypes } from "./enum";

const TitleMedium = ({ children, ...typographyProps }) => (
  <Typography
    elementType="h4"
    size={TypographyTypes.TITLE_MEDIUM}
    weight={FontWeights.BOLD}
    {...typographyProps}
  >
    {children}
  </Typography>
);

TitleMedium.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

export default TitleMedium;
