import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import { NgTableClickableText } from "../table/ng-table";
import { AppPermissions } from "../../utils/permissions";

export function canModifyMonitors(workspaceUserPermissions) {
  return hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
  ]);
}

function MonitorLinkCell(props) {
  const { monitor, workspaceUserPermissions, history } = props;
  const canModify = canModifyMonitors(workspaceUserPermissions);
  const onClick = () =>
    history.push(
      getURIInstance(URIPath.EDIT_MONITOR, {
        workspaceUuid: monitor.metadata.workspaceId,
        id: monitor.metadata.uuid,
      })
    );
  const trigger = (
    <NgTableClickableText onClick={onClick} clickable={canModify}>
      {monitor.metadata.name}
    </NgTableClickableText>
  );
  return trigger;
}

export default MonitorLinkCell;
