import React from "react";
import { EventLevel } from "../../utils/enums";
import { TagStatus } from "../../atom/tag/enums";
import Tag from "../../atom/tag";

function NotificationLevelCell(props) {
  const { value } = props;
  let levelStatus;
  let text;
  if (value === EventLevel.ERROR) {
    text = "Error";
    levelStatus = TagStatus.ERROR;
  } else if (value === EventLevel.INFO) {
    text = "Info";
    levelStatus = TagStatus.INFO;
  } else if (value === EventLevel.WARNING) {
    text = "Warning";
    levelStatus = TagStatus.WARNING;
  } else {
    console.log(`Unknown level ${value}`);
    text = "";
  }

  return <Tag status={levelStatus}>{text}</Tag>;
}

export default NotificationLevelCell;
