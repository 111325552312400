import React from "react";
import { Pagination as AntPagination } from "antd";

import BodyMedium from "../../elements/Typography/BodyMedium";
import { FontWeights, TypographyColors } from "../../elements/Typography/enum";

import "./pagination.scss";

export default function Pagination(props) {
  const { testId = null, simple = false, ...otherProps } = props;
  let className = "energy-pagination";

  let showTotal;
  if (simple) {
    className = "energy-pagination-simple";
    showTotal = (total, range) => (
      <>
        <BodyMedium>
          Showing {range[0]}-{range[1]}
        </BodyMedium>
        <BodyMedium weight={FontWeights.REGULAR} color={TypographyColors.INFO2}>
          {" "}
          of {total}
        </BodyMedium>
      </>
    );
  }

  return (
    <AntPagination
      data-testid={testId}
      className={className}
      showTotal={showTotal}
      {...otherProps}
    />
  );
}
