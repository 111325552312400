import React, { Component } from "react";
import PropTypes from "prop-types";
import RuleBasicConfigTab from "./tabs/basic/rule-basic-config-tab";
import RuleBasicConfigContent from "./tabs/basic/rule-basic-config-content";
import RuleTrainConfigTab from "./tabs/train/rule-train-config-tab";
import RuleTrainConfigContent from "./tabs/train/rule-train-config-content";
import RulePreviewConfigTab from "./tabs/preview/rule-preview-config-tab";
import RulePreviewConfigContent from "./tabs/preview/rule-preview-config-content";
import NgButton from "../../components/button/ng-button";
import { toast } from "../toast/toast";
import {
  RuleTabBasicIcon,
  RuleTabTrainingIcon,
  RuleTabPreviewIcon,
} from "./rule-tab-icon";
import { getRuleSymptomInfo } from "../../utils/icon";
import { PollManager } from "../../utils/poll";
import { isJobRunning } from "../../utils/general";
import {
  NotificationTypeConst,
  RuleTabNameConst,
  SymptomTypeConst,
} from "../../utils/enums";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { Tabs } from "antd";
import { BackIcon } from "../wizard-nav-header/wizard-nav-header";
import { EVENT, PAGE, trackEvent, getMonitorDetailProps } from "../../utils/telemetry";
import { isMonitorTraining } from "../../utils/monitor";
import { getConfigBoundErrors } from "../../utils/config-bound-validation";
import Divider from "../../atom/divider";

import "./rule-wizard.scss";

class RuleWizard extends Component {
  constructor(props) {
    super(props);

    this.state = RuleWizard.getDefaultStateFromProps(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);

    this.onTabChanged = this.onTabChanged.bind(this);
    this.onRuleConfigChanged = this.onRuleConfigChanged.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.onNextButtonClicked = this.onNextButtonClicked.bind(this);

    this.onIsLiveChanged = this.onIsLiveChanged.bind(this);
    this.onPreviewPeriodChange = this.onPreviewPeriodChange.bind(this);
    this.onPreviewDeleted = this.onPreviewDeleted.bind(this);

    this._pollManager = null;
  }

  static getDerivedStateFromProps(props, state) {
    const { isEdit, previewList = [], defaultData } = props;
    if (!isEdit) {
      return null;
    }

    let isChanged = false;
    let changeObject = {};
    if (defaultData !== state.configData) {
      isChanged = true;
      changeObject = {
        ...changeObject,
        configData: defaultData,
      };
    }

    if (previewList !== state.previewList) {
      isChanged = true;
      changeObject = {
        ...changeObject,
        previewList,
      };
    }

    if (isChanged) {
      return { ...state, ...changeObject };
    }

    return null;
  }

  static getDefaultStateFromProps(props) {
    return {
      activeTabKey: RuleWizard.getActiveTabFromProps(props),
      edit: !!props.edit,
      configData: props.defaultData,
      previewList: [],
      isInfoPanelCollapsed: true,
      currentPreviewPeriod: null,
      currentPreviewIncidentInfo: null,
      isSavingInProgress: false,
    };
  }

  static getActiveTabFromProps(props) {
    const { isEdit, activeTabKey } = props;
    if (!isEdit || !activeTabKey) {
      return RuleTabNameConst.CONFIG;
    }

    return activeTabKey;
  }

  componentDidMount() {
    const { isEdit, kpiList } = this.props;
    if (!isEdit || kpiList.length === 0) {
      return;
    }

    const {
      configData: {
        metadata: { uuid },
      },
    } = this.state;
    this.props.getCurrentRuleStatus(uuid);

    const kpi = this.getKpiInfo();
    if (!kpi) {
      console.log(
        `Selected kpi is empty in rule ${this.state.configData.metadata.uuid} edit mode`
      );
    }
  }

  componentDidUpdate() {
    if (!this.props.isEdit) {
      return;
    }

    const {
      configData: {
        metadata: { uuid, name },
        status,
      },
    } = this.state;

    const isTrained = Boolean(status?.training?.isTrained);

    if (!this._pollManager && isMonitorTraining(this.state.configData)) {
      this._pollManager = new PollManager();
      this.props.startPollTrainingStatus(this._pollManager, uuid);
      console.log("Start a rule training status polling");
    } else if (this._pollManager && !isMonitorTraining(this.state.configData)) {
      console.log("Stopping rule training polling");
      this.stopPollManager();
      if (isTrained) {
        this.props.getCurrentRuleStatus(uuid);
        toast(`${name} training complete.`);
      }
    }
  }

  componentWillUnmount() {
    this.stopPollManager();
    if (this.props.resetRuleTrainingInfo) {
      this.props.resetRuleTrainingInfo();
    }
  }

  stopPollManager() {
    if (this._pollManager) {
      this._pollManager.stop();
      this._pollManager = null;
      console.log("Monitor training polling is stopped");
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      trackEvent(EVENT.CANCEL_MONITOR_CREATION, {
        ...getMonitorDetailProps(this.state.configData),
        page: PAGE.MONITOR_DETAIL,
      });
      this.props.onCancel();
    }
  }

  onSave() {
    if (this.props.onSave) {
      trackEvent(EVENT.SAVE_MONITOR, {
        ...getMonitorDetailProps(this.state.configData),
        page: PAGE.MONITOR_DETAIL,
      });

      const newRuleConfig = this.getNewRule();

      if (newRuleConfig?.config?.symptom?.type === SymptomTypeConst.MANUAL_THRESHOLD) {
        const { config } = newRuleConfig;

        const validationErros = getConfigBoundErrors({
          lower: config.symptom.bound.lower,
          upper: config.symptom.bound.upper,
        });

        if (Object.keys(validationErros).length > 0) {
          this.setState({ errors: validationErros });
          return;
        }
      }

      if (!this.isSliceOverridesValid(newRuleConfig?.config)) return;

      this.setState({ isSavingInProgress: true });
      this.props.onSave(newRuleConfig).then(() => {
        this.setState({ isSavingInProgress: false });
      });
    }
  }

  isSliceOverridesValid(config) {
    const hasGlobalTrainingPeriods = !!config?.symptom?.trainingPeriods?.length;

    if (!hasGlobalTrainingPeriods) {
      if (
        config?.sliceSymptomConfigs?.some(
          (sliceOverride) => sliceOverride.symptomConfig.trainingPeriods?.length
        )
      ) {
        toast(
          `You need to have a global training period to save slice specific overrides`,
          NotificationTypeConst.FAILURE
        );
        return false;
      }
    }

    return true;
  }

  getKpiInfo() {
    const { configData } = this.state;
    if (!configData.config.metrics || configData.config.metrics.length === 0) {
      return null;
    }

    return this.props.kpiList.filter(
      (kpi) => kpi.metadata.uuid === configData.config.metrics[0]
    )[0];
  }

  onDelete() {
    const { isEdit } = this.props;
    if (!isEdit) {
      return;
    }

    if (this.props.onDelete) {
      const {
        configData: {
          metadata: { uuid },
        },
      } = this.state;
      console.log(`Start to delete rule ${uuid}`);
      this.props.onDelete(uuid);
    }
  }

  onTabChanged(newTabKey) {
    this.setState({
      activeTabKey: newTabKey,
    });

    if (this.props.onActiveTabChanged) {
      this.props.onActiveTabChanged(newTabKey);
    }
  }

  onRuleConfigChanged(newConfigData, callback = null) {
    this.setState(
      {
        configData: newConfigData,
      },
      () => {
        callback && callback();
      }
    );
  }

  getNewRule() {
    return this.state.configData;
  }

  onIsLiveChanged(isLive) {
    const { configData } = this.state;
    configData.config.isLive = isLive;
    this.onRuleConfigChanged(configData);
  }

  getNavHeader(enableModifyFilter = true) {
    const { configData, activeTabKey } = this.state;

    const {
      metadata: { name },
      config: { symptom = {} },
    } = configData;

    const { type } = symptom;
    const symptomTypeInfo = getRuleSymptomInfo(type);
    const SymptomDisplayIcon = symptomTypeInfo ? symptomTypeInfo.icon : null;

    const buttonItems = this.getButtonItems(enableModifyFilter);

    const buttonItem = buttonItems[activeTabKey];
    const buttonDisabled = !!buttonItem.disabled;

    return (
      <>
        <div className="wizard-nav-back-button" onClick={this.onCancel}>
          <BackIcon />
          Back
        </div>
        <div className="wizard-nav-symptom">
          {SymptomDisplayIcon ? <SymptomDisplayIcon height={24} width={24} /> : null}
          {name}
        </div>
        <Divider type="vertical" style={{ height: 36, margin: "4px" }} />
        <NgButton onClick={this.onCancel} size="large" outline>
          Cancel
        </NgButton>
        <NgButton onClick={buttonItem.onClick} disabled={buttonDisabled} size="large">
          {buttonItem.label}
        </NgButton>
      </>
    );
  }

  onPreviewPeriodChange(newPreviewPeriod, customerOptions) {
    const queryObject = {
      startTime: newPreviewPeriod.startTimestamp,
      endTime: newPreviewPeriod.endTimestamp,
      filterUuids: [this.state.configData.metadata.uuid],
      previewUuids: [newPreviewPeriod.previewId],
      customerOptions,
    };
    this.props.getPreviewIncidentList(queryObject);
    this.props.getPreviewSummaryList(queryObject);
  }

  onRuleCreateClick() {
    console.log("Call create or save method.");
    trackEvent(EVENT.SAVE_AND_TRAIN_MONITOR, {
      ...getMonitorDetailProps(this.state.configData),
      page: PAGE.MONITOR_DETAIL,
    });
    this.onSave();
  }

  onGeneratePreview() {
    console.log("Call creating preview");
    trackEvent(EVENT.PREVIEW_MONITOR, {
      ...getMonitorDetailProps(this.state.configData),
      page: PAGE.MONITOR_DETAIL,
    });

    const { currentPreviewPeriod, configData } = this.state;
    if (!currentPreviewPeriod) {
      console.log("Current preview period is not valid. Ignoring...");
      return;
    }

    const { startTimestamp, endTimestamp } = currentPreviewPeriod;
    const newPreview = {
      startTimestamp,
      endTimestamp,
      filterUuid: configData.metadata.uuid,
      name: `preview_${startTimestamp}_${endTimestamp}`,
    };

    trackEvent(EVENT.PREVIEW_MONITOR, {
      ...getMonitorDetailProps(this.state.configData),
      page: PAGE.MONITOR_DETAIL,
    });

    console.log(`Generating preview with ${JSON.stringify(newPreview)}`);
    this.props.addPreview(newPreview);
  }

  onPreviewDeleted() {
    const {
      configData: {
        metadata: { uuid },
      },
      previewList,
    } = this.state;

    if (previewList.length === 0) {
      return;
    }

    const currentPreview = {
      filterUuid: uuid,
      previewId: previewList[0].id,
    };
    this.props.deletePreview(currentPreview);
  }

  isBasicTabReady() {
    const {
      configData: {
        metadata: { name },
        config: { metrics = [], symptom = {} },
      },
    } = this.state;

    const { type = "" } = symptom;
    return (name || "").trim() && type && metrics.length > 0;
  }

  getTabHeaderItems() {
    const { isEdit } = this.props;
    const isTrained =
      this.state.configData &&
      this.state.configData.status &&
      this.state.configData.status.training &&
      this.state.configData.status.training.isTrained;

    const isAutoSymptom = !(
      this.state.configData.config.symptom && this.state.configData.config.symptom.type
    );
    const isTrainingDisabled = !isEdit && !this.isBasicTabReady();
    const isPreviewDisabled = !isTrained;

    const tabItems = [
      {
        iconComponent: RuleTabBasicIcon,
        label: "Define",
        key: RuleTabNameConst.CONFIG,
      },
      {
        iconComponent: RuleTabTrainingIcon,
        label: "Train",
        key: RuleTabNameConst.TRAINING,
        disabled: isTrainingDisabled || isAutoSymptom,
      },
      {
        iconComponent: RuleTabPreviewIcon,
        label: "Preview",
        key: RuleTabNameConst.PREVIEW,
        disabled: isPreviewDisabled || isAutoSymptom,
      },
    ];

    return tabItems;
  }

  onNextButtonClicked() {
    const { isEdit } = this.props;
    if (isEdit) {
      this.onSave();
    } else {
      trackEvent(EVENT.FINISH_DEFINE_MONITOR, {
        ...getMonitorDetailProps(this.state.configData),
        page: PAGE.MONITOR_DETAIL,
      });
      this.onTabChanged(RuleTabNameConst.TRAINING);
    }
  }

  getButtonItems(enableModifyFilter = true) {
    const isNextButtonDisabled = !this.isBasicTabReady();
    const isSaveTrainButtonDisabled =
      this.props.isEdit &&
      isJobRunning(
        this.state.configData.status.training &&
          this.state.configData.status.training.trainingJobState
          ? this.state.configData.status.training.trainingJobState
          : ""
      );

    const isPreviewButtonDisabled =
      this.state.previewList.length > 0 ||
      !this.state.currentPreviewPeriod ||
      this.state.currentPreviewPeriod.isError ||
      !this.state.currentPreviewPeriod.startTimestamp ||
      !this.state.currentPreviewPeriod.endTimestamp;

    const buttonItems = {
      [RuleTabNameConst.CONFIG]: {
        label: this.props.isEdit ? "Save" : "Next",
        onClick: this.onNextButtonClicked,
        disabled: isNextButtonDisabled || !enableModifyFilter,
      },
      [RuleTabNameConst.TRAINING]: {
        label: "Save and train",
        onClick: this.onRuleCreateClick.bind(this),
        disabled:
          isSaveTrainButtonDisabled ||
          !enableModifyFilter ||
          this.state.isSavingInProgress,
      },
      [RuleTabNameConst.PREVIEW]: {
        label: "Generate Preview",
        onClick: this.onGeneratePreview.bind(this),
        disabled: isPreviewButtonDisabled || !enableModifyFilter,
      },
    };

    return buttonItems;
  }

  getConfigPanelView(enableModifyFilter = true) {
    const { activeTabKey } = this.state;

    const tabItems = this.getTabHeaderItems();

    return (
      <div className="wizard-config-panel-container lightup-auto-flex-item-container">
        <div className="wizard-config-container lightup-vertical-flex-container">
          <div className="wizard-tab-header-container">
            <Tabs
              className="lightup"
              activeKey={activeTabKey}
              onTabClick={this.onTabChanged}
            >
              {tabItems.map((tabItem, index) => {
                const {
                  iconComponent: IconComponent,
                  label,
                  key,
                  disabled = false,
                } = tabItem;

                return (
                  <Tabs.TabPane
                    disabled={disabled}
                    tab={
                      <div className="monitor-tab-header-item-container">
                        <div className="monitor-tab-header-icon-item-container">
                          <IconComponent />
                        </div>
                        {label}
                      </div>
                    }
                    key={key}
                  />
                );
              })}
            </Tabs>
          </div>
          <div className="wizard-tab-config-item-container lightup-rest-flex-item-container">
            {this.getConfigItemView(enableModifyFilter)}
          </div>
        </div>
      </div>
    );
  }

  getConfigItemView(enableModifyFilter = true) {
    const { errors, activeTabKey, configData, currentPreviewPeriod, previewList } =
      this.state;

    if (activeTabKey === RuleTabNameConst.CONFIG) {
      return (
        <RuleBasicConfigTab
          defaultData={configData}
          onConfigChange={this.onRuleConfigChanged}
          isEdit={this.props.isEdit}
          isClone={this.props.isClone}
          dataSourceList={this.props.dataSourceList}
          kpiList={this.props.kpiList}
          integrationList={this.props.integrationList}
          scheduleList={this.props.scheduleList}
          dimensionList={this.props.currentDimensionList}
          enableModifyFilter={enableModifyFilter}
        />
      );
    } else if (activeTabKey === RuleTabNameConst.TRAINING) {
      let isTraining = false;
      if (
        this.props.isEdit &&
        configData &&
        configData.status &&
        configData.status.training &&
        configData.status.training.trainingJobState
      ) {
        isTraining = isJobRunning(configData.status.training.trainingJobState);
      }
      return (
        <RuleTrainConfigTab
          errors={errors}
          defaultData={configData}
          currentRuleStatus={this.props.currentRuleStatus}
          isEdit={this.props.isEdit}
          isTraining={isTraining}
          kpiList={this.props.kpiList}
          onConfigChange={this.onRuleConfigChanged}
        />
      );
    } else if (activeTabKey === RuleTabNameConst.PREVIEW) {
      return (
        <RulePreviewConfigTab
          defaultData={configData}
          previewPeriod={currentPreviewPeriod}
          currentPreviewSummaryData={this.props.currentPreviewSummaryData}
          onPreviewDeleted={this.onPreviewDeleted}
          previewList={previewList}
          onPreviewPeriodChange={(currentPreviewPeriod) => {
            this.setState({ currentPreviewPeriod });
          }}
          enableModifyFilter={enableModifyFilter}
        />
      );
    }

    return null;
  }

  getConfigContentView(enableModifyFilter = true) {
    const { activeTabKey, configData } = this.state;

    const {
      metadata: { name, uuid },
      status,
      config: { symptom },
    } = configData;

    let isTrained = false;
    let trainingStatus = "";
    let trainingSummary = {};
    if (status && status.training) {
      trainingStatus = status.training.trainingJobState;
      isTrained = status.training.isTrained;
      trainingSummary.isSlicing = !!status.training.isSlicing;

      if (trainingSummary.isSlicing) {
        trainingSummary.totalSlice = status.training.totalSlice;
        trainingSummary.trainedSlice = status.training.trainedSlice;
      }

      trainingSummary.trainingErrorMsg = status.training.trainingError || "";
    }

    let currentActiveTabComponent;
    if (activeTabKey === RuleTabNameConst.CONFIG) {
      currentActiveTabComponent = (
        <RuleBasicConfigContent isAutoSymptom={!symptom || !symptom.type} />
      );
    } else if (activeTabKey === RuleTabNameConst.TRAINING) {
      currentActiveTabComponent = (
        <RuleTrainConfigContent
          title={name}
          ruleId={uuid}
          isTrained={isTrained}
          trainingStatus={trainingStatus}
          trainingSummary={trainingSummary}
          currentRuleStatus={this.props.currentRuleStatus}
          kpi={this.getKpiInfo()}
          ruleConfig={configData}
          getMetricsData={this.props.getMetricsData}
          nonSliceRuleTrainingData={this.props.nonSliceRuleTrainingData}
          sliceRuleTrainingData={this.props.sliceRuleTrainingData}
          isEdit={this.props.isEdit}
        />
      );
    } else if (activeTabKey === RuleTabNameConst.PREVIEW) {
      currentActiveTabComponent = (
        <RulePreviewConfigContent
          addPreview={this.props.addPreview}
          title={name}
          ruleId={uuid}
          isTrained={isTrained}
          isTraining={isJobRunning(trainingStatus)}
          ruleConfig={configData}
          kpi={this.getKpiInfo()}
          previewList={this.props.previewList}
          getPreviewList={this.props.getPreviewList}
          previewIncidentList={this.props.previewIncidentList}
          getPreviewIncidentList={this.props.getPreviewIncidentList}
          currentPreviewMetricsData={this.props.currentPreviewMetricsData}
          getCurrentPreviewMetricsData={this.props.getCurrentPreviewMetricsData}
          updatePreviewIncidentStatus={this.props.updatePreviewIncidentStatus}
          onPreviewPeriodChange={this.onPreviewPeriodChange}
          startPollPreviewStatus={this.props.startPollPreviewStatus}
          resetRulePreviewInfo={this.props.resetRulePreviewInfo}
          currentPreviewSummaryData={this.props.currentPreviewSummaryData}
          currentPreviewSliceValue={this.props.currentPreviewSliceValue}
          getCurrentPreviewSliceValue={this.props.getCurrentPreviewSliceValue}
          onPreviewDeleted={this.onPreviewDeleted}
          resetCurrentPreviewMetricsData={this.props.resetCurrentPreviewMetricsData}
          enableDeletePreview={enableModifyFilter}
        />
      );
    } else {
      console.log(`Unknown tab key ${activeTabKey}`);
      return null;
    }

    return (
      <div className="wizard-tab-config-content-container lightup-rest-flex-item-container">
        {currentActiveTabComponent}
      </div>
    );
  }

  render() {
    // If workspaceUserPermissions not passed, allow full access
    const { workspaceUserPermissions = { isSuperuser: true, permissions: [] } } =
      this.props;
    const enableModifyFilter = hasPermission(workspaceUserPermissions, [
      AppPermissions.BACKEND_APPS_FILTER_VIEWS_EDIT_FILTERDETAIL,
    ]);
    return (
      <div className="rule-wizard">
        <div className="wizard-nav-container">
          {this.getNavHeader(enableModifyFilter)}
        </div>
        <div className="wizard-body-container lightup-horizon-flex-container">
          {this.getConfigPanelView(enableModifyFilter)}
          {this.getConfigContentView(enableModifyFilter)}
        </div>
      </div>
    );
  }
}

RuleWizard.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  currentDimensionList: PropTypes.array,
  dataSourceList: PropTypes.array.isRequired,
  kpiList: PropTypes.array.isRequired,
  integrationList: PropTypes.array.isRequired,
  isEdit: PropTypes.bool.isRequired,
  currentRuleStatus: PropTypes.array,
  defaultData: PropTypes.object,
  onActiveTabChanged: PropTypes.func,
};

RuleWizard.defaultProps = {
  defaultData: {},
  currentRuleStatus: [],
};

export default RuleWizard;
