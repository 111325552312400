import React from "react";
import { Switch as AntSwitch } from "antd";

import { classesName } from "../../utils/css";
import BodyMedium from "../../elements/Typography/BodyMedium";
import { TypographyColors } from "../../elements/Typography/enum";

import "./switch.scss";

export default function Switch(props) {
  const {
    className = "",
    label,
    labelPosition = "left",
    testId,
    disabled,
    ...restProps
  } = props;
  const classLabelTop = labelPosition === "top" ? "label-position-top" : "";
  const classLabelLeft = labelPosition === "left" ? "label-position-left" : "";
  return (
    <div
      className={classesName(
        "lightup-switch",
        className,
        classLabelTop,
        classLabelLeft
      )}
      data-testid={testId}
    >
      <AntSwitch
        className={classesName(className)}
        disabled={disabled}
        {...restProps}
      />
      <SwitchLabel label={label} direction={labelPosition} disabled={disabled} />
    </div>
  );
}

function SwitchLabel({ direction = "left", label, disabled }) {
  const labelClassName = `label-${direction}`;
  return label ? (
    <BodyMedium
      className={classesName("switch-label", labelClassName)}
      color={disabled ? TypographyColors.DISABLED : TypographyColors.DEFAULT}
    >
      {label}
    </BodyMedium>
  ) : null;
}
