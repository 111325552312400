import React from "react";
import NotificationLevelCell from "./notification-level-cell";
import DynamicHeightTable from "../../components/table/ng-table";
import NotificationAlertChannelCell from "./notification-alert-channel-cell";
import { SubmitType } from "../../views/integrations/integration-metadata";
import { hasPermission } from "../../utils/uri-path";
import { AppPermissions } from "../../utils/permissions";
import { fnSorter } from "../../utils/sort";
import { EVENT, trackEvent } from "../../utils/telemetry";
import Switch from "../../atom/switch";

function NotificationConfigurationTab(props) {
  const {
    integrationList,
    notificationMonitorList,
    updateNotificationMonitorItem,
    workspaceUserPermissions,
  } = props;

  const enableConfigureEventMonitor = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_EVENT_VIEWS_EDIT_SYSTEMEVENTMONITORDETAILVIEW,
  ]);

  const normalizedIntegrationList = integrationList.data.filter(
    ({ mode }) => mode !== SubmitType.MANUAL
  );

  const columns = [
    {
      title: "Level",
      key: "level",
      defaultSortOrder: "descend",
      dataIndex: ["config", "eventLevel"],
      width: 100,
      sorter: {
        compare: fnSorter((row) => row.config.eventLevel),
      },
      render: (level) => <NotificationLevelCell value={level} />,
    },
    {
      title: "Muted",
      key: "isMuted",
      dataIndex: ["config", "alertConfig", "isMuted"],
      width: 100,
      sorter: {
        compare: fnSorter((row) => row.config?.alertConfig?.isMuted),
      },
      render: (isMuted, record) => (
        <Switch
          className="alert-config-setting-toggle-checkbox-container"
          labelPosition="left"
          disabled={!enableConfigureEventMonitor}
          checked={isMuted}
          onChange={() => {
            trackEvent(isMuted ? EVENT.UNMUTE_CHANNEL : EVENT.MUTE_CHANNEL, {
              level: record?.config?.eventLevel || "",
              workspace_id: record?.metadata?.workspaceId || "",
            });
            updateNotificationMonitorItem(record.metadata.uuid, {
              ...record,
              config: {
                ...record.config,
                alertConfig: {
                  ...(record.config.alertConfig || {}),
                  isMuted: !isMuted,
                },
              },
            });
          }}
        />
      ),
    },
    {
      title: "Alert channels",
      key: "alertChannels",
      dataIndex: ["config", "alertConfig", "channels"],
      sorter: {
        compare: fnSorter((row) => row.config?.alertConfig?.channels?.join(",")),
      },
      render: (channels, record) => {
        return (
          <NotificationAlertChannelCell
            isReadonly={!enableConfigureEventMonitor}
            value={channels.map((currentChannel) => currentChannel.channelId)}
            alertChannelList={normalizedIntegrationList}
            onChange={(newChannels) => {
              updateNotificationMonitorItem(record.metadata.uuid, {
                ...record,
                config: {
                  ...record.config,
                  alertConfig: {
                    ...(record.config.alertConfig || {}),
                    channels: newChannels.map((channelId) => {
                      return { channelId };
                    }),
                  },
                },
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="entity-list-table-container">
      <DynamicHeightTable
        dataSource={notificationMonitorList.data}
        loading={normalizedIntegrationList.loading}
        columns={columns}
        rowKey={(record) => record.metadata.uuid}
        sortDirections={["ascend", "descend", "ascend"]}
        size={"middle"}
        pagination={{
          position: ["topRight"],
          hideOnSinglePage: true,
          size: "default",
        }}
      />
    </div>
  );
}

export default NotificationConfigurationTab;
