import React, { useEffect, useMemo, useState } from "react";
import Tooltip from "../../components/tooltip/ng-tooltip";
import { TextWithIcon } from "../../components/button/ng-button";
import EntityList from "../../components/entity-list/entity-list";
import { NgTableClickableText } from "../../components/table/ng-table";
import TagGroup from "../../components/tag-group/ng-tag-group";
import ProfilerConfirmationDialog from "../profiler/profiler-confirmation-dialog";
import DataSourceActionsMenu, { DataSourceActions } from "./datasource-actions-menu";
import DataSourceStatusBadge from "./datasource-status-badge";
import { deepMerge } from "../../utils/deep-merge";
import { getURIInstance, hasPermission, URIPath } from "../../utils/uri-path";
import {
  ConnectionHealth,
  getDataSourceDisplayName,
  getConnectionHealthDisplayName,
  ConnectionStatusPauseDisplayMessage,
  ListPageColumnKey,
} from "../../utils/enums";
import {
  getConnectionHealth,
  getLastScannedDisplayTime,
  isSchemaScanning,
} from "../../utils/datasource";
import { getDataSourceIcon, getConnectionHealthIcon } from "../../utils/icon";
import { AppPermissions } from "../../utils/permissions";
import { fnSorter } from "../../utils/sort";
import queryString from "query-string";
import EntityListPage from "../../components/entity-list/entity-list-page";
import {
  decodeEntityListSearchObject,
  encodeEntityListSearchObject,
} from "../../utils/search";
import { useInterval } from "../../utils/hooks";
import {
  EVENT,
  PAGE,
  getDatasourceDetailProps,
  trackEvent,
} from "../../utils/telemetry";
import { Skeleton } from "antd";
import ProfilerDataSourceStatusHelpItem from "../../components/profiler/profiler-data-source-help-item";
import { AddDataSourceIcon } from "../../components/profiler/profiler-icons";
import useSearch, {
  searchEntityType,
} from "../../components/search/use-search/use-search";

import PageHeader from "../../components/PageHeader";
import { IconName, IconSizes } from "../../elements/Icon";
import {
  SideBarNavigationAction,
  WorkspaceItemKeyToLabelMapper,
} from "../../components/sidebar/util";
import Button from "../../atom/Button";
import "./datasource-list.scss";

const POLL_DATA_SOURCE_LIST_INTERVAL = 30 * 1000;

function getTableRows(dataSourceList, kpiList) {
  const metricCountByDataSourceUuid = kpiList.data.reduce((countByUuid, metric) => {
    const sourceUuid = metric.config.sources[0];
    if (!countByUuid.hasOwnProperty(sourceUuid)) {
      countByUuid[sourceUuid] = 0;
    }
    countByUuid[sourceUuid] += 1;
    return countByUuid;
  }, {});

  return dataSourceList.data.map((datasource) => {
    return {
      ...datasource,
      dataSourceData: datasource,
      metricCount: metricCountByDataSourceUuid[datasource.metadata.uuid] || 0,
      connectionStatus: getConnectionHealth(datasource),
    };
  });
}

function DataSourceList(props) {
  const {
    match: {
      params: { workspaceUuid },
    },
    location,
    history,
    workspaceUserPermissions,
    kpiList,
    dataSourceList,
    dataSourceUsage,
    tagList,
    getDataSourceList,
    getDataSourceUsage,
    getKpiList,
    getTagList,
    updateDataSource,
    updateDataSourceTags,
    updateDataSourceProfilerConfig,
    deleteDataSource,
  } = props;

  const { options: searchOptions, filter: filterRows } = useSearch({
    entityType: searchEntityType.DATASOURCE,
    dataSources: dataSourceList.data,
  });

  const { search } = location;
  const [conformDialogConfig, setConformDialogConfig] = useState({
    isOpen: false,
    config: null,
  });

  const [searchItem, setSearchItem] = useState(decodeEntityListSearchObject(search));

  useEffect(() => {
    getDataSourceList(workspaceUuid);
    getKpiList(workspaceUuid);
    getTagList(workspaceUuid);
  }, [workspaceUuid, getDataSourceList, getKpiList, getTagList]);

  useEffect(() => {
    const queryParams = queryString.parse(search);
    const dataSourceUuid = queryParams.dataSourceUuid || "";
    const dataSourceId = queryParams.dataSourceId || "";
    if (!dataSourceUuid || dataSourceList?.data?.length === 0) {
      return;
    }

    const selectedDataSource = dataSourceList.data.find(
      (dataSource) => dataSource.metadata.uuid === dataSourceUuid
    );
    if (!selectedDataSource) {
      return;
    }

    setSearchItem({
      dataSourceName: [selectedDataSource.metadata.name],
      dataSourceId: dataSourceId ? [dataSourceId] : [],
    });
  }, [dataSourceList, search]);

  const loading = dataSourceList.loading;
  const tableRows = useMemo(() => {
    return getTableRows(dataSourceList, kpiList);
  }, [dataSourceList, kpiList]);

  useInterval(() => {
    if (
      !loading &&
      dataSourceList.data.some(
        (currentDataSource) =>
          getConnectionHealth(currentDataSource) !== ConnectionHealth.SUCCESS ||
          isSchemaScanning(currentDataSource)
      )
    ) {
      getDataSourceList(workspaceUuid, { isRefresh: true });
    }
  }, POLL_DATA_SOURCE_LIST_INTERVAL);

  const canAddDataSource = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCELIST,
  ]);

  const canModifyDataSource = hasPermission(workspaceUserPermissions, [
    AppPermissions.BACKEND_APPS_SOURCE_VIEWS_EDIT_SOURCEDETAIL,
  ]);

  function onAdd() {
    trackEvent(EVENT.CREATE_DATASOURCE, {
      workspace_id: workspaceUuid,
      page: PAGE.DATASOURCES,
    });
    const nextUrlPath = getURIInstance(URIPath.ADD_DATA_SOURCE, { workspaceUuid });
    const nextUrl = `${nextUrlPath}?returnTo=dataSourceList`;
    history.push(nextUrl);
  }

  const dataSourceActionsSharedMenuProps = {
    workspaceUuid,
    workspaceUserPermissions,
    history: history,
    onToggleDataSourcePauseClick: (workspaceUuid, datasources, newIsLive) => {
      const datasource = datasources[0];
      if (newIsLive) {
        updateDataSource(
          workspaceUuid,
          deepMerge(datasource, { config: { isLive: true } })
        );
      } else {
        openConfirmationDialog(
          workspaceUuid,
          datasource,
          undefined,
          "pause",
          `You are about to pause datasource ${datasource.metadata.name}, which has no metrics or monitors`,
          {},
          () => {
            trackEvent(EVENT.PAUSE_DATASOURCE, {
              ...getDatasourceDetailProps(datasource),
              page: PAGE.DATASOURCES,
            });

            updateDataSource(
              workspaceUuid,
              deepMerge(datasource, { config: { isLive: false } })
            );
          }
        );
      }
    },
    onToggleDataSourceProfilerStatusClick: (workspaceUuid, datasources, newIsLive) => {
      const datasource = datasources[0];
      const newProfilerConfig = { ...datasource.config.profiler, enabled: newIsLive };
      if (newIsLive) {
        updateDataSourceProfilerConfig(workspaceUuid, datasource, newProfilerConfig);
      } else {
        openConfirmationDialog(
          workspaceUuid,
          datasource,
          undefined,
          "delete",
          `You are about to deactivate datasource ${datasource.metadata.name}, which has no metrics or monitors`,
          { profilerConfig: newProfilerConfig },
          (context) => {
            const { dataSource, profilerConfig } = context;

            trackEvent(EVENT.DISABLE_DATASOURCE, {
              ...getDatasourceDetailProps(dataSource),
              page: PAGE.DATASOURCES,
            });

            updateDataSourceProfilerConfig(workspaceUuid, dataSource, profilerConfig);
          }
        );
      }
    },
    onDeleteDataSourceClick: (workspaceUuid, datasources) => {
      const currentDataSource = datasources[0];
      openConfirmationDialog(
        workspaceUuid,
        currentDataSource,
        "Delete datasource",
        "delete",
        `You are about to delete datasource ${currentDataSource.metadata.name}, which has no metrics or monitors`,
        {},
        ({ dataSource }) => {
          trackEvent(EVENT.DELETE_DATASOURCE, {
            ...getDatasourceDetailProps(dataSource),
            page: PAGE.DATASOURCES,
          });
          console.log(`Data Source: ${JSON.stringify(dataSource)} is removed.`);
          deleteDataSource(workspaceUuid, dataSource);
        }
      );
    },
    loading: false,
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: ["metadata", "idSerial"],
      defaultSortOrder: "descend",
      sorter: { compare: fnSorter((row) => row.metadata.idSerial) },
      width: 60,
      fixed: "left",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: ["metadata", "name"],
      sorter: { compare: fnSorter((row) => row.metadata.name.toLowerCase()) },
      width: 160,
      fixed: "left",
      render: (name, { dataSourceData }) => {
        const onClick = () => {
          trackEvent(EVENT.VIEW_DATASOURCE_IN_EXPLORER, {
            ...getDatasourceDetailProps(dataSourceData),
            page: PAGE.DATASOURCES,
          });
          const queryString = `dataSourceUuid=${dataSourceData.metadata.uuid}`;
          const nextUrl = `${getURIInstance(URIPath.EXPLORER, {
            workspaceUuid,
          })}?${queryString}`;
          history.push(nextUrl);
        };
        const trigger = (
          <NgTableClickableText onClick={onClick}>{name}</NgTableClickableText>
        );
        return trigger;
      },
    },
    {
      title: "Status",
      key: "dataSourceStatus",
      dataIndex: ["config", "profiler", "enabled"],
      sorter: { compare: fnSorter((row) => row.config.profiler.enabled) },
      render: (_, { dataSourceData }) => {
        const trigger = (
          <DataSourceStatusBadge
            datasource={dataSourceData}
            clickable={canModifyDataSource}
          />
        );

        return (
          <DataSourceActionsMenu
            trigger={trigger}
            actions={[DataSourceActions.PAUSE_CHANGE, DataSourceActions.STATUS_CHANGE]}
            dataSources={[dataSourceData]}
            showStatusActions
            {...dataSourceActionsSharedMenuProps}
          />
        );
      },
      width: 90,
    },
    {
      title: "TYPE",
      key: "type",
      dataIndex: ["config", "connection", "type"],
      render: function (type) {
        const IconComponent = getDataSourceIcon(type);
        return (
          <Tooltip title={getDataSourceDisplayName(type)}>
            <span>
              <IconComponent />
            </span>
          </Tooltip>
        );
      },
      sorter: { compare: fnSorter((row) => row.config.connection.type) },
      width: 70,
    },
    {
      title: "Connection status",
      key: "connectionStatus",
      dataIndex: "connectionStatus",
      render: function (connectionStatus, data) {
        const IconComponent = getConnectionHealthIcon(connectionStatus);
        const connectionHealthDisplayName =
          getConnectionHealthDisplayName(connectionStatus);

        const content = (
          <TextWithIcon icon={<IconComponent />} iconPosition="left">
            {connectionHealthDisplayName}
          </TextWithIcon>
        );

        if (
          [ConnectionHealth.SUCCESS, ConnectionHealth.UNKNOWN].includes(
            connectionStatus
          )
        ) {
          return content;
        }

        const failedReason = data.status?.lastScannedFailedReason ?? "";
        const tooltipMessage =
          connectionStatus === ConnectionHealth.PAUSED
            ? ConnectionStatusPauseDisplayMessage[data.status?.lastScannedStatus]
            : failedReason;
        const tooltipContent = (
          <div style={{ alignItems: "left", margin: "10px" }}>{tooltipMessage}</div>
        );

        return (
          <Tooltip title={tooltipContent}>
            <span>{content}</span>
          </Tooltip>
        );
      },
      sorter: { compare: fnSorter((row) => row.connectionStatus) },
      width: 180,
    },
    {
      title: "Last scanned",
      key: "lastScanned",
      dataIndex: ["status", "lastScannedTs"],
      render: function (lastScannedTs) {
        return getLastScannedDisplayTime(lastScannedTs);
      },
      sorter: { compare: fnSorter((row) => row?.status?.lastScannedTs || 0) },
      width: 180,
    },
    {
      title: "Metrics",
      key: "metrics",
      dataIndex: ["metricCount"],
      sorter: { compare: fnSorter((row) => row.metricCount) },
      width: 110,
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: ["metadata", "tags"],
      render: (tags, { dataSourceData }) => {
        return (
          <TagGroup
            editEnabled={canModifyDataSource}
            value={tags}
            tagList={tagList}
            onChange={(newTags) => {
              updateDataSourceTags(workspaceUuid, dataSourceData, newTags);
              const isAddingNewTag = newTags.length > tags.length;

              if (isAddingNewTag)
                trackEvent(EVENT.ADD_TAG_TO_DATASOURCE, {
                  ...getDatasourceDetailProps(dataSourceData),
                  page: PAGE.DATASOURCES,
                });
            }}
          />
        );
      },
      sorter: { compare: fnSorter((row) => row.metadata.tags.join(", ")) },
      width: 570,
    },
    {
      title: "",
      key: ListPageColumnKey.ACTIONS,
      width: 50,
      dataIndex: ["metadata"],
      render: (_, { dataSourceData }) => {
        return (
          <DataSourceActionsMenu
            trigger={null}
            triggerClassName="actions-trigger"
            dataSources={[dataSourceData]}
            actions={Object.values(DataSourceActions).filter(
              (action) => action !== DataSourceActions.STATUS_CHANGE
            )}
            {...dataSourceActionsSharedMenuProps}
          />
        );
      },
    },
  ];

  function openConfirmationDialog(
    workspaceUuid,
    dataSource,
    title,
    actionType,
    defaultConfirmationMsg,
    context,
    callback
  ) {
    const targetName = dataSource.metadata.name;
    const config = {
      targetName,
      defaultConfirmationMsg,
      title,
      actionType,
      context: {
        dataSource,
        ...context,
        callback,
      },
    };

    getDataSourceUsage(workspaceUuid, dataSource);
    setConformDialogConfig({
      isOpen: true,
      config,
    });
  }

  if (loading) {
    return (
      <div className="datasource-list-content-loader">
        <Skeleton active={true} paragraph={{ rows: 10 }} />
      </div>
    );
  }

  if (tableRows.length === 0) {
    return (
      <div className="datasource-list-zero-container">
        <div className="datasource-list-zero-header">Datasources</div>
        <ProfilerDataSourceStatusHelpItem
          icon={<AddDataSourceIcon />}
          title="Hook up your first datasource by clicking the button below"
          buttonText="Add datasource"
          redirectUri={getURIInstance(URIPath.ADD_DATA_SOURCE, { workspaceUuid })}
          description="Any admin can do this"
          buttonProps={{ disabled: !canAddDataSource }}
        />
      </div>
    );
  }

  return (
    <EntityListPage>
      <PageHeader
        iconSize={IconSizes.XLARGE}
        iconName={IconName.Database}
        title={WorkspaceItemKeyToLabelMapper[SideBarNavigationAction.DATA_SOURCE]}
        rightContent={
          canAddDataSource && (
            <Button disabled={loading} label="+ Create Datasource " onClick={onAdd} />
          )
        }
      />
      <EntityList
        searchOptions={searchOptions}
        columns={columns}
        rows={tableRows}
        getRowKey={(datasource) => datasource.metadata.uuid}
        getFilteredRows={filterRows}
        onAdd={onAdd}
        searchItem={searchItem}
        onSearchItemChange={(newSearchItem) => {
          history.replace(
            `${location.pathname}?${encodeEntityListSearchObject(newSearchItem)}`
          );
          setSearchItem(newSearchItem);
        }}
        testId="entity-list-datasource"
      />
      <ProfilerConfirmationDialog
        modalIsOpen={conformDialogConfig.isOpen}
        setIsOpen={(isOpen) => {
          setConformDialogConfig({ ...conformDialogConfig, isOpen });
        }}
        okClicked={({ callback, ...otherProperties }) => {
          callback(otherProperties);
        }}
        usage={dataSourceUsage}
        title={conformDialogConfig.config?.title || ""}
        context={conformDialogConfig.config?.context || {}}
        actionType={conformDialogConfig.config?.actionType || "delete"}
        enableUsage={true}
        defaultConfirmationMsg={
          conformDialogConfig.config?.defaultConfirmationMsg || ""
        }
      />
    </EntityListPage>
  );
}

export default DataSourceList;
