export const ButtonType = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TEXT: "text",
  LINK: "link",
};

export const ButtonVariant = {
  BASIC: "basic",
  ICON: "icon",
};

export const ButtonShape = {
  DEFAULT: "default",
  ROUND: "round",
};

export const ButtonSize = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
};
